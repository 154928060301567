import React, { useEffect } from 'react';
import p5 from 'p5';

import {init} from './resources/js/random';

const Canvas = (props) => {
    const Sketch = (p) => {
        p.setup = () => {
            const width = props.size.width;
            const height = props.size.height;

            p.createCanvas(width, height);
            p.clear();
            props.drawFunc({md: props.md, p, width, height});
            addWatermark(p, props.size, props.style);
            sign(p, props.size, props.style);
        }
        p.draw = props.animFunc? props.animFunc : null;
    }

    const addWatermark = (p, size, style) => {
        const col = p.color(style.watermark.color);
    
        p.push();
        col.setAlpha(255 * style.watermark.opacity);
        p.textFont(style.watermark.fontFamily);
        p.textSize(size.watermark.fontSize);
        p.fill(col);
        p.textAlign('center');
        p.translate(size.watermark.translateX, size.watermark.translateY);
        p.rotate((Math.PI / 180) * parseInt(size.watermark.rotation)); 
        p.text(`@MauroGrizia`, size.width * 0.1, size.height * 1.05);
        p.pop();
    }
    const sign = (p, size, style) => {
        const sign = {
            x: size.width * 0.98,
            y: size.height * 0.98,
        };
        const sizeBox = size.footer.box;
        const styleBox = style.footer.box;
        const col = p.color(styleBox.background);
    
        p.push();
        p.noStroke();
        col.setAlpha(255 * styleBox.opacity);
        p.fill(col);
        p.rect(sizeBox.x, sizeBox.y, sizeBox.width, sizeBox.height);
        p.pop();
        p.push();
        p.textFont(styleBox.fontFamily);
        p.textSize(sizeBox.fontSize);
        p.fill(styleBox.color);
        p.textAlign('right');
        p.text(`Mauro Grizia`, sign.x, sign.y);
        p.pop();
    }

    useEffect(() => {
        init(props.md.seed);
        
        const node = document.querySelector('.container-canvas');
        const myP5 = new p5(Sketch, node);

        return myP5.remove;
    }, [props]);

    return (
        <div className="container-canvas">
        </div>
    );

}

export default Canvas;