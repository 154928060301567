import React, {useContext} from 'react';
import Context from '../../../context/language/Context';
import dictionary from './lang/index.js';

const SayItWithColors = function () {
    const {language} = useContext(Context);
    const text = dictionary[language];

    return (
        <div className='content-container'>
            <h1 className="title">{text['siwc-title']}</h1>
            <hr />
            <p>{text['soon']}</p>
        </div>
    );
}

export default SayItWithColors;