import React, {useContext} from 'react';
import Context from '../../context/language/Context';
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { ToastContainer, toast } from "react-toastify";

import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';

import { COOKIE_CONF } from '../../assets/conf/conf';
import { Link } from 'react-router-dom';

const AdminPage = () => {
  const {language} = useContext(Context);
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const [username, setUsername] = useState("");

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    let status = false;
    let user = null;

    const verifyCookie = async () => {
      try {
        if (!cookies.token || cookies.token === 'undefined') {
          navigate(`/${language}/admin/login`);
        } else {
          const { data } = await axios.post(
            "/api/admin/verifyUser",
            {},
            { cancelToken: source.token,
              credentials: 'include',
              withCredentials: true,
              httpOnly: false,
              sameSite: 'Strict'}
          );
          status = data.status;
          user = data.user;
          setUsername(user);
        }
        
        return status
          ? toast(`Hello ${user}`, {
            position: "top-right",
          })
          : (removeCookie("token"), navigate("/ES/admin/login"));
      } catch (err) {
        if (axios.isCancel(err)) {
          console.log('successfully aborted');
        } else {
          console.log(err);
        }
      };
    };

    verifyCookie();

    return () => {
      // cancel the request before component unmounts
      source.cancel();
    };
  }, []);

  const logout = () => {
    removeCookie("token", COOKIE_CONF);
    setCookie("token", '', COOKIE_CONF);
    navigate(`/${language}/admin/login`);
  };

  return (
    <>
        <div className="content-container">
            <h2>{"Bienvenida reina " + username + " <3"}</h2>
            <Nav className="me-auto">
                <Link className="nav-link nav-link-mau" to={`/${language}/admin/db`}>DB</Link>
                <Link className="nav-link nav-link-mau" to={`/${language}/admin/blog`}>BLOG</Link>
                <Link className="nav-link nav`link-mau" to={`/${language}/admin/email`}>EMAIL</Link>
            </Nav>
            <Button onClick={logout}>LOGOUT</Button>
        </div> 
        <ToastContainer />
    </>
    
  );
};

export default AdminPage;
