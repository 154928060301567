import React, {useContext} from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Context from '../../../context/language/Context.jsx';
import dictionary from './lang/index.js';

import './about.css';

const About = function () {
    const {language} = useContext(Context);
    const text = dictionary[language];
    
    return (
        <div id='container-about' className='content-container'>
            <h1 className="title">{text['title']}</h1>
            <hr />
            <Container fluid='md'>
                <Row>
                    <Col className="text-center m-2 p-2" xs={12} sm={12} md={5} lg={4}>
                        <div className="me_container">
                            <img className="me" src="/assets/img/mau.webp" alt="Mauro Grizia" />
                            <div className="overlay">
                                <div className="me_text">Mauro Grizia<br />{text['diploma']}</div>
                            </div>
                        </div>
                    </Col>
                    <Col className="m-2 p-2" xs={12} sm={12} md={6} lg={7}>
                        <p>
                            {text['greeting']}
                        </p>
                        <p>
                            {text['p1']}
                        </p>
                        <p>
                            {text['p2']}
                        </p>
                    </Col>
                </Row>
                <Row>
                    <p>
                        {text['p3']}
                    </p>
                    <p>
                        {text['p4']}
                    </p>
                    <p>
                        {text['p5']}
                    </p>
                    <p>
                        {text['p6']}
                    </p>
                </Row>
                <Row>
                    <div className="social_container">
                        <a href="https://www.linkedin.com/in/mauro-grizia-83a34aa2/" rel="noopener noreferrer" target="_blank">
                            <i className="bi bi-linkedin"></i>
                        </a>
                        <a href="https://www.instagram.com/maurogrizia/" rel="noopener noreferrer" target="_blank">
                            <i className="bi bi-instagram"></i>
                        </a>
                        <a href="https://www.threads.net/@maurogrizia" rel="noopener noreferrer" target="_blank" title="Threads">
                            <i className="bi bi-threads"></i>
                        </a>
                        <a href="https://twitter.com/GriziaMauro" rel="noopener noreferrer" target="_blank">
                            <i className="bi bi-twitter"></i>
                        </a>
                        <a href="https://www.reddit.com/user/MauroGrizia" rel="noopener noreferrer" target="_blank">
                            <i className="bi bi-reddit"></i>
                        </a>
                        <a href="tg://resolve?domain=supermaramau" title="Telegrameame">
                            <i className="bi bi-telegram"></i>
                        </a>
                        <a href="https://medium.com/@griziamauro" rel="noopener noreferrer" target="_blank" title="Medium">
                            <i className="bi bi-medium"></i>
                        </a>
                    </div>
                </Row>
            </Container>
           
        </div>
    );
}

export default About;