import React, {useContext} from "react";
import ProjectColection from './ProjectColection';

import Context from '../../context/language/Context';
import dictionary from './lang/index.js';

const Projects = function() {
    const {language} = useContext(Context);
    const text = dictionary[language];
    const conf = {
        cols: 3,
    };
    const projects = {
        completed: [
            {
                title: text['rt-title'],
                description: text['rt-description'],
                alt: text['rt-alt'],
                img: '/assets/img/radiotango.webp',
                link: 'https://radiotango.net.ar'
            },
            {
                title: text['daw-title'],
                description: text['daw-description'],
                alt: text['daw-alt'],
                img: '/assets/img/daw.webp',
                link: `/${language}/projects/daw`
            },
            {
                title: text['tweet-title'],
                description: text['tweet-description'],
                alt: text['tweet-alt'],
                img: '/assets/img/sentiments.webp',
                link: `/${language}/projects/twitter`
            },
        ],
        inProgress: [
            {
                title: text['siwc-title'],
                description: text['siwc-description'],
                disabled: true,
                alt: text['siwc-alt'],
                img: '/assets/img/sayitwithcolors.webp',
                link: `/${language}/projects/sayitwithcolors`
            }
        ],
        future: [
            {
                title: text['moj-title'],
                description: text['moj-description'],
                disabled: true,
                alt: text['moj-alt'],
                img: '/assets/img/jazz.webp',
                link: `/${language}/projects/mapofjazz`
            },
        ]
    };

    return (
        <>
        <div className="content-container">
            <h1 className="title">{text['project-title']}</h1>
            <hr />
            <h2>{text['project-completed']}</h2>
            <ProjectColection colection={projects.completed} cols={conf.cols}/>
            <p></p>
            <h2>{text['project-progress']}</h2>
            <ProjectColection colection={projects.inProgress} cols={conf.cols}/>
            <p></p>
            <h2>{text['project-future']}</h2>
            <ProjectColection colection={projects.future} cols={conf.cols}/>
        </div>
        </>
    );
}

export default Projects;