/*
 *  Draw a basic shape, like a circle or triangle
 *  @p: canvas p
 *  @position: object with x,y coordinates
 *  @data: object with relevant data
 *    commonly expects:
 *      shape: String representing the name of the shape
 *      p conf:
 *      stroke
 *      fill
 */
const drawSimpleShape = (p, position, data) => {
    if (!data.type) {
        console.log("data.type doesn't exists");
    }
    //console.log(position)
    //console.log(data)
    /*
     * data expects: radius, angleStart and angleEnd
     */
    const drawPath = {
        circle: (p, data) => {
            const angleStart = data.angleStart? data.angleStart : 0;
            const angleEnd = data.angleEnd? data.angleEnd : 2 * Math.PI;
            const radius = data.radius? data.radius : data.size? data.size : 50;

            p.ellipse(0, 0, radius, radius);
            //p.ellipse(100, 100, 200, 200, 40);
        },
        triangle: (p, data) => {
            const funcs = {
                equilateral: () => {
                    p.moveTo(0, -data.size * 0.5);
                    p.beginShape();
                    p.line(data.size / 2, data.size / 2);
                    p.line(-data.size / 2, data.size / 2);
                    p.line(0, -data.size * 0.5);
                    p.endShape();
                },
                isosceles: () => {
                    p.moveTo(0, -data.size * 0.5);
                    p.beginShape();
                    p.line(data.size / 2, data.size);
                    p.line(-data.size / 2, data.size);
                    p.line(0, -data.size * 0.5);
                    p.endShape();
                }
            };

            const val = funcs[data.subtype](p, position, data);

            return val;
        },
        square: (p, data) => {
            const width = data.size? data.size : Math.floor(data.width * 0.5);
            const height = data.size? data.size : Math.floor(data.height * 0.5);
            
            p.line(-width, -height, width, -height)
            p.line(width, -height, width, height)
            p.line(width, height, -width, height)
            p.line(-width, height, -width, -height)
        },
        pentagon: (p, data) => {
            const size = data.size? data.size : 200;
            const angles = [
                -Math.PI * 0.5,
                -Math.PI * 0.9,
                -Math.PI * 1.3,
                -Math.PI * 1.7,
                -Math.PI * 2.1,
            ];

            p.beginShape();
            p.moveTo(size * Math.cos(angles[0]), size * Math.sin(angles[0]));
            p.line(size * Math.cos(angles[1]), size * Math.sin(angles[1]));
            p.line(size * Math.cos(angles[2]), size * Math.sin(angles[2]));
            p.line(size * Math.cos(angles[3]), size * Math.sin(angles[3]));
            p.line(size * Math.cos(angles[4]), size * Math.sin(angles[4]));
            p.endShape();
        },
        hexagon: (p, data) => {
            const size = data.size? data.size : 200;
            const angles = [
                Math.PI * 0.34,
                Math.PI * 0.67,
                Math.PI * 1,
                Math.PI * 1.34,
                Math.PI * 1.67,
                Math.PI * 2,
            ];

            p.beginShape();
            p.moveTo(size * Math.cos(angles[0]), size * Math.sin(angles[0]));
            p.line(size * Math.cos(angles[1]), size * Math.sin(angles[1]));
            p.line(size * Math.cos(angles[2]), size * Math.sin(angles[2]));
            p.line(size * Math.cos(angles[3]), size * Math.sin(angles[3]));
            p.line(size * Math.cos(angles[4]), size * Math.sin(angles[4]));
            p.line(size * Math.cos(angles[5]), size * Math.sin(angles[5]));
            p.endShape();
        },
        heptagon: (p, data) => {
            const size = data.size? data.size : 200;
            const angleOffset = 0.214285714;
            const angles = [
                Math.PI * 0.285714286 * 0 + angleOffset,
                Math.PI * 0.285714286 * 1 + angleOffset,
                Math.PI * 0.285714286 * 2 + angleOffset,
                Math.PI * 0.285714286 * 3 + angleOffset,
                Math.PI * 0.285714286 * 4 + angleOffset,
                Math.PI * 0.285714286 * 5 + angleOffset,
                Math.PI * 0.285714286 * 6 + angleOffset,
            ];

            p.beginShape();
            p.moveTo(size * Math.cos(angles[0]), size * Math.sin(angles[0]));
            p.line(size * Math.cos(angles[1]), size * Math.sin(angles[1]));
            p.line(size * Math.cos(angles[2]), size * Math.sin(angles[2]));
            p.line(size * Math.cos(angles[3]), size * Math.sin(angles[3]));
            p.line(size * Math.cos(angles[4]), size * Math.sin(angles[4]));
            p.line(size * Math.cos(angles[5]), size * Math.sin(angles[5]));
            p.line(size * Math.cos(angles[6]), size * Math.sin(angles[6]));
            p.endShape();
        },
        octogon: (p, data) => {
            const size = data.size? data.size : 200;
            const angleOffset = 0;
            const angles = [
                Math.PI * 0.25 * 0 + angleOffset,
                Math.PI * 0.25 * 1 + angleOffset,
                Math.PI * 0.25 * 2 + angleOffset,
                Math.PI * 0.25 * 3 + angleOffset,
                Math.PI * 0.25 * 4 + angleOffset,
                Math.PI * 0.25 * 5 + angleOffset,
                Math.PI * 0.25 * 6 + angleOffset,
                Math.PI * 0.25 * 7 + angleOffset,
            ];

            p.push();
            p.translate(size * Math.cos(angles[0]), size * Math.sin(angles[0]));
            p.beginShape(p.LINES);
            p.vertex(size * Math.cos(angles[1]), size * Math.sin(angles[1]));
            p.vertex(size * Math.cos(angles[2]), size * Math.sin(angles[2]));
            p.vertex(size * Math.cos(angles[3]), size * Math.sin(angles[3]));
            p.vertex(size * Math.cos(angles[4]), size * Math.sin(angles[4]));
            p.vertex(size * Math.cos(angles[5]), size * Math.sin(angles[5]));
            p.vertex(size * Math.cos(angles[6]), size * Math.sin(angles[6]));
            p.vertex(size * Math.cos(angles[7]), size * Math.sin(angles[7]));
            p.endShape();
            p.pop();
        },
    };
    p.push();
    p.translate(position.x? position.x : 0, position.y? position.y : 0);
    p.rotate(position.angle? position.angle : 0);
    p.strokeCap(data.strokeCap? data.strokeCap : p.ROUND);
    p.strokeWeight(data.strokeWeight && data.strokeWeight > 0? data.strokeWeight : 0);
    if (data.stroke) {
        p.stroke(data.stroke);
    } else {
        p.noStroke();
    }
    if (data.fill && data.fill !== 'transparent') {
        p.fill(data.fill);
    } else {
        p.noFill();
    }
    drawPath[data.type](p, data);
    p.pop();
};
const getShapeData = {
    circle: (position, data) => {
        const shapeData = {
            description: 'center and radius',
            center: {
                x: position.x,
                y: position.y,
            },
            radius: data.radius,
        };

        return shapeData;
    },
    triangle: (position, data) => {
        const triangleTypes = {
            equilateral: (position, data) => {
                const shapeData = {
                    description: 'corners',
                    corners: [
                        {
                            x: position.x,
                            y: position.y - data.size / 2,
                        },
                        {
                            x: position.x + data.size / 2,
                            y: position.y + data.size / 2
                        },
                        {
                            x: position.x - data.size / 2,
                            y: position.y + data.size / 2,
                        }
                    ],
                };

                return shapeData;
            },
            isosceles: (position, data) => {
                const shapeData = {
                    description: 'corners',
                    corners: [
                        {
                            x: position.x,
                            y: position.y - data.size / 2,
                        },
                        {
                            x: position.x + data.size / 2,
                            y: position.y + data.size
                        },
                        {
                            x: position.x - data.size / 2,
                            y: position.y + data.size,
                        }
                    ],
                };

                return shapeData;
            }
        };

        return triangleTypes[data.subtype](position, data);
    }
};


module.exports = {drawSimpleShape, getShapeData};