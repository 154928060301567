import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Context from "./Context";
import languages from "./languages";

const Provider = ({ children }) => {
    const BASE_URL = 'maurogrizia.com.ar';
    const navigate = useNavigate();
    const defaultLanguage = window.localStorage.getItem('language') || 'ES';
    const [language, setLanguage] = useState(defaultLanguage);
    const provider = {
        language,
        changeLanguage: selected => {
            const newLanguage = languages[selected]? selected : 'ES';
            const url = window.navigation.currentEntry.url;
            const idx = url.indexOf(language);
            
            if (idx > -1) {
                navigate(`${newLanguage}/${url.substring(idx + 3, url.length)}`);
            } else {
                navigate(`${newLanguage}/`);
            }
            setLanguage(newLanguage);
            window.localStorage.setItem('language', newLanguage);
        }
    };

    return (
        <Context.Provider value={provider}>
            {children}
        </Context.Provider>
    );
};

export default Provider;