const getSquaredDistance = (p1, p2, print) => {
    if (print) {
        console.log(`Math.sqrt(${Math.pow(p2.x - p1.x, 2)} + ${Math.pow(p2.y - p1.y, 2)}) = ${Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2))}`)
    }
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
}

// Is c1 inside c2?
// c1,c2: Object with x,y,r
// distance (optional): Number representing squaredDistance between c1 and c2
const isInsideCircle = (c1, c2, distance) => {
  const d = distance? distance : getSquaredDistance(c1,c2);
  const isInside = d < c2.r;

  return isInside;
}

module.exports = {getSquaredDistance, isInsideCircle};
