import React from 'react';
import Emoji from '../other/Emoji.js';

import ImageCreator from './ImageCreator';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import Context from '../../context/language/Context';
import dictionary from './lang/index.js';

class SVGCreator extends ImageCreator {
    static text = {};
    static contextType = Context;

    constructor (props) {
        super(props);

        const language = localStorage.getItem('language') || 'ES';
        
        this.text = dictionary[language];
    }
    save() {
        const svg = document.getElementById("svg-save")
        const serializer = new XMLSerializer();

        let source = serializer.serializeToString(svg);

        // Add name spaces.
        if(!source.match(/^<svg[^>]+xmlns="http:\/\/www\.w3\.org\/2000\/svg"/)) {
            source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
        }
        if(!source.match(/^<svg[^>]+"http:\/\/www\.w3\.org\/1999\/xlink"/)) {
            source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
        }
        source = source.replace('display: none;', '');
        source = '<?xml version="1.0" standalone="no"?>\r\n' + source;

        //convert svg source to URI data scheme.
        const  url = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(source);
        const downloadLink = document.createElement("a");

        downloadLink.href = url;
        downloadLink.download = "translation.svg";
        downloadLink.click();
    }

    componentDidMount() {
        this.init();

        if (!this.state.flags || this.state.flags === null) {
            this.init();
        }
    }

    renderLines(text, dimensions, charsPerLine) {
        const words = text.split(' ');
        const lines = [];

        let line = [];
        let strLine = '';
        let cantLines = 0;
        let addLine = 0;
        let chars = 0;

        for (let i = 0; i < words.length; i++) {
            if (chars + words[i].length > charsPerLine) {
                chars = chars % charsPerLine;
                strLine = line.join(' ');
                lines.push(<tspan key={i} x={dimensions.x} dy={String(Math.floor(parseInt(dimensions.fontSize) * 1.2) * addLine) + 'px'}>{strLine}</tspan>);
                line = [];
                chars = 0;
                cantLines++;
                addLine = 1;
            }
            line.push(words[i]);
            chars += words[i].length + 1;
        };

        strLine = line.join(' ');
        if (strLine.length > 0) {
            lines.push(<tspan key={words.length} x={dimensions.x} dy={String(Math.floor(parseInt(dimensions.fontSize) * 1.2) * addLine) + 'px'}>{strLine}</tspan>);
            cantLines++;
        }

        return {
            lines: lines,
            cantLines: cantLines,
        };
    }

    render() {
        // On sequence calls the text pads to the right
        // JSON is a short, but inneficient solution
        const sizeConf = ImageCreator.sizeConfs[this.state.size];
        const styleConf = ImageCreator.styleConfs[this.state.style];
        
        this.text = dictionary[this.context.language];
        
        try {
            const translation = this.state.translation;
            const languageFrom = this.state.languageFrom;
            const idxEquivalent = this.state.idxEquivalent;
            const isEquivalent = (idxEquivalent > -1 && translation.translations.equivalents[languageFrom.name] && idxEquivalent < translation.translations.equivalents[languageFrom.name].length);
            const flagDAW = ImageCreator.flagDAW;

            let phrase = '', literal = '', meaning = '', explanation = '';
            let countryUsed = null;
            let flagLiteral = null;
            let languageTo = this.state.languageTo;
            
            if (translation) {
                phrase = isEquivalent? translation.translations.equivalents[languageFrom.name][idxEquivalent].text : translation.phrase;
                literal = (translation.translations.literals && translation.translations.literals[languageTo.name][0])? translation.translations.literals[languageTo.name][0].text : '';
                meaning = (translation.translations.meanings && translation.translations.meanings[languageTo.name][0])? translation.translations.meanings[languageTo.name][0].text : '';
                explanation = (translation.explanation && translation.explanation[languageTo.name])? translation.explanation[languageTo.name] : '';

                countryUsed = this.getBy(this.state.countries, "_id", isEquivalent? translation.translations.equivalents[languageFrom.name][idxEquivalent].used[0] : translation.used[0]);
                
                if (translation.translations.literals[languageTo.name][0]) {
                    flagLiteral = this.getBy(this.state.languages, "name", languageTo.name);
                } else {
                    flagLiteral = this.getBy(this.state.flags, "name", "LIT");
                }
            }

            const objPhrase = {...sizeConf.phrase};
            objPhrase.x -= parseInt(sizeConf.phrase.fontSize);

            const objLiteral = {...sizeConf.literal};
            objLiteral.x -= parseInt(sizeConf.phrase.fontSize);
            
            const linesPhrase = this.renderLines(phrase, sizeConf.phrase, sizeConf.phrase.charsPerLine);
            const linesLiteral = this.renderLines(literal, sizeConf.literal, sizeConf.literal.charsPerLine);
            const linesMeaning = this.renderLines(meaning, sizeConf.meaning, sizeConf.meaning.charsPerLine);
            
            const yLiteral = sizeConf.height * 0.25 + parseInt(sizeConf.phrase.fontSize) * (linesPhrase.cantLines - 1);
            const yMeaningAux = yLiteral + parseInt(sizeConf.literal.fontSize) * linesLiteral.cantLines + 30;
            const yMeaning = yMeaningAux > sizeConf.meaning.y? yMeaningAux : sizeConf.meaning.y;
            const yLine = yLiteral + (yMeaning - yLiteral) / 2;
            return (
                <div className="image-creator mt-3">
                    <Container fluid>
                        <Row className={"justify-content-md-center mt-2"} xs={"auto"} md={12} lg={12}>
                            <Col className={'col-6'}>
                                <Dropdown className="text-center">
                                    <label className="lbl-button" htmlFor="dropdown-language-from">
                                        {this.text['from']}
                                    </label>
                                    <DropdownToggle id="dropdown-language-from" variant="primary" caret={"true"}>
                                        {(this.state.languageFrom)? this.state.languageFrom.description : 'Select language'}
                                    </DropdownToggle>
                                    <Dropdown.Menu>
                                        {this.renderOptions({
                                            hiddenColection: ImageCreator.conf.hiddenColection.languageFrom,
                                            select: this.state.languageFrom,
                                            state: "languageFrom",
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            <Col className={'col-6'}>
                                <Dropdown className="text-center">
                                    <label className="lbl-button" htmlFor="dropdown-language-to">
                                        {this.text['to']}
                                    </label>
                                    <Dropdown.Toggle id="dropdown-language-to" variant="primary" caret={"true"}>
                                        {(this.state.languageTo)? this.state.languageTo.description : 'Select language'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {this.renderOptions({
                                            hiddenColection: ImageCreator.conf.hiddenColection.languageTo,
                                            select: this.state.languageTo,
                                            state: "languageTo",
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        </Row>
                        <Row className={"justify-content-md-center mt-2"} xs={"auto"} md={12} lg={12}>
                            <Col className="col-1 m-0 p-0">
                                <Button className="ghostly-button" disabled={!this.state.translation || this.state.idxEquivalent < 0} onClick={ImageCreator.prevEquivalent} variant="light">{"<"}</Button>
                            </Col>
                            <Col className="col-10 m-0 p-0 text-center">
                                <div className="container-image-creator">
                                    <svg id="svg-save" height={sizeConf.height} width={sizeConf.width} >
                                        <rect x="0px" y="0px" height={sizeConf.height} width={sizeConf.width} fill={styleConf.rectFill} opacity="100%" />
                                        
                                        {translation && <>
                                            <text fill={styleConf.textFill} fontSize={sizeConf.phrase.fontSize} fontFamily={styleConf.phrase.fontFamily} x={objPhrase.x} y={sizeConf.height * 0.1} textalign="center">
                                                <Emoji symbols={phrase && countryUsed? countryUsed.codepoints: null}/> {linesPhrase.lines}
                                            </text>
                                            <text fill={styleConf.textFill} fontSize={sizeConf.literal.fontSize} fontFamily={styleConf.literal.fontFamily} x={objLiteral.x} y={yLiteral} textalign="center">
                                                <Emoji symbols={literal && flagLiteral? flagLiteral.codepoints : null}/> {linesLiteral.lines}
                                            </text>
                                            <line x1={sizeConf.width * 0.1} y1={yLine} x2={sizeConf.width * 0.9} y2={yLine} style={styleConf.lineSeparator} />
                                            <text fill={styleConf.textFill} fontSize={sizeConf.meaning.fontSize} fontFamily={styleConf.meaning.fontFamily} x={sizeConf.meaning.x} y={yMeaning}>
                                                {linesMeaning.lines}
                                            </text>
                                            <text fill={styleConf.textFill} fontSize={sizeConf.footer.fontSize} fontFamily={styleConf.footer.fontFamily} x={sizeConf.width * 0.98} y={sizeConf.height * 0.98} style={{textAnchor: 'end'}}>
                                                <Emoji symbols={flagDAW? flagDAW.codepoints : ''}/> {this.state.name}
                                            </text>
                                            {this.state.addWatermark && 
                                                <text fill={styleConf.textFill} fontSize={sizeConf.watermark.fontSize} fontFamily={styleConf.watermark.fontFamily} style={{opacity: styleConf.watermark.opacity, transformOrigin: "bottom left", transform: "rotate(" + sizeConf.watermark.rotation + ")"}} x={sizeConf.width * 0.05} y={sizeConf.height * 1.05}>
                                                    {(flagDAW? flagDAW.emoji : '') + this.state.name}
                                                </text>}
                                        </>}
                                    </svg>
                                    <svg id="svg-visible" height={sizeConf.height} width={sizeConf.width} style={{display: "none"}}>
                                        <rect x="0px" y="0px" height={sizeConf.height} width={sizeConf.width} fill={styleConf.rectFill} opacity="100%" />
                                        
                                        {translation && <>
                                            <text fill={styleConf.textFill} fontSize={sizeConf.phrase.fontSize} fontFamily={styleConf.phrase.fontFamily} x={objPhrase.x} y={sizeConf.height * 0.1} textalign="center">
                                                <Emoji symbols={phrase && countryUsed? countryUsed.codepoints: null}/> {linesPhrase.lines}
                                            </text>
                                            <text fill={styleConf.textFill} fontSize={sizeConf.literal.fontSize} fontFamily={styleConf.literal.fontFamily} x={objLiteral.x} y={yLiteral} textalign="center">
                                                <Emoji symbols={literal && flagLiteral? flagLiteral.codepoints : null}/> {linesLiteral.lines}
                                            </text>
                                            <line x1={sizeConf.width * 0.1} y1={yLine} x2={sizeConf.width * 0.9} y2={yLine} style={styleConf.lineSeparator} />
                                            <text fill={styleConf.textFill} fontSize={sizeConf.meaning.fontSize} fontFamily={styleConf.meaning.fontFamily} x={sizeConf.meaning.x} y={yMeaning}>
                                                {linesMeaning.lines}
                                            </text>
                                            <text fill={styleConf.textFill} fontSize={sizeConf.footer.fontSize} fontFamily={styleConf.footer.fontFamily} x={sizeConf.width * 0.98} y={sizeConf.height * 0.98} style={{textAnchor: 'end'}}>
                                                <Emoji symbols={flagDAW? flagDAW.codepoints : ''}/> {this.state.name}
                                            </text>
                                            {this.state.addWatermark && 
                                                <text fill={styleConf.textFill} fontSize={sizeConf.watermark.fontSize} fontFamily={styleConf.watermark.fontFamily} style={{opacity: styleConf.watermark.opacity, transformOrigin: "bottom left", transform: "rotate(" + sizeConf.watermark.rotation + ")"}} x={sizeConf.width * 0.05} y={sizeConf.height * 1.05}>
                                                    {(flagDAW? flagDAW.emoji : '') + this.state.name}
                                                </text>}
                                        </>}
                                    </svg>
                                </div>
                            </Col>
                            <Col className="col-1 m-0 p-0">
                                <Button className="ghostly-button" disabled={!this.state.translation || this.state.idxEquivalent >= this.state.translation.translations.equivalents[languageFrom.name].length - 1} onClick={ImageCreator.nextEquivalent} variant="light">{">"}</Button>
                            </Col>
                        </Row>
                        <Row className={"text-center mt-3"} xs={"auto"} md={12} lg={12}>
                            <Col className="mt-2" xs={12} md={3}>
                                <Button disabled={!this.state.oldest[languageFrom.name] || this.state.oldest[languageFrom.name]._id === this.state.translation._id} onClick={(event) => {ImageCreator.update(event, {select: 'prev'});}} variant="primary">{'<'}</Button>
                                <span className="container-dice">
                                    <i className={"dice bi bi-dice-" + this.state.dice + "-fill"} onClick={(event) => {ImageCreator.update(event, {animateDice: true, select: 'random'});}}></i>
                                </span>
                                <Button disabled={!this.state.newest[languageFrom.name] || this.state.newest[languageFrom.name]._id === this.state.translation._id} onClick={(event) => {ImageCreator.update(event, {select: 'next'});}} variant="primary">{'>'}</Button>
                            </Col>
                            <Col className="mt-2" xs={4} md={2}>
                                <Form.Label>{this.text['size']}</Form.Label>
                            </Col>
                            <Col className="mt-2" xs={8} md={4}>
                                <Form.Select defaultValue={this.state.downloadSize} id="svg-size" name="size" onChange={(e) => this.setState({downloadSize: e.target.value})} size="sm">
                                    {this.renderFormSizes()}
                                </Form.Select>
                            </Col>
                            <Col className="mt-2" xs={12} md={3}>
                                <Button onClick={this.save} variant="primary">{'Descargar'}</Button>
                            </Col>
                        </Row>
                        <Row className={"justify-content-md-center mt-3"} xs={"auto"} md={12} lg={12}>
                            <p>
                                {explanation}
                            </p>
                            <p></p>
                        </Row>
                    </Container>
                </div>
            );
        } catch (e) {
            console.log(e.message);

            return (
                <div>
                   {this.text['svg-error']}
                </div>
            );
        }
    }
}

export default SVGCreator;