import {drawSimpleShape} from './drawSimpleShape';
import {getSquaredDistance} from './geometryFunctions';
import {Point} from './geometryClasses';
import {random} from './random';
import {webSizes} from '../../../util/canvas/sizes';
import {commonPalettes} from './commonPalettes';

const commonLabels = {
    true: {
        ES: 'Sí',
        EN: 'Yes',
        DE: 'Ja',
    },
    false: {
        ES: 'No',
        EN: 'No',
        DE: 'Nein',
    },
    palette1: {
        ES: 'Paleta 1',
        EN: 'Palette 1',
        DE: 'Palette 1',
    },
    palette2: {
        ES: 'Paleta 2',
        EN: 'Palette 2',
        DE: 'Palette 2',
    },
    palette3: {
        ES: 'Paleta 3',
        EN: 'Palette 3',
        DE: 'Palette 3',
    },
    palette4: {
        ES: 'Paleta 4',
        EN: 'Palette 4',
        DE: 'Palette 4',
    },
    palette5: {
        ES: 'Paleta 5',
        EN: 'Palette 5',
        DE: 'Palette 5',
    }
};

const colection = {
    CaptivatingCircles: {
        title: {
            ES: "Círculos cautivadores",
            EN: "Captivating circles",
            DE: "Faszinierende Kreise",
        },
        searchQuery: 'circulos+cautivadores',
        controls: {
            dropdowns: [
                {
                    name: 'idxPalette',
                    label: 'lbl-palette',
                    options: [
                        {label: commonPalettes.label.blue, value: 0},
                        {label: commonPalettes.label.darkblue, value: 1},
                        {label: commonPalettes.label.midnight, value: 2},
                        {label: commonPalettes.label.green, value: 3}, 
                        {label: commonPalettes.label.gray, value: 4}
                    ],
                    value: 0,
                },
                {
                    name: 'idxIncrement',
                    label: 'lbl-distribution',
                    options: [{label: {ES: 'Lineal', EN: 'Linear', DE: 'Linear'} , value: 0}, {label: {ES: 'Radial', EN: 'Radial', DE: 'Radial'} , value: 1}],
                    value: 0,
                },
                {
                    name: 'size',
                    label: 'lbl-size',
                    options: Object.entries(webSizes).map(([key, value]) => {return {label: value.description , value: key}}),
                    value: 'landscape',
                },
                {
                    name: 'idxPosition',
                    label: 'lbl-order',
                    options: [{label: {ES: 'Aleatorio', EN: 'Random', DE: 'Zufällig'} , value: 'random'}, {label: {ES: 'Superpuesto', EN: 'Overlapping', DE: 'Überlappend'}, value: 'back_first'}, {label: {ES: 'Centro', EN: 'Center', DE: 'Zentrum'}, value: 'center_first'}],
                    value: 'random',
                },
            ],
            sliders: [
                {
                    name: 'cantCircles',
                    label: 'lbl-total',
                    min: 500,
                    max: 4000,
                    step: 100,
                    value: 4000,
                },
            ],
            multiSliders: [
                {
                    // Here, name is used for id. '_' is replace with min and max, for key finding
                    name: '_CC',
                    label: 'lbl-concentrics',
                    min: {
                        name: 'minCC',
                        limit: 2,
                        value: 6,
                    },
                    max: {
                        maxName: 'maxCC',
                        value: 12,
                        limit: 18,
                    },
                    step: 1,
                },
                {
                    name: 'circle_Size',
                    label: 'lbl-circle-size',
                    min: {
                        name: 'circleMinSize',
                        limit: 2,
                        value: 10,
                    },
                    max: {
                        maxName: 'circleMaxSize',
                        value: 50,
                        limit: 100,
                    },
                    step: 1,
                },
            ],
            texts: [],
        },
        draw: {
            md: {
                palettes: [
                    commonPalettes.rgb.blue,
                    commonPalettes.rgb.darkblue,
                    commonPalettes.rgb.midnight,
                    commonPalettes.rgb.green,
                    commonPalettes.rgb.gray,
                ],
                idxPalette: 0,
                idxIncrement: 0,
                idxPosition: 'back_first',
                cantCircles: 4000,
                minCC: 6,
                maxCC: 18,
                circleMinSize: 10,
                circleMaxSize: 50,
                position: 'random',
                data: {
                    type: 'circle',
                    angleStart: 0,
                    angleEnd: 2 * Math.PI,
                },
                size: 'landscape',
                seed: '000000',
            },
            func: ({md, p, width, height }) => {
                const palettes = [
                    {
                        name: 'blue',
                        idxColorBack: 1,
                    },
                    {
                        name: 'darkblue',
                        idxColorBack: 1,
                    },
                    {
                        name: 'midnight2',
                        idxColorBack: 1,
                    },
                    {
                        name: 'darkgreen',
                        idxColorBack: 0,
                    },
                    {
                        name: 'gray',
                        idxColorBack: 1,
                    },
                ];
                const increments = [
                    // Lineal
                    ({i, circleData}) => {return circleData.maxRadius / circleData.cantCircles * (i + 1);},
                    // Exponential
                    ({i, circleData}) => {return circleData.maxRadius / (i + 1);},
                ];
                const pCenter = {
                    x: webSizes[md.size].width * 0.5,
                    y: webSizes[md.size].height * 0.5,
                };
                const positions = {
                    random: () => {
                        return {
                            x: webSizes[md.size].width * Math.random(),
                            y: webSizes[md.size].height * Math.random(),
                        }
                    },
                    back_first: (idx) => {
                        return {
                            x: webSizes[md.size].width * Math.random(),
                            y: webSizes[md.size].height / md.cantCircles * idx + 1 * (Math.random() / 10),
                        }
                    },
                    center_first: (idx, angle) => {
                        return {
                            x: pCenter.x + Math.sin(angle) * webSizes[md.size].width * ((idx + 1) / md.cantCircles),
                            y: pCenter.y + Math.cos(angle) * webSizes[md.size].height * ((idx + 1) / md.cantCircles),
                        }
                    }
                };

                // Background
                const paletteName = palettes[md.idxPalette].name;
                const palette = paletteName? commonPalettes.rgb[paletteName] : palettes[md.idxPalette].colors.rgb;
                const RGB = [...palette].map((col) => {return p.color(`rgb(${col})`)});
                const idxColorBackFill = random(0, RGB.length - 1);
                const backFill = RGB[idxColorBackFill];
                
                p.clear();
                p.strokeWeight(0);
                p.fill(backFill);
                p.rect(0, 0, width, height);

                const pOrigin = {
                    x: 0,
                    y: 0,
                };
                const maxDistance = getSquaredDistance(pCenter, pOrigin);

                let idx;

                for (let j = 0; j < md.cantCircles; j ++) {
                    // Draw captivating circles
                    const angle = Math.random() * 90;
                    const position = positions[md.idxPosition](j, angle);
                    const distance = getSquaredDistance(pCenter, position);
                    const fillIdx = Math.floor(distance / maxDistance * RGB.length);
                    const circleData = {
                        cantCircles: Math.floor(Math.random() * (md.maxCC - md.minCC + 1)) + md.minCC,
                        maxRadius: Math.floor((md.circleMaxSize - md.circleMinSize + 1) * Math.random() + md.circleMinSize),
                        colors: RGB.slice(0,fillIdx).concat(RGB.slice(fillIdx + 1, RGB.length))
                    };
                    const data = {
                        ...md.data,
                        radius: circleData.maxRadius,
                        fill: RGB[fillIdx],
                        strokeWeight: Math.floor(Math.random() + ((circleData.maxRadius / 15) - ((circleData.maxRadius / 30)) + 1)),
                    };

                    drawSimpleShape(p, position, data);
                    data.fill = '';
                    for (let i = 0; i < circleData.cantCircles; i++) {
                        idx = Math.floor(Math.random() * circleData.colors.length);
                        data.stroke = circleData.colors[idx];
                        data.radius = increments[md.idxIncrement]({circleData, i});    
                        drawSimpleShape(p, position, data);
                    }
                }
            },   
        }
    },
    JoyDivision: {
        title: {
            ES: 'Joy Division',
            EN: 'Joy Division',
            DE: 'Joy Division',
        },
        searchQuery: 'joy+division',
        controls: {
            dropdowns: [
                {
                    name: 'idxPalette',
                    label: 'lbl-palette',
                    options: [
                        {label: commonPalettes.label.pastel, value: 0},
                        {label: commonPalettes.label.rainbow, value: 1},
                        {label: commonPalettes.label.blackWhite, value: 2},
                        {label: commonPalettes.label.sky, value: 3},
                    ],
                    value: 0,
                },
                {
                    name: 'idxLineWidth',
                    label: 'lbl-line-width',
                    options: [
                        {label: {ES: 'Chico', EN: 'Small', DE: 'Klein'}, value: 0},
                        {label: {ES: 'Grande', EN: 'Big', DE: 'Groß'}, value: 1},
                    ],
                    value: 0,
                },
                {
                    name: 'idxDistorsion',
                    label: 'lbl-distortion',
                    options: [
                        {label: {ES: 'Débil', EN: 'Weak', DE: 'Schwach'}, value: 0},
                        {label: {ES: 'Centro fuerte', EN: 'Strong center', DE: 'Starkes Zentrum'}, value: 1},
                    ],
                    value: 0,
                },
                {
                    name: 'size',
                    label: 'lbl-size',
                    options: Object.entries(webSizes).map(([key, value]) => {return {label: {ES: value.description.ES, EN: value.description.EN, DE: value.description.DE} , value: key}}),
                    value: 'landscape',
                },
            ],
            sliders: [
                {
                    name: 'step',
                    label: 'lbl-step',
                    min: 15,
                    max: 100,
                    step: 5,
                    value: 35,
                },
                {
                    name: 'marginX',
                    label: 'lbl-margin-x',
                    min: 10,
                    max: 300,
                    step: 5,
                    value: 95,
                },
                {
                    name: 'marginY',
                    label: 'lbl-margin-y',
                    min: 100,
                    max: 220,
                    step: 5,
                    value: 200,
                },
            ],
            multiSliders: [],
            checkboxes: [
                {
                    name: 'stroke',
                    label: 'lbl-stroke',
                    options: [
                        {label: commonLabels.true, value: true},
                        {label: commonLabels.false, value: false},
                    ],
                    value: true,
                },
                {
                    name: 'coloredStroke',
                    label: 'lbl-colored-stroke',
                    options: [
                        {label: commonLabels.true, value: true},
                        {label: commonLabels.false, value: false},
                    ],
                    value: true,
                },
                {
                    name: 'fill',
                    label: 'lbl-fill',
                    options: [
                        {label: commonLabels.true, value: true},
                        {label: commonLabels.false, value: false},
                    ],
                    value: false,
                },
            ],
            texts: [],
        },
        draw: {
            md: {
                idxPalette: 2,
                idxLineWidth: 0,
                idxDistorsion: 1,
                // Project
                coloredStroke: true,
                stroke: true,
                fill: false,
                step: 35,
                marginX: 95,
                marginY: 200,
                size:  'landscape',
                seed: 'JOY!',
            },
            func: ({md, p, width, height }) => {
                const palettes = [
                    // Pastel
                    {
                        background: p.color('rgb(240,240,240)'),
                        colors: commonPalettes.rgb.pastel,
                        name: 'pastel',
                        strokeStyle: (data) => {
                            const colors = commonPalettes.rgb.pastel;
                            const idxColor = data.i % colors.length;
                            const alpha = 1;
                            const col = p.color(`rgba(${colors[idxColor]},${alpha})`);
                        
                            return col;
                        },
                    },
                    // Rainbow complex 
                    // Ease in and out
                    {
                        background: p.color('rgb(250,250,250)'),
                        colors: commonPalettes.rgb.rainbow,
                        name: 'rainbow',
                        strokeStyle: (data) => {
                            const colors = commonPalettes.rgb.rainbow;
                            const idxColor = data.i % colors.length;
                            const alpha = (data.i < data.total * 0.5? data.i : (data.total - data.i) )/ data.total + 0.05;
                            const col = p.color(`rgba(${colors[idxColor]}, ${alpha})`);
                        
                            return col;
                        },
                    },
                    // Black and white
                    {
                        background: p.color(`rgb(${commonPalettes.rgb.blackWhite[0]})`),
                        colors: commonPalettes.rgb.blackWhite,
                        name: 'blackWhite',
                        strokeStyle: (data) => {
                            const col = p.color(`rgb(${commonPalettes.rgb.blackWhite[1]})`);
                            
                            return col;
                        },
                    },
                    // Sky
                    {
                        background: p.color(`rgb(${commonPalettes.rgb.sky[1]})`),
                        colors: commonPalettes.rgb.sky,
                        name: 'sky',
                        strokeStyle: (data) => {
                            const colors = [...commonPalettes.rgb.sky];
                            colors.splice(1,1);
                            const idxColor = Math.floor(Math.random() * colors.length);
                            const col = p.color(`rgb(${colors[idxColor]})`);
                            
                            return col;
                        },
                    },
                ];
                // Line width choosing
                const lineWidths = [
                    () => {return 4;},
                    (data) => {
                        const MAX_VALUE = 8;
                        
                        return MAX_VALUE - data.x/data.width * MAX_VALUE + MAX_VALUE - data.y/data.height}
                  ];
                // Distorsions
                const distorsions = [
                    // Little distorsion wave
                    (data) => {return {x: data.p.x, y: (data.p.y + Math.random() * data.step)};},
                    // More distorted on center
                    (data) => {
                        const FACTOR = 3;
                        const max_distance = getSquaredDistance({x:0, y:0}, {x: data.pCenter.x, y: 0});
                        const distance = getSquaredDistance(data.p, {x: data.pCenter.x, y: data.p.y});
                        const variance = distance / max_distance;
                        const newY = data.p.y - Math.random() * (1 - variance) * data.step * FACTOR;
                    
                        return {x: data.p.x, y: newY};
                    },
                ];
    
                const pCenter = {
                    x: width * 0.5,
                    y: height * 0.5,
                };
    
                p.push();
                p.clear();
                p.fill(palettes[md.idxPalette].background);
                p.rect(0, 0, webSizes[md.size].width + 10, webSizes[md.size].height + 10);
                p.pop();
                p.push();
    
                // Save line data
                const lines = [];
    
                for (let y = md.marginY + md.step; y < height - md.marginY + md.step; y += md.step) {
                    const line = [];
    
                    for (let x = md.marginX; x < webSizes[md.size].width - md.marginX; x += md.step) {
                        const data = {p: {x,y}, step: md.step, pCenter};
                        const distorsion = distorsions[md.idxDistorsion](data);
                        const p = distorsion;
                        
                        line.push(p);
                    }
                    lines.push(line);
                }
    
                // Draw lines
                for (let i = 0; i < lines.length; i++) {
                    const col = palettes[md.idxPalette].strokeStyle({i, total:lines.length});
                    
                    p.strokeWeight(md.stroke? lineWidths[md.idxLineWidth]({x: lines[i][0].x, y: lines[i][0].y, width: webSizes[md.size].width, height: webSizes[md.size].height}) : 0);
                    p.stroke(md.coloredStroke? palettes[md.idxPalette].strokeStyle({i, total:lines.length}) : 'black');
                    
                    if (md.fill) {
                        p.fill(col);
                    } else {
                        p.fill(palettes[md.idxPalette].background);
                    }
                    p.beginShape();
                    p.vertex(lines[i][0].x, lines[i][0].y);           // Start
    
                    for (let j = 0; j < lines[i].length - 1; j++) {
                        const xc = (lines[i][j].x + lines[i][j + 1].x) / 2;
                        const yc = (lines[i][j].y + lines[i][j + 1].y) / 2;
                        
                        p.quadraticVertex (
                            lines[i][j].x, lines[i][j].y,               // Control 1
                            xc, yc,                                     // Control 2
                        );
                    }
                    p.endShape();
                }
                p.pop();
            },
        },
    },
    ChaoticCircles: {
        title: {
            ES: "Círculos caóticos",
            EN: "Chaotic circles",
            DE: "Chaotische Kreise",
        },
        searchQuery: 'circulos+caoticos',
        controls: {
            dropdowns: [
                {
                    name: 'idxPalette',
                    label: 'lbl-palette',
                    options: [
                        {label: commonLabels.palette1, value: 0},
                        {label: commonLabels.palette2, value: 1},
                        {label: commonLabels.palette3, value: 2},
                        {label: commonLabels.palette4, value: 3},
                        {label: commonLabels.palette5, value: 4},
                    ],
                    value: 0,
                },
                {
                    name: 'size',
                    label: 'lbl-size',
                    options: Object.entries(webSizes).map(([key, value]) => {return {label: {ES: value.description.ES, EN: value.description.EN, DE: value.description.DE} , value: key}}),
                    value: 'landscape',
                },
            ],
            sliders: [
                {
                    name: 'cantSections',
                    label: 'lbl-cha-sections',
                    min: 4,
                    max: 50,
                    step: 1,
                    value: 19,
                },
                {
                    name: 'cantCircles',
                    label: 'lbl-total',
                    min: 5,
                    max: 100,
                    step: 1,
                    value: 66,
                },
                {
                    name: 'min_scale_rect',
                    label: 'lbl-cha-min-scale',
                    min: 0.1,
                    max: 0.9,
                    step: 0.1,
                    value: 0.1,
                },
                {
                    name: 'max_scale_rect',
                    label: 'lbl-cha-max-scale',
                    min: 0.9,
                    max: 1.9,
                    step: 0.1,
                    value: 1.3,
                },
                {
                    name: 'pCenter',
                    label: 'lbl-cha-pos',
                    min: 0,
                    max: 8,
                    step: 1,
                    value: 5,
                },
            ],
            multiSliders: [],
            checkboxes: [],
            texts: [],
        },
        draw: {
            md: {
                // Indexes
                idxPalette: 4,
                data: {
                    type: 'circle',
                    distances: null,
                    fillStyle: 'pink',
                    lineWidth: 3,
                    strokeStyle: 'black',
                },
                cantSections: 19,
                cantCircles: 66,
                cantArcs: 1,
                max_scale_rect: 1.3,
                min_scale_rect: 0.1,
                pCenter: 5,
                size: 'landscape',
                seed: 'CHAOTIC',
              },
            func: ({md, p, width, height }) => {
                const palettes = [
                    {
                      colors: {
                        hex: [
                            '#FFEAD0', '#F76F8E', '#96616B', '#37505C', '#113537',
                        ]
                      }
                    },
                    {
                      colors: {
                        hex: [
                            '#420039', '#932F6D', '#E07BE0', '#DCCCFF', '#F6F2FF',
                        ]
                      }
                    },
                    {
                      colors: {
                        hex: [
                            '#1B998B', '#2D3047', '#FFFD82', '#FF9B71', '#E84855',
                        ]
                      }
                    },
                    {
                      colors: {
                        hex: [
                            '#9E6240', '#DEA47E', '#CD4631', '#F8F2DC', '#81ADC8',
                        ]
                      }
                    },
                    {
                      colors: {
                        hex: [
                            '#F7F7F2', '#E4E6C3', '#899878', '#222725', '#121113',
                        ]
                      }
                    },
                ];
                const getNextIdx = (key, arr, idx) => {
                    const nextFuncs = {
                        next: (arr, idx) => {
                            return (idx + 1) % arr.length;
                        },
                        prev: (arr, idx) => {
                            return (idx - 1 + arr.length) % arr.length;
                        },
                        random: (arr, idx) => {
                            return Math.floor(Math.random() * arr.length);
                        },
                    };
                  
                    return nextFuncs[key](arr, idx);
                };
                const changeColor = (key, color, ammount) => {
                    const rgb = {
                        r: parseInt(color[1] + color[2], 16),
                        g: parseInt(color[3] + color[4], 16),
                        b: parseInt(color[5] + color[6], 16),
                    };
                    const changeFuncs = {
                        lighter: (rgb, ammount) => {
                            return {
                                r: rgb.r + ammount > 255? 255 : rgb.r + ammount,
                                g: rgb.g + ammount > 255? 255 : rgb.g + ammount,
                                b: rgb.b + ammount > 255? 255 : rgb.b + ammount,
                            };
                        },
                        darker: (rgb, ammount) => {
                            return {
                                r: rgb.r - ammount < 0? 0 : rgb.r - ammount,
                                g: rgb.g - ammount < 0? 0 : rgb.g - ammount,
                                b: rgb.b - ammount < 0? 0 : rgb.b - ammount,
                            };
                        },
                    };
                  
                    const newRGB = changeFuncs[key](rgb, ammount);
                  
                    return `#${newRGB['r'].toString(16)}${newRGB['g'].toString(16)}${newRGB['b'].toString(16)}`
                }

                // Background
                p.clear();
                p.strokeWeight(0);
                p.rect(0, 0, width, height);

                const RADIUS = width * 2;
                
                const w = width * 0.015;
                const h = height * 0.75;
                const angSection = 2 * Math.PI / md.cantSections;
                const cx = width * 0.5 * (md.pCenter % 3);
                const cy = height * 0.5 * Math.floor(md.pCenter / 3);
                const changeTo = Math.random() > 0.5? 'darker' : 'lighter';

                let x, y, idxColor = Math.floor(Math.random() * palettes[md.idxPalette].colors.hex.length);

                p.fill(changeColor(changeTo, palettes[md.idxPalette].colors.hex[idxColor], parseInt('22', 16)));
                p.rect(0, 0, width, height);
                
                for (let j = 0; j < md.cantCircles; j++) {
                    const r = RADIUS / md.cantCircles * (j + 1);

                    // Circle
                    for (let i = 0; i < md.cantSections; i++) {
                        x = cx + r * Math.sin(angSection * i);
                        y = cy + r * Math.cos(angSection * i);
                        idxColor = getNextIdx('random', palettes[md.idxPalette].colors.hex, idxColor);

                        // Clock rectangle
                        p.push();
                        p.translate(x, y);
                        p.rotate(-angSection * i);
                        p.scale(Math.random() * (md.max_scale_rect - md.min_scale_rect), 0.25);
                        p.fill(palettes[md.idxPalette].colors.hex[idxColor]);
                        p.rect(-w * Math.random(), h * 0.5, w, h * 0.4);
                        p.pop();

                        // Arc
                        p.push();
                        p.strokeCap(md.data.strokeCap? md.data.strokeCap : p.SQUARE);
                        p.translate(cx, cy);
                        p.rotate(-angSection * i);
                        p.strokeWeight(w * Math.random());
                        p.stroke(palettes[md.idxPalette].colors.hex[idxColor]);
                        p.noFill();
                        const v = Math.abs(r + (h * (Math.random() - 0.5)));
                        p.arc(0, 0, v, v, -angSection * Math.random() * md.cantArcs, angSection * Math.random() * md.cantArcs);
                        p.pop();
                    }
                }
            },
        },
    },
    HypnoticSquares: {
        title: {
            ES: "Cuadrados hipnóticos",
            EN: "Hypnotic Squares",
            DE: "Hypnotische Quadrate",
        },
        controls: {
            dropdowns: [
                {
                    name: 'idxPalette',
                    label: 'lbl-palette',
                    options: [
                        {label: commonPalettes.label.fire, value: 0},
                        {label: commonPalettes.label.pastel, value: 1},
                        {label: commonPalettes.label.rainbow, value: 2},
                        {label: commonPalettes.label.cream, value: 3}, 
                        {label: commonPalettes.label.cthulhu, value: 4}
                    ],
                    value: 2,
                },
                {
                    name: 'size',
                    label: 'lbl-size',
                    options: Object.entries(webSizes).map(([key, value]) => {return {label: {ES: value.description.ES, EN: value.description.EN, DE: value.description.DE} , value: key}}),
                    value: 'landscape',
                },
            ],
            sliders: [
                {
                    name: 'cantGrids',
                    label: 'lbl-grid-size',
                    min: 1,
                    max: 9,
                    step: 1,
                    value: 4,
                },
                {
                    name: 'min',
                    label: 'lbl-max-square',
                    min: 1,
                    max: 25,
                    step: 1,
                    value: 15,
                },
                {
                    name: 'strokeWeight',
                    label: 'lbl-line-size',
                    min: 1,
                    max: 25,
                    step: 1,
                    value: 20,
                },
                {
                    name: 'factor',
                    label: 'lbl-factor',
                    min: 0.35,
                    max: 0.95,
                    step: 0.05,
                    value: 0.75,
                },
                {
                    name: 'angle',
                    label: 'lbl-angle',
                    min: 0.00,
                    max: 0.45,
                    step: 0.05,
                    value: 0.35,
                },
            ],
            multiSliders: [],
            checkboxes: [
                {
                    name: 'keepRolling',
                    label: 'lbl-roll',
                    options: [{label: {ES: 'Verdadero'}, value: true}, {label: {ES: 'Falso'}, value: false}],
                    value: false,
                },
            ],
            texts: [],
        },
        draw: {
            md: {
                // Color choosing
                idxPalette: 2,
                // Particular data
                type: 'square',
                fillStyle: 'black',
                strokeWeight: 25,
                strokeStyle: 'transparent',
                globalCompositeOperation: '',
                lineCap: '',
                // Project data
                cantGrids: 4,
                movementsArr: [[-1, 0 , 1, 0], [1, 0 , -1, 0], [0]],
                // Hypnotic data
                angle: 0.35,
                movements: null,
                factor: 0.75,
                cant: 5,
                min: 15,
                keepRolling: false,
                seed: 'HYPNOTIC SQUARES',
                size: 'landscape',
                width: webSizes['landscape'].width,
                height: webSizes['landscape'].height,
            },
            func: ({md, p, width, height }) => {
                const palettes = [
                    {
                        idxColorBack: 3,
                        name: 'fire',
                    },
                    {
                        idxColorBack: 3,
                        name: 'pastel',
                    },
                    {
                        idxColorBack: 0,
                        name: 'rainbow',
                    },
                    {
                        idxColorBack: 1,
                        name: 'cream',
                    },
                    {
                        idxColorBack: 0,
                        name: 'cthulhu',
                    },
                ];
                const drawHypnoticSquare = (hypnotic, p, position, data, palette) => {
                    const actualSize = {
                      width: data.strokeWeight + (data.size? data.size : data.width),
                      height: data.strokeWeight + (data.size? data.size : data.height),
                    };
                    const actualMin = (hypnotic.min? hypnotic.min : 0) + data.strokeWeight;
                    const idxMovements = (hypnotic.idxStart + hypnotic.i) % hypnotic.movements.length;
                  
                    let done = false;
                    
                    if (!done && (hypnotic.min && (actualMin < actualSize.width && actualMin < actualSize.height))) {
                      
                  
                      data.width = Math.floor(data.width * hypnotic.factor);
                      data.height = Math.floor(data.height * hypnotic.factor);
                      data.strokeWeight = Math.ceil(data.strokeWeight * hypnotic.factor);
                      data.stroke = p.color(`rgb(${palette[random(0, palette.length - 1)]})`);
                  
                      hypnotic.i++;
                      position.dx = 0.25 * hypnotic.movements[idxMovements] * Math.floor((data.width - hypnotic.i * data.strokeWeight) * (1 - hypnotic.factor));
                      position.dy = 0.25 * hypnotic.movements[(idxMovements + 1) % hypnotic.movements.length] * Math.floor((data.height - hypnotic.i * data.strokeWeight) * (1 - hypnotic.factor));
                      position.x = Math.floor(position.baseX + position.dx + position.margin.x * 0.5);
                      position.y = Math.floor(position.baseY + position.dy + position.margin.y * 0.5);
                      position.angle += (hypnotic.i % 2 === 1? 1 : (hypnotic.keepRolling? 0 : -1)) * hypnotic.angle;
                      drawSimpleShape(p, position, data);
                      drawHypnoticSquare(hypnotic, p, position, data, palette)
                      done = true;
                    }  
                };

                // Background
                const size = width > height? height : width;
                const paletteName = palettes[md.idxPalette].name;
                const palette = paletteName? commonPalettes.rgb[paletteName] : palettes[md.idxPalette].colors.rgb;
                const RGB = [...palette];
                const idxColorBackFill = palettes[md.idxPalette].idxColorBack;
                const backFill = p.color(`rgb(${RGB.splice(idxColorBackFill, 1)[0]})`);
                
                //HEX = HEX.filter((color) => {return color !== backFill && color !== circleFill;})
                p.clear();
                p.strokeWeight(0);
                p.fill(backFill);
                p.rect(0, 0, width, height);

                const grid = {
                    height: Math.floor((size - md.strokeWeight * md.cantGrids) / md.cantGrids),
                    width: Math.floor((size - md.strokeWeight * md.cantGrids) / md.cantGrids),
                };
                const margin = {
                    y: (webSizes[md.size].height - (grid.height * md.cantGrids)) / (md.cantGrids + 2),
                    x: (webSizes[md.size].width - (grid.width * md.cantGrids)) / (md.cantGrids + 2),
                };
                const FACTOR_SMALL = 0.35;
                const FACTOR_BIG = 0.65;
                const offsetX = (webSizes[md.size].width < webSizes[md.size].height? FACTOR_SMALL : FACTOR_BIG) * Math.ceil((webSizes[md.size].width - (grid.width ) * md.cantGrids) / md.cantGrids);
                const offsetY = (webSizes[md.size].width > webSizes[md.size].height? FACTOR_SMALL : FACTOR_BIG) * Math.ceil((webSizes[md.size].height - (grid.height ) * md.cantGrids) / md.cantGrids);
                const data = {
                    type: 'square',
                    width: grid.width,
                    height: grid.height,
                    fill: 'transparent',
                    stroke: p.color(`rgb(${RGB[random(0, RGB.length - 1)]})`),
                    strokeWeight: md.strokeWeight,
                    strokeCap: p.PROJECT,
                };
                for (let i = 0; i < md.cantGrids; i++) {
                    for (let j = 0; j < md.cantGrids; j++) {
                        const baseX = Math.floor(margin.x + j * (grid.width + margin.x) + Math.ceil(grid.width * 0.5));
                        const baseY = Math.floor(margin.y + i * (grid.height + margin.y) + Math.ceil(grid.height * 0.5));
                        const position = {
                            baseX,
                            baseY,
                            x: Math.floor(margin.x * 0.5 + offsetX + j * (grid.width + margin.x) + Math.ceil(grid.width * 0.5)),
                            y: Math.floor(margin.y * 0.5 + offsetY + i * (grid.height + margin.y) + Math.ceil(grid.height * 0.5)),
                            angle: md.angle * Math.PI,
                            margin,
                        };
                        const hypnotic = {
                            angle: md.factor,
                            movements: md.movements,
                            factor: md.factor,
                            cant: md.cant,
                            min: md.min,
                            keepRolling: md.keepRolling,
                            movements: md.movementsArr[random(0, md.movementsArr.length - 1)],
                            angle: md.angle * Math.PI,
                            i: 0,
                        };

                        hypnotic.idxStart = random(0, hypnotic.movements.length);
                        data.width = grid.width;
                        data.height = grid.height;
                        data.fill = 'transparent';
                        data.stroke = p.color(`rgb(${RGB[random(0, RGB.length - 1)]})`);
                        drawHypnoticSquare(hypnotic, p, position, {...data}, RGB);
                    }
                }

            },
        }
    },
    Nexus: {
        title: {
            ES: "Nexos",
            EN: "Nexus",
            DE: "Nexus",
        },
        controls: {
            dropdowns: [
                {
                    name: 'idxPalette',
                    label: 'lbl-palette',
                    options: [
                        {label: commonPalettes.label.military, value: 0},
                        {label: commonPalettes.label.passion, value: 1},
                        {label: commonPalettes.label.fire, value: 2},
                        {label: commonPalettes.label.elegant, value: 3}, 
                        {label: commonPalettes.label.duality, value: 4}, 
                        {label: commonPalettes.label.lady, value: 5}
                    ],
                    value: 2,
                },
                {
                    name: 'size',
                    label: 'lbl-size',
                    options: Object.entries(webSizes).map(([key, value]) => {return {label: {ES: value.description.ES, EN: value.description.EN, DE: value.description.DE} , value: key}}),
                    value: 'landscape',
                },
            ],
            sliders: [
                {
                    name: 'cantPoints',
                    label: 'lbl-cant-points',
                    min: 100,
                    max: 3000,
                    step: 1,
                    value: 1500,
                },
                {
                    name: 'maxInteractions',
                    label: 'lbl-max-interactions',
                    min: 1,
                    max: 5,
                    step: 1,
                    value: 2,
                },
                {
                    name: 'strokeWeight',
                    label: 'lbl-stroke-weigth',
                    min: 1,
                    max: 5,
                    step: 1,
                    value: 3,
                },
            ],
            multiSliders: [],
            checkboxes: [],
            texts: [],
        },
        draw: {
            md: {
                // Indexes
                idxPalette: 5,
                // General Data
                cantPoints: 1500,
                maxInteractions: 2,
                strokeCap: 'ROUND',
                blendMode: 'BLEND',
                strokeWeight: 3,
                strokeStyle: 'black',
                size: 'landscape',
                width: webSizes['landscape'].width,
                height: webSizes['landscape'].height,
            },
            func: ({md, p, width, height }) => {
                const palettes = [
                    {
                      name: 'military',
                      idxColorBack: 3,
                      // 1-3-5-9
                    },
                    {
                      name: 'passion',
                      idxColorBack: 6,
                      // 5-6
                    },
                    {
                      name: 'fire',
                      idxColorBack: 1,
                      // 0-1-2-5
                    },
                    {
                      name: 'elegant',
                      idxColorBack: 3,
                      // 3-4
                    },
                    {
                      name: 'duality',
                      idxColorBack: 6,
                      // 4-6-8
                    },
                    {
                      name: 'lady',
                      idxColorBack: 4,
                      // 0-2-3
                    },
                ];

                // Background
                const paletteName = palettes[md.idxPalette].name;
                const palette = paletteName? commonPalettes.rgb[paletteName] : palettes[md.idxPalette].colors.rgb;
                let RGB = [...palette];
                const idxColorBackFill = palettes[md.idxPalette].idxColorBack;
                const backFill = RGB.splice(idxColorBackFill, 1)[0];
                
                RGB = RGB.filter((color) => {return color !== backFill;});
                p.clear();
                p.strokeWeight(0);
                p.fill(`rgb(${backFill})`);
                p.rect(0, 0, webSizes[md.size].width, webSizes[md.size].height);
                
                
                p.push();
                p.blendMode(p[md.blendMode]);
                p.strokeCap(p[md.strokeCap]);
                let arrPoints = [];

                // Create points
                for (let i = 0; i < md.cantPoints; i++) {
                    const randomX = Math.random();
                    const randomY = Math.random();

                    const options = {
                        x: randomX < 0.05? 0 : (randomX > 0.95? width : Math.random() * width),
                        y: randomY < 0.05? 0 : (randomY > 0.95? height : Math.random() * height),
                        r: 0,
                    };
                
                    arrPoints.push(new Point(options));
                }

                // Find which points interact with eachother (closest ones)
                for (let i = 0; i < md.cantPoints; i++) {
                    const point = arrPoints[i];
                    const closest = [];

                    for (let j = 0; j < md.cantPoints; j++) {
                        if (j !== i) {
                            const p = arrPoints[j];
                            const distance = getSquaredDistance(point, p);

                            if (closest.length < md.maxInteractions) {
                                closest.push({p, distance});
                            } else {
                                closest.sort((a, b) => {
                                    if (a.distance < b.distance) {
                                        return -1;
                                    } else {
                                        return 1;
                                    }
                                });
                                
                                if (distance < closest[closest.length - 1].distance) {
                                    closest[closest.length - 1] = {p, distance};
                                }
                            }
                        }
                    }
                    arrPoints[i].closest = closest;
                }

                // Draw lines between interative points
                for (let i = 0; i < md.cantPoints; i++) {
                    const point = arrPoints[i];
                    
                    for (let j = 0; j < point.closest.length; j++) {
                        const col = p.color(`rgb(${RGB[i % RGB.length]})`);

                        p.push();
                        p.stroke(col);
                        p.strokeWeight(md.strokeWeight);
                        p.beginShape();
                        p.line(
                            point.x, point.y,
                            point.closest[j].p.x, point.closest[j].p.y
                        );
                        p.endShape();
                        p.pop();
                    }
                }
                
                p.pop();
            },
        }
    },
    ValentinCards: {/*
        title: {
            ES: "Carta de amor",
            EN: "Love letter",
            DE: "Liebesbrief",
        },
        controls: {
            dropdowns: [
                {
                    name: 'idxPalette',
                    label: 'lbl-palette',
                    options: [{label: {ES: 'Azul'} , value: 0}, {label: {ES: 'Medianoche'} , value: 1}, {label: {ES: 'Verde'} , value: 2}, {label: {ES: 'Gris'} , value: 3}],
                    value: 0,
                },
                {
                    name: 'size',
                    label: 'lbl-size',
                    options: Object.entries(webSizes).map(([key, value]) => {return {label: value.description , value: key}}),
                    value: 'landscape',
                },
            ],
            sliders: [
                {
                    name: 'cantCircles',
                    label: {
                        ES: 'Total círculos',
                        EN: 'Total circles'
                    },
                    min: 500,
                    max: 4000,
                    step: 100,
                    value: 4000,
                },
            ],
            multiSliders: [
                {
                    // Here, name is used for id. '_' is replace with min and max, for key finding
                    name: '_CC',
                    label: {
                        ES: 'Concéntricos por círculo',
                        EN: 'Concentrics per circle'
                    },
                    min: {
                        name: 'minCC',
                        limit: 2,
                        value: 6,
                    },
                    max: {
                        maxName: 'maxCC',
                        value: 12,
                        limit: 18,
                    },
                    step: 1,
                },
            ],
            texts: [],
        },
        draw: {
            md: {
            }
        },
        func: ({md, context, width, height }) => {
        },*/
    },
};
Object.freeze(colection);

export default colection;