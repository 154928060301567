import React, {useContext} from 'react';

import Context from '../../../context/language/Context.jsx';
import dictionary from './lang/index.js';

const Error404 = function() {
    const {language} = useContext(Context);
    const text = dictionary[language];

    return (
        <div className="content-container blog-entry">
            <h1 className="title">{text['title']}</h1>

            <p>
                {text['message']}
            </p>
        </div>
    );
}

export default Error404;