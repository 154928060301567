import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from 'axios';

const DBHandler = () => {
    const COOKIE_OPTIONS = {path: '/', sameSite: 'Strict', secure: false};
    const TIMEOUT = 5000;
    const navigate = useNavigate();
    const [cookies, removeCookie] = useCookies(['token']);

    const delete_entry = async () => {
        try {
            const res = await axios.post(
                '/api/delete', 
                {}, 
                {timeout: TIMEOUT}
            );

            alert(res);
        } catch (e) {
            console.log(e.message);
        }
    };

    const show = async () => {
        try {
            const res = await axios.post(
                '/api/show', 
                {}, 
                {timeout: TIMEOUT}
            );

            alert(res);
        } catch (e) {
            console.log(e.message);
        }
    };

    const upload = async () => {
        try {
            const res = await axios.post(
                '/api/upload',
                {},
                {   withCredentials: true,
                    credentials: 'include',
                    timeout: TIMEOUT}
                );

            alert(res);
        } catch (e) {
            console.log(e.message);
        }
    }

    useEffect(() => {
        // Cancel request in case of demounting or timeout
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        let status = false;

        const verifyCookie = async () => {
            try {
                if (!cookies.token || cookies.token === 'undefined') {
                    navigate(`/ES/admin/login`);
                } else {
                    const { data } = await axios.post(
                        "/api/admin/verifyUser",
                        {},
                        {   cancelToken: source.token,
                            withCredentials: true,
                            credentials: 'include',
                            timeout: TIMEOUT}
                    );
                    status = data.status;
                }
                if (!status) {
                    removeCookie("token", COOKIE_OPTIONS);
                    navigate(`/ES/admin/login`);
                }
            } catch (err) {
                if (axios.isCancel(err)) {
                    console.log('successfully aborted');
                } else {
                    console.log(err);
                }
            };
        };

        verifyCookie();

        return () => {
            // cancel the request before component unmounts
            source.cancel();
        };
    }, []);

    return (
        <div>
            <button onClick={delete_entry}>
                DELETE
            </button>
            <button onClick={show}>
                SHOW
            </button>
            <button onClick={upload}>
                UPLOAD
            </button>
        </div>
    );
}

export default DBHandler;