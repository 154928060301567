/*
    TODO:
        * Add footer box for sign
        * Check font size for footers
        * Add specific sizes for web
*/

const sizes = {
    small: {
        description: 'Small',
        height: 320,
        width: 480,
        phrase: {
            charsPerLine: 22,
            fontSize: '30px',
            x: 50,
            y: 40,
        },
        literal: {
            charsPerLine: 30,
            fontSize: '20px',
            x: 70,
            y: 70,
        },
        meaning: {
            charsPerLine: 50,
            fontSize: '16px',
            x: 25,
            y: 175,
        },
        footer: {
            fontSize: '14px',
        },
        watermark: {
            fontSize: '28px',
            rotation: "-35deg",
            translateX: 0,
            translateY: -100,
        },
    },
    medium: {
        description: 'Medium',
        height: 600,
        width: 800,
        phrase: {
            charsPerLine: 25,
            fontSize: '50px',
            x: 75,
            y: 40,
        },
        literal: {
            charsPerLine: 35,
            fontSize: '36px',
            x: 150,
            y: 130,
        },
        meaning: {
            charsPerLine: 55,
            fontSize: '22px',
            x: 50,
            y: 350,
        },
        footer: {
            fontSize: '20px',
        },
        watermark: {
            fontSize: '58px',
            rotation: "-35deg",
            translateX: 25,
            translateY: -175,
        },
    },
    large: {
        description: 'Large',
        height: 800,
        width: 1024,
        phrase: {
            charsPerLine: 40,
            fontSize: '45px',
            x: 75,
            y: 40,
        },
        literal: {
            charsPerLine: 30,
            fontSize: '40px',
            x: 150,
            y: 250,
        },
        meaning: {
            charsPerLine: 70,
            fontSize: '25px',
            x: 50,
            y: 450,
        },
        footer: {
            fontSize: '30px',
        },
        watermark: {
            fontSize: '72px',
            rotation: "-35deg",
            translateX: 50,
            translateY: -200,
        },
    },
    fb_landscape: {
        description: 'Facebook landscape',
        height: 630,
        width: 1200,
        phrase: {
            charsPerLine: 25,
            fontSize: '72px',
            x: 100,
            y: 120,
        },
        literal: {
            charsPerLine: 40,
            fontSize: '55px',
            x: 125,
            y: 150,
        },
        meaning: {
            charsPerLine: 50,
            fontSize: '40px',
            x: 60,
            y: 375,
        },
        footer: {
            fontSize: '30px',
        },
        watermark: {
            fontSize: '70px',
            rotation: "-25deg",
            translateX: 250,
            translateY: -200,
        },
    },
    fb_square: {
        description: 'Facebook square',
        height: 1200,
        width: 1200,
        phrase: {
            charsPerLine: 25,
            fontSize: '72px',
            x: 75,
            y: 72,
        },
        literal: {
            charsPerLine: 30,
            fontSize: '60px',
            x: 150,
            y: 250,
        },
        meaning: {
            charsPerLine: 35,
            fontSize: '48px',
            x: 50,
            y: 600,
        },
        footer: {
            fontSize: '32px',
        },
        watermark: {
            fontSize: '88px',
            rotation: "-45deg",
            translateX: -270,
            translateY: -216,
        },
    },
    fb_story: {
        description: 'Facebook story',
        height: 1920,                               // Ratio 16:9
        width: 1080,
        phrase: {
            charsPerLine: 25,
            fontSize: '72px',
            x: 100,
            y: 40,
        },
        literal: {
            charsPerLine: 30,
            fontSize: '55px',
            x: 125,
            y: 350,
        },
        meaning: {
            charsPerLine: 45,
            fontSize: '40px',
            x: 60,
            y: 1150,
        },
        footer: {
            fontSize: '32px',
        },
        watermark: {
            fontSize: '100px',
            rotation: "-70deg",
            translateX: -1400,
            translateY: 275,
        },
    },
    fb_vertical: {
        description: 'Facebook vertical',
        height: 1200,
        width: 630,
        phrase: {
            charsPerLine: 20,
            fontSize: '45px',
            x: 75,
            y: 40,
        },
        literal: {
            charsPerLine: 25,
            fontSize: '34px',
            x: 75,
            y: 290,
        },
        meaning: {
            charsPerLine: 45,
            fontSize: '24px',
            x: 50,
            y: 650,
        },
        footer: {
            fontSize: '26px',
        },
        watermark: {
            fontSize: '70px',
            rotation: "-70deg",
            translateX: -800,
            translateY: 175,
        },
    },
    ig_landscape: {
        description: 'Instagram landscape',
        height: 1080,
        width: 1350,
        phrase: {
            charsPerLine: 50,
            fontSize: '72px',
            x: 100,
            y: 40,
        },
        literal: {
            charsPerLine: 50,
            fontSize: '55px',
            x: 125,
            y: 250,
        },
        meaning: {
            charsPerLine: 55,
            fontSize: '40px',
            x: 60,
            y: 500,
        },
        footer: {
            fontSize: '14px',
        },
        watermark: {
            fontSize: '80px',
            rotation: "-35deg",
            translateX: -100,
            translateY: -256,
        },
    },
    ig_square: {
        description: 'Instagram square',
        height: 1080,                              // Ratio 1:1
        width: 1080,
        phrase: {
            charsPerLine: 25,
            fontSize: '72px',
            x: 75,
            y: 72,
        },
        literal: {
            charsPerLine: 30,
            fontSize: '60px',
            x: 250,
            y: 130,
        },
        meaning: {
            charsPerLine: 35,
            fontSize: '48px',
            x: 50,
            y: 600,
        },
        footer: {
            fontSize: '32px',
        },
        watermark: {
            fontSize: '88px',
            rotation: "-45deg",
            translateX: -270,
            translateY: -216,
        },
    },
    ig_story: {
        description: 'Instagram story',
        height: 1920,                              // Ratio 9:16
        width: 1080,
        phrase: {
            charsPerLine: 22,
            fontSize: '72px',
            x: 100,
            y: 40,
        },
        literal: {
            charsPerLine: 30,
            fontSize: '55px',
            x: 125,
            y: 500,
        },
        meaning: {
            charsPerLine: 45,
            fontSize: '40px',
            x: 60,
            y: 1200,
        },
        footer: {
            fontSize: '32px',
        },
        watermark: {
            fontSize: '100px',
            rotation: "-70deg",
            translateX: -1400,
            translateY: 275,
        },
    },
    ig_vertical: {
        description: 'Instagram vertical',
        height: 1080,
        width: 566,
        phrase: {
            charsPerLine: 17,
            fontSize: '45px',
            x: 75,
            y: 40,
        },
        literal: {
            charsPerLine: 25,
            fontSize: '35px',
            x: 75,
            y: 200,
        },
        meaning: {
            charsPerLine: 40,
            fontSize: '22px',
            x: 50,
            y: 450,
        },
        footer: {
            fontSize: '14px',
        },
        watermark: {
            fontSize: '60px',
            rotation: "-70deg",
            translateX: -800,
            translateY: 175,
        },
    },
    li_landscape: {
        description: 'Linkedin landscape',
        height: 630,
        width: 1200,
        phrase: {
            charsPerLine: 25,
            fontSize: '72px',
            x: 100,
            y: 50,
        },
        literal: {
            charsPerLine: 40,
            fontSize: '55px',
            x: 125,
            y: 150,
        },
        meaning: {
            charsPerLine: 50,
            fontSize: '40px',
            x: 60,
            y: 450,
        },
        footer: {
            fontSize: '30px',
        },
        watermark: {
            fontSize: '70px',
            rotation: "-25deg",
            translateX: 250,
            translateY: -200,
        },
    },
    li_vertical: {
        description: 'Linkedin vertical',
        height: 1200,
        width: 630,
        phrase: {
            charsPerLine: 17,
            fontSize: '50px',
            x: 75,
            y: 40,
        },
        literal: {
            charsPerLine: 25,
            fontSize: '36px',
            x: 75,
            y: 300,
        },
        meaning: {
            charsPerLine: 40,
            fontSize: '24px',
            x: 50,
            y: 650,
        },
        footer: {
            fontSize: '26px',
        },
        watermark: {
            fontSize: '70px',
            rotation: "-70deg",
            translateX: -850,
            translateY: 250,
        },
    },
    pi_square: {
        description: 'Pinterest square',
        height: 1000,
        width: 1000,
        phrase: {
            charsPerLine: 25,
            fontSize: '62px',
            x: 80,
            y: 100,
        },
        literal: {
            charsPerLine: 28,
            fontSize: '55px',
            x: 150,
            y: 250,
        },
        meaning: {
            charsPerLine: 35,
            fontSize: '48px',
            x: 50,
            y: 550,
        },
        footer: {
            fontSize: '32px',
        },
        watermark: {
            fontSize: '75px',
            rotation: "-45deg",
            translateX: -270,
            translateY: -216,
        },
    },
    tw_landscape: {
        description: 'Twitter landscape',
        height: 512,
        width: 1024,
        phrase: {
            charsPerLine: 32,
            fontSize: '55px',
            x: 50,
            y: 50,
        },
        literal: {
            charsPerLine: 40,
            fontSize: '40px',
            x: 100,
            y: 125,
        },
        meaning: {
            charsPerLine: 55,
            fontSize: '30px',
            x: 75,
            y: 350,
        },
        footer: {
            fontSize: '14px',
        },
        watermark: {
            fontSize: '60px',
            rotation: "-25deg",
            translateX: 250,
            translateY: -180,
        },
    },
    wa_story: {
        description: 'Whatsapp story',
        height: 1334,
        width: 750,
        phrase: {
            charsPerLine: 17,
            fontSize: '62px',
            x: 50,
            y: 175,
        },
        literal: {
            charsPerLine: 22,
            fontSize: '50px',
            x: 100,
            y: 400,
        },
        meaning: {
            charsPerLine: 35,
            fontSize: '34px',
            x: 75,
            y: 800,
        },
        footer: {
            fontSize: '28px',
        },
        watermark: {
            fontSize: '70px',
            rotation: "-60deg",
            translateX: -850,
            translateY: 125,
        },
    },
};
// Canvas and svg tag sizes for web
const webSizes = {
    /*square: {
        description: {
            ES: 'Cuadrado',
            EN: 'Square',
            DE: 'Quadrate',
        },
        height: 470,
        width: 470,
        footer: {
            fontSize: '24px',
            box: {
                x: 282,             // width * 0.6
                y: 435,             // width * 0.93
                width: 188,         // width * 0.4
                height: 36,         // width * 0.075
            },
        },
        watermark: {
            fontSize: '70px',
            rotation: "-45deg",
            translateX: -120,
            translateY: -90,
        },
    },*/
    landscape: {
        description: {
            ES: 'Apaisado',
            EN: 'Oblong',
            DE: 'Länglich',
        },
        height: 450,
        width: 800,
        footer: {
            box: {
                fontSize: 30,
                x: 800 * 0.7,
                y: 450 * 0.9,
                width: 800 * 0.3,
                height: 450 * 0.1,
            },
        },
        watermark: {
            fontSize: 70,
            rotation: "-25deg",
            translateX: 150,
            translateY: -150,
        },
    },
    vertical: {
        description: {
            ES: 'Vertical',
            EN: 'Vertical',
            DE: 'Vertical',
        },
        height: 800,
        width: 450,
        footer: {
            box: {
                fontSize: 28,
                x: 450 * 0.55,
                y: 800 * 0.93,
                width: 450 * 0.45,
                height: 800 * 0.07,
            },
        },
        watermark: {
            fontSize: 70,
            rotation: "-70deg",
            translateX: -550,
            translateY: 175,
        },
    },
}

module.exports = {webSizes, sizes};