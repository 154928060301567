import React, {useState, useEffect, useContext} from 'react';
import {Link, Navigate} from 'react-router-dom';

import '../pages/contact/contact.css';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { ToastContainer, toast } from "react-toastify";
import {TOAST_CONF, TOAST_CONF_SHORT} from '../../assets/conf/conf.js';

import Context from '../../context/language/Context';
import dictionary from './lang/index.js';

import { WidgetInstance } from "friendly-challenge";
import Col from 'react-bootstrap/esm/Col.js';
import Row from 'react-bootstrap/esm/Row.js';
    
const Request = function (props) {
    /*if (!props.request || !props.request || !props.toggleShowReq) {
        return <Navigate to='/error_404'/>;
    }*/

    const {language} = useContext(Context);
    const text = dictionary[language];

    const [widget, setWidget] = useState(null);
    const [canSend, setCanSend] = useState(false);
    const [form, setForm] = useState({projectName: props.projectName, md: props.request.draw.md});
    const [notification, setNotification] = useState({text: '', show: false});
    const [requestID, setRequestID] = useState('');

    function handleChange (event) {
        const target = event.target;
        const key = target.name;

        form[key] = target.value;
        setForm(form);
    }

    function handleCopy () {
        navigator.clipboard.writeText(requestID);
        toast.success('¡Copiado!', TOAST_CONF_SHORT);
    }

    async function sendEmail(event) {
        const target = event.target;
        const inEmailAddress = document.getElementById('emailAddress');
        const inEmailBody = document.getElementById('emailBody');
        
        const isEmail = (email) =>
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(form.email);

        let send = canSend;

        target.disabled = true;

        // Check if mandatory data is missing
        if (!form.body) {
            inEmailBody.classList.add('danger');
            inEmailBody.focus();
            send = false;
        }
        if (!form.email || !isEmail(form.email)) {
            inEmailAddress.classList.add('danger');
            inEmailAddress.focus();
            send = false;
        }

        if (send) {
            const domForm = document.getElementById("form");
            
            try {
                await axios.post('/api/email/sendEmailFromMeWithAttachments', form).then(
                  (x) => {
                    if (x.data === 'ERROR') {
                        toast.error('¡Ha ocurrido un error!', TOAST_CONF);
                    } else {
                        toast.success('¡Email enviado!', TOAST_CONF);
                        setCanSend(false);
                        domForm.reset();
                        widget.reset();
                        setRequestID(x.data);
                    }
                  }
                );
                inEmailAddress.classList.remove('danger');
                inEmailBody.classList.remove('danger');
            } catch (e) {
                console.log(e.message);
            }
        } else {
            toggleNotification(text['req-error-general']);
        }
        target.disabled = false;
    }
    
    function toggleNotification(text) {
        const newState = {
            show: notification.show
        }

        if (text && typeof(text) === 'string') {
            newState.text = text;
            newState.show = !newState.show;
        } else {
            newState.text = '';
            newState.show = false;
        }
        setNotification(newState);
    }

    useEffect( () => {
         // This element should contain the `frc-captcha` class for correct styling
         const element = document.querySelector("#my-widget");
         const options = {
             doneCallback: (solution) => {
                setCanSend(true);
            },
             errorCallback: (solution) => {
                setCanSend(false);
                toggleNotification(text['req-captcha-error']);
            },
             sitekey: "FCMS9EG3SF5809VL",
             startMode: "focus",
         }
         const widget = new WidgetInstance(element, options);
         
         setWidget(widget);
    }, []);

    return (
        <section>
            <div className="div-back" onClick={props.toggleShowReq}>
                <b><i className="bi bi-chevron-left"></i></b>{text['req-suc-back']}
            </div>
            <h2>{text['req-suc-saved']}</h2>
            <p>
                {text['req-suc-ins-1']} {text['req-suc-ins-2']}
            </p>
            {requestID
            ?   <div>
                    <h6>
                        {text['req-suc-p1']}
                    </h6>
                    <h6>
                        {text['req-suc-p2']}
                    </h6>
                    <ol>
                        <li><Link target="_blank" to={`https://maurogrizia.mitiendanube.com${props.searchQuery? `/search/?q=${props.searchQuery}` : ''}`}>{text['req-suc-li-1']}</Link></li>
                        <li>{text['req-suc-li-2']}</li>
                        <li>{text['req-suc-li-3']}</li>
                    </ol>
                    <Row>
                        <Col key={`col-1`} xs={0} sm={0} md={1} lg={2}></Col>
                        <Col key={`col-2`} xs={12} sm={12} md={10} lg={8}>
                            <div className="div-copy" onClick={handleCopy}>
                                <i className="bi bi-clipboard"></i>
                                <span id="requestID" className="text-center">
                                    {requestID}
                                </span>
                            </div>
                        </Col>
                        <Col key={`col-3`} xs={0} sm={0} md={1} lg={2}>
                        </Col>
                    </Row>
                </div>
            :   <Form id="form">
                    <Form.Group className="mb-3" controlId="emailAddress">
                        <Form.Label>Email</Form.Label>
                        <Form.Control className="mandatory" type="email" name="email" onChange={handleChange} placeholder={text['req-form1-ph']} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="emailBody">
                        <Form.Label>{text['req-lbl-ta']}</Form.Label>
                        <Form.Control className="mandatory" as="textarea" name="body" onChange={handleChange} placeholder={text['req-ph-default']} rows="5" />
                    </Form.Group>
                    <div>
                        <Button className="mb-3" disabled={canSend? false : true} id="email-submit" onClick={sendEmail}>
                            {text['req-but']}
                        </Button>
                        <div id="my-widget" className="frc-captcha" data-lang="es" style={{float: 'right'}}></div>
                    </div>
                </Form>
            }
            <ToastContainer />
        </section>
    );
}

export default Request;