import React, { useContext } from 'react';
import languages from './languages';
import Context from './Context';

import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

//import './styles.css';

const Selector = () => {
  const { language, changeLanguage } = useContext(Context);
  const handleLanguageChange = (language) => {
      changeLanguage(language);
  };

  return (
      <ButtonGroup className="bg-language" vertical = {false}>
      {Object.values(languages).map((lang, idx) => (
        <ToggleButton
          className="tb-language"
          key = {`radio-${idx}`}
          type = "radio"
          name = "radio"
          size = "sm"
          value = {lang.name}
          checked = {language === lang.name}
          onClick = {(e) => {handleLanguageChange(lang.name)}}
        >
          {lang.emoji}
        </ToggleButton>
      ))}
    </ButtonGroup>
  );
};

export default Selector;