import React from 'react';

const Emoji = function (props) {
    let symbol = '';

    if (props.symbol) {
        symbol += (String.fromCodePoint(props.symbol));
    } else {
        if (props.symbols) {
            props.symbols.forEach((e) => {
                symbol += (String.fromCodePoint(e));
            });
        }
    }

    // role and aria-label help with accesibility
    return (
        props.makeSpan && props.label
            ?   (<span 
                    className="emoji" 
                    role="img"
                    aria-label={props.label ? props.label : ""}
                    aria-hidden={props.label ? "false" : "true"}>
                    {symbol}
                </span>)
            :   symbol
    );}
    
export default Emoji;

// Outside of svgs I can insert emojis like this:
// <Emoji symbols={[0x1F1E9, 0x1F1EA]} label="germany"/>