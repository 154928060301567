
const conf = {
    TOAST_CONF: {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
    },
    COOKIE_CONF: {
        path: '/',
        sameSite: 'Strict',
        secure: true,
        credentials: 'include',
        withCredentials: true
    },
    TOAST_CONF_SHORT: {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
    },
};

module.exports = conf;