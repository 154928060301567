import React, {useContext} from 'react';

import {Link} from 'react-router-dom';

import Selector from '../context/language/Selector.jsx';
import Context from '../context/language/Context.jsx';
import dictionary from './lang/index.js';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import Dropdown from 'react-bootstrap/Dropdown';
import Emoji from './other/Emoji.js';

const Header = function () {
    const {language} = useContext(Context);
    const text = dictionary[language];
    
    return (
      <Navbar className="navbar-mau" expand="md">
        <Container>
          <Link className="navbar-brand`active" to={`/${language}`} aria-current="page">
            <img src="/assets/img/triangle.png" className="d-inline-block align-top logo" alt={text['alt-MG-logo']}/>
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Link className="nav-link nav-link-mau" to={`/${language}/blog`}>{text['blog']}</Link>
              <Link className="nav-link nav-link-mau" to={`/${language}/projects`}>{text['projects']}</Link>
              <Link className="nav-link nav-link-mau" to={`/${language}/gallery`}>{text['gallery']}</Link>
              <Link className="nav-link nav-link-mau" to={`/${language}/contact`}>{text['contact']}</Link>
              <Dropdown className="container-donations">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <Emoji symbols={["0x1F497"]}/>
                  {text['donations']}
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-r0">
                  <Dropdown.Item className="a-donation" href='https://cafecito.app/maurogrizia' rel='noopener' target='_blank'>
                    <img className="img-donation" srcSet='https://cdn.cafecito.app/imgs/buttons/button_6.png 1x, https://cdn.cafecito.app/imgs/buttons/button_6_2x.png 2x, https://cdn.cafecito.app/imgs/buttons/button_6_3.75x.png 3.75x' src='https://cdn.cafecito.app/imgs/buttons/button_6.png' alt={text['alt-cafecito']} />
                  </Dropdown.Item>
                  <Dropdown.Item className="a-donation" href='https://ko-fi.com/N4N3O2G9M' rel="noopener noreferrer" target='_blank'>
                    <img className="img-donation" src='https://storage.ko-fi.com/cdn/kofi1.png?v=3' border='0' alt={text['alt-kofi']} />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
          
          <Selector/>
        </Container>
      </Navbar>
    );
}

export default Header;