const languages = {
    ES: {
        name: "ES",
        description: "Español",
        keywords: ['español', 'spanish', 'spanisch'],
        codepoints: ["0x1F1E6", "0x1F1F7"],
        emoji: "🇦🇷",
    },
    EN: {
        name: "EN",
        description: "English",
        keywords: ['english', 'ingles', 'englisch'],
        codepoints: ["0x1F1FA", "0x1F1F8"],
        emoji: "🇺🇸",
    },
    DE: {
        name: "DE",
        description: "Deutsch",
        keywords: ['deutsch', 'german', 'aleman'],
        codepoints: ["0x1F1E9", "0x1F1EA"],
        emoji: "🇩🇪",
    },
    /*POR: {
        name: "POR",
        description: "Português",
        keywords: ['portugues', 'portuguese', 'portugiesisch', 'Português'],
        codepoints: ["0x1F1E7", "0x1F1F7"],
        emoji: "🇧🇷",
    },*/
    
};

export default languages;