import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

import LanguageProvider from '../context/language/Provider';
import EntriesProvider from '../context/entries/Provider';

import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import Entry from './blog/Entry';
import Blog from './blog/Blog';
import Gallery from './gallery/Gallery';
import ArtProject from './gallery/ArtProject';
//import HypnoticSquares from './gallery/projects/HypnoticSquares';
//import CaptivatingCircles from './gallery/projects/CaptivatingCircles';
//import Chatbot from './chatbot/Chatbot';
import Header from './Header';
import DBHandler from './admin/DBHandler';
import BlogHandler from './admin/BlogHandler';
import EmailHandler from './admin/EmailHandler';
import UploadPhrase from './imageCreator/UploadPhrase';
import Projects from './projects/Projects';
import Daw from './projects/projects/Daw.jsx';
import MapOfJazz from './projects/projects/MapOfJazz';
import SayItWithColors from './projects/projects/SayItWithColors';
import Twitter from './projects/projects/Twitter';
import Error404 from './pages/error/Error404';
import AdminPage from './admin/AdminPage';
import Login from './admin/Login';
import Signup from './admin/Signup';
import Request from './gallery/Request.jsx';

const App = function () {
    // <Route exact path="/blog/:id" element={<Entry/>}/>
                    //<Route exact path="/gallery/CaptivatingCircles" element={<CaptivatingCircles/>}/>
                    //<Route exact path="/gallery/HypnoticSquares" element={<HypnoticSquares/>}/>
    return (
            <BrowserRouter>
                <LanguageProvider>
                    <Header/>
                    <EntriesProvider>
                        <Routes>
                            <Route exact path="/" element={<About/>} />
                            <Route exact path="/:lang/" element={<About/>} />
                            <Route exact path="/:lang/about" element={<About/>} />
                            <Route exact path="/:lang/contact" element={<Contact/>}/>
                            
                            <Route exact path="/:lang/admin" element={<AdminPage/>}/>
                            <Route exact path="/:lang/admin/login" element={<Login/>}/>
                            <Route exact path="/:lang/admin/signup" element={<Signup/>}/>
                            <Route exact path="/:lang/admin/db" element={<DBHandler/>}/>
                            <Route exact path="/:lang/admin/blog" element={<BlogHandler/>}/>
                            <Route exact path="/:lang/admin/email" element={<EmailHandler/>}/>

                            <Route exact path="/:lang/blog" element={<Blog/>}/>
                            <Route exact path="/:lang/blog/:year/:month/:folder" element={<Entry/>}/>

                            <Route exact path="/:lang/gallery" element={<Gallery/>}/>
                            <Route exact path="/:lang/gallery/:projectName" element={<ArtProject/>}/>
                            <Route exact path="/:lang/gallery/:projectName/request" element={<Request/>}/>
                            
                            <Route exact path="/:lang/projects/" element={<Projects/>}/>
                            <Route exact path="/:lang/projects/daw" element={<Daw/>}/>
                            <Route exact path="/:lang/projects/daw/upload" element={<UploadPhrase/>}/>
                            <Route exact path="/:lang/projects/mapofjazz" element={<MapOfJazz/>}/>
                            <Route exact path="/:lang/projects/sayitwithcolors" element={<SayItWithColors/>}/>
                            <Route exact path="/:lang/projects/twitter" element={<Twitter/>}/>

                            <Route exact path="*" element={<Error404/>}/>
                        </Routes>
                    </EntriesProvider>
                </LanguageProvider>
            </BrowserRouter>
    );
};

export default App;