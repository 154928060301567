const colectionPreview = {
    CaptivatingCircles: {
        date: '15 Jan 2024',
        title: {
            ES: "Círculos cautivadores",
            EN: "Captivating circles",
            DE: "Faszinierende Kreise",
        },
        description: {
            ES: "Descubre el encanto hipnótico de los círculos con este fascinante diseño. Una composición cautivadora, cada uno meticulosamente dispuesto, que danzan a través del diseño, cautivando la mente y creando una sensación de serena tranquilidad.",
            EN: "Discover the hypnotic charm of circles with this fascinating design. A captivating composition, each meticulously arranged, that dance across the design, captivating the mind and creating a feeling of serene tranquility.",
            DE: "Entdecken Sie den hypnotischen Charme von Kreisen mit diesem faszinierenden Design. Eine fesselnde Komposition, jede sorgfältig arrangiert, die über das Design tanzt, den Geist fesselt und ein Gefühl heiterer Ruhe erzeugt.",
        },
        alt: {
            ES: "Círculos cautivadores",
            EN: "Captivating circles",
            DE: "Faszinierende Kreise",
        },
        img: '../assets/img/gallery/concentric-circles.webp',
        link: 'CaptivatingCircles',
    },
    ChaoticCircles: {
        date: '08 Mar 2024',
        title: {
            ES: "Círculos caóticos",
            EN: "Chaotic circles",
            DE: "Chaotische Kreise",
        },
        description: {
            ES: "Descubre la esencia cautivadora de este diseño dinámico, que presenta una danza lúdica de círculos concéntricos. Bañado en un espectro de colores claros y aireados, este fondo de pantalla de arte generativo irradia energía positiva para alegrar tu día.",
            EN: "Discover the captivating essence of this dynamic design, which features a playful dance of concentric circles. Bathed in a spectrum of light and airy colors, this generative art wallpaper radiates positive energy to brighten your day.",
            DE: "Entdecken Sie die fesselnde Essenz dieses dynamischen Designs, das einen verspielten Tanz aus konzentrischen Kreisen aufweist. Diese generative Kunsttapete ist in ein Spektrum heller und luftiger Farben getaucht und strahlt positive Energie aus, um Ihren Tag zu erhellen.",
        },
        alt: {
            ES: "Círculos caóticos",
            EN: "Chaotic circles",
            DE: "Chaotiche Kreise",
        },
        img: '../assets/img/gallery/chaotic-circles.webp',
        link: 'ChaoticCircles',
    },
    Nexus: {
        date: '22 Apr 2024',
        title: {
            ES: "Nexos",
            EN: "Nexus",
            DE: "Nexus",
        },
        description: {
            ES: "Divertidas conexiones entre puntos invisibles que estallan con tonos vibrantes, creando una exhibición cautivadora, simple y sorprendente.",
            EN: "Playful connections between unseen points burst with vibrant hues, creating a captivating display that's both simple and striking.",
            DE: "Spielerische Verbindungen zwischen unsichtbaren Punkten strotzen vor lebendigen Farbtönen und schaffen eine fesselnde Darstellung, die sowohl schlicht als auch eindrucksvoll ist.",
        },
        alt: {
            ES: "Múltiples líneas de colores entreconectándose",
            EN: "Multiple colored lines connecting",
            DE: "Mehrere farbige Linien verbinden",
        },
        img: '../assets/img/gallery/nexus.webp',
        link: 'Nexus',
    },
    HypnoticSquares: {
        date: '30 Jan 2024',
        title: {
            ES: "Cuadrados hipnóticos",
            EN: "Hypnotic Squares",
            DE: "Hypnotische Quadrate",
        },
        description: {
            ES: "Inspirada en \"Estructuras de cuadrados\" de Vera Molnár, ¡esta obra de arte trata sobre patrones geométricos y coloridos!",
            EN: "Inspired by Vera Molnár's \"Structures of Squares\", this artwork is all about colorful, geometric patterns!",
            DE: "Inspiriert von Vera Molnárs \"Structures of Squares\" dreht sich bei diesem Kunstwerk alles um farbenfrohe, geometrische Muster!",
        },
        alt: {
            ES: "Cuadrados hipnóticos",
            EN: "Hypnotic Squares",
            DE: "Hypnotische Quadrate",
        },
        img: '../assets/img/gallery/hypnotic-squares.webp',
        link: 'HypnoticSquares',
    },
    JoyDivision: {
        date: '08 Mar 2024',
        title: {
            ES: 'Joy Division',
            EN: 'Joy Division',
            DE: 'Joy Division',
        },
        description: {
            ES: "Abraza la esencia enigmática de \"Unknown Pleasures\" de Joy Division con este cautivador diseño de arte generativo inspirado en la icónica portada del álbum.",
            EN: "Embrace the enigmatic essence of Joy Division's \"Unknown Pleasures\" with this captivating generative art design inspired by the album's iconic cover.",
            DE: "Erleben Sie die rätselhafte Essenz von „Unknown Pleasures“ von Joy Division mit diesem faszinierenden generativen Kunstdesign, das vom ikonischen Cover des Albums inspiriert ist.",
        },
        alt: {
            ES: "Unknown Pleaures - Joy Division",
            EN: "Unknown Pleaures - Joy Division",
            DE: "Unknown Pleaures - Joy Division",
        },
        img: '../assets/img/gallery/joy-division.webp',
        link: 'JoyDivision',
    },
    /*ValentinCards: {
        date: '01 Feb 2024',
        title: {
            ES: "Carta de amor",
            EN: "Love letter",
            DE: "Liebesbrief",
        },
        description: {
            ES: "Carta de amor potenciada con IA. Expresá lo que verdaderamente sentís con la ayuda de tecnología de punta.",
            EN: "Love letter powered by AI. Express what you truly feel with the help of cutting-edge technology.",
            DE: "Liebesbrief mit KI. Drücken Sie mit Hilfe modernster Technologie aus, was Sie wirklich fühlen.",
        },
        alt: {
            ES: "Carta de amor",
            EN: "Love letter",
            DE: "Liebesbrief",
        },
        img: '../assets/img/gallery/hearts.webp',
        link: 'ValentinCards',
    }*/
};

export default colectionPreview;