import React, {useEffect, useContext} from 'react';

import Context from '../../../context/language/Context';
import dictionary from './lang/index.js';

import { setLazyLoadImages } from '../../../assets/js/imgUtils.js';

const Twitter = function () {
    const {language} = useContext(Context);
    const text = dictionary[language];

    function makeCarousels() {
        const carousels = document.querySelectorAll('mau-carousel');
        const visitedArray = Array(carousels.length);
        const fBrowseImg = (event) => {
            const button = event.target;
            const carousel = button.closest("mau-carousel");
            const figures = carousel.querySelectorAll('figure');
            const figureActive = carousel.querySelector("[data-active]");
            const indexActive = [...figures].indexOf(figureActive);
            const indexActiveNew = (figures.length + indexActive + (button.dataset.move === "next"? 1 : -1)) % figures.length;
            const browseCarouselEvent = new Event('mau-browseCarousel', {
                bubbles: false,
                cancelable: false,
            });

            
            figures[indexActiveNew].dataset.active = "true";
            delete figureActive.dataset.active;
            // Dispatch event to lazy-load img
            if (!visitedArray[indexActiveNew]) {
                window.dispatchEvent(browseCarouselEvent);
            }
            visitedArray[indexActiveNew] = true;
        };

        // Make carousels
        carousels.forEach((carousel) => {
            // Select active figure (first one found)
            let found = false;

            for (let i = 0; i < carousel.children.length && !found; i++) {
                if (carousel.children[i].tagName === 'FIGURE') {
                    carousel.children[i].dataset.active = "true";
                    visitedArray[i] = true;
                    found = true;
                }
            };

            // Add navigation buttons
            const butNext = document.createElement('i');
            const butPrev = document.createElement('i');

            butNext.className = 'carousel-button next bi bi-arrow-right-square-fill';
            butPrev.className = 'carousel-button prev bi bi-arrow-left-square-fill';

            butNext.dataset.move = "next";
            butPrev.dataset.move = "prev";

            butNext.addEventListener("click", fBrowseImg);
            butPrev.addEventListener("click", fBrowseImg);

            carousel.appendChild(butNext);
            carousel.appendChild(butPrev);
        });
    }

    useEffect(() => {
        makeCarousels();
        setLazyLoadImages();

        return () => {
            const carouselButs = document.querySelectorAll('.carousel-button');

            carouselButs.forEach((but) => {
                but.remove();
            });
        }
    }, []);

    return (
        <>
        <div className="content-container">
            <h1 className="title">{text['tweet-title']}</h1>
            <hr />

            <img className="lazy-load" data-src="https://firebasestorage.googleapis.com/v0/b/personal-website-gmail.appspot.com/o/pages%2Fprojects%2Ftwitter%2Fwordcloud-coverpage.webp?alt=media&token=36aaccd0-b6cf-4fed-88c6-b8d64e7bc1d1" alt="Wordcloud from Argentina's 2018 Tweet analysis" />
            
            <p>
                {text['tweet-p1']}
            </p>
            <p>
                {text['tweet-p2']}<a rel="noopener noreferrer" target="_blank" href="https://us.sagepub.com/en-us/nam">{text['tweet-p3']}</a>{text['tweet-p4']}<b>{text['tweet-p5']}</b>{text['tweet-p6']}<a rel="noopener noreferrer" target="_blank" href="https://doi.org/10.1177/01655515231160034">{text['tweet-p7']}</a>
                <br/>
                {text['tweet-p8']}<a rel="noopener noreferrer" target="_blank" href="https://drive.google.com/file/d/1iXMYOcmVmYbMdfwHbC3sAhVuD2q5XexR/view?usp=sharing">{text['tweet-p9']}</a>
            </p>
            
            <h2>{text['tweet-sub1']}</h2>
            <ol>
                <li><a href="#objectives" target="_self">{text['tweet-li1']}</a></li>
                <li><a href="#concepts" target="_self">{text['tweet-li2']}</a></li>
                <ol>
                    <li><a href="#nlp" target="_self">{text['tweet-li3']}</a></li>
                    <li><a href="#vsm" target="_self">{text['tweet-li4']}</a></li>
                </ol>
                <li><a href="#tecnologies" target="_self">{text['tweet-li5']}</a></li>
                <li><a href="#implementation" target="_self">{text['tweet-li6']}</a></li>
                <ol>
                    <li><a href="#adaptation" target="_self">{text['tweet-li7']}</a></li>
                    <li><a href="#analysis" target="_self">{text['tweet-li8']}</a></li>
                </ol>
                <li><a href="#results" target="_self">{text['tweet-li9']}</a></li>
            </ol>

            <h2><span id="objectives">{text['tweet-sub1']}</span></h2>
            <p>
                {text['tweet-p10']}
            </p>
            <p>
                {text['tweet-p11']}
            </p>
            <ul>
                <li>{text['tweet-li10']}</li>
                <li>{text['tweet-li11']}</li>
                <li>{text['tweet-li12']}</li>
                <li>{text['tweet-li13']}</li>
            </ul>

            <h2><span id="concepts">{text['tweet-sub3']}</span></h2>

            <h4><span id="nlp">{text['tweet-sub-sub1']}</span></h4>
            <p>
                {text['tweet-p12']}
            </p>
            <p>
                {text['tweet-p13']}
            </p>
            <p>
                {text['tweet-p14']}
            </p>
            <p>
                {text['tweet-p15']}
            </p>


            <h4><span id="vsm">{text['tweet-sub-sub2']}</span></h4>
            <p>
                {text['tweet-p16']}
            </p>
            <p>
                {text['tweet-p17']}
            </p>

            <h2><span id="tecnologies">{text['tweet-sub4']}</span></h2>
            <p>
                {text['tweet-p18']}
                <br/>
                {text['tweet-p19']}
            </p>
            <p>
                {text['tweet-p20']}
            </p>
            <p>
                {text['tweet-p21']}
                <br/>
                {text['tweet-p22']}
            </p>
            <ol>
                <li>{text['tweet-li14']}</li>
                <li>{text['tweet-li15']}</li>
                <li>{text['tweet-li16']}</li>
            </ol>
            <p>
                {text['tweet-p23']}
            </p>
            <ul>
                <li><b>CrowdFlower:</b> {text['tweet-li17']}</li>
                <li><b>ElectoralTweets:</b> {text['tweet-li18']}</li>
                <li><b>EmoInt:</b>  {text['tweet-li19']}</li>
                <li><b>TEC:</b> {text['tweet-li20']}</li>
            </ul>
            <p>
                {text['tweet-p24']}
                <br/>
                {text['tweet-p25']}
                <br/>
                {text['tweet-p26']}
                <br/>
                {text['tweet-p27']}
                <br/>
                {text['tweet-p28']}
            </p>
            <p>
                {text['tweet-p29']}
            </p>
            <ol>
                <li>{text['tweet-li21']}</li>
                <li>{text['tweet-li22']}</li>
            </ol>
            <p>
                <br/>
                {text['tweet-p30']}
            </p>

            <h2><span id="implementation">{text['tweet-sub5']}</span></h2>

            <h4><span id="adaptation">{text['tweet-sub-sub3']}</span></h4>
            <p>
                {text['tweet-p31']}
                <br/>
                {text['tweet-p32']}
                <br/>
                {text['tweet-p33']}
            </p>
            <ol start="0">
                <li>{text['tweet-li23']}</li>
                <li>{text['tweet-li24']}</li>
                <li>{text['tweet-li25']}</li>
                <li>{text['tweet-li26']}</li>
                <li>{text['tweet-li27']}</li>
                <li>{text['tweet-li28']}</li>
                <li>{text['tweet-li29']}</li>
                <li>{text['tweet-li30']}</li>
                <li>{text['tweet-li31']}</li>
            </ol>
            <p>
                {text['tweet-p34']}
            </p>

            <p>
                {text['tweet-p35']}
                <br/>
                {text['tweet-p36']}
                <br/>
                {text['tweet-p37']}
                <br/>
                {text['tweet-p38']}
                <br/>
                {text['tweet-p39']}
            </p>

            <h4><span id="queries">{text['tweet-sub6']}</span></h4>
            <p>
                {text['tweet-p40']}
                <br/>
                {text['tweet-p41']}
                <br/>
                {text['tweet-p42']}
                <br/>
                {text['tweet-p43']}
            </p>

            <ul>
                <li>{text['tweet-li33']}</li>
                <li>{text['tweet-li34']}</li>
                <li>{text['tweet-li35']}</li>
            </ul>

            <p>
                {text['tweet-p44']}
            </p>
            <p>
                <i>{text['tweet-i1']}</i>
            </p>
            <p>
                {text['tweet-p45']}
            </p>
            <p>
                <i>{text['tweet-i2']}</i>
                <br/>
                <i>{text['tweet-i3']}</i>
                <br/>
                <i>{text['tweet-i4']}</i>
            </p>

            <p>
                {text['tweet-p46']}
            </p>

            <p>
                {text['tweet-p47']}
                <br/>
                {text['tweet-p48']}
                <br/>
                {text['tweet-p49']}
            </p>

            <p>
                {text['tweet-p50']}
            </p>
            <ol>
                <li>{text['tweet-i5']}</li>
                <li>{text['tweet-i6']}</li>
                <li>{text['tweet-i7']}</li>
                <li>{text['tweet-i8']}</li>
                <li>{text['tweet-i9']}</li>
                <li>{text['tweet-i10']}</li>
            </ol>

            <p>
                {text['tweet-p51']}
                <br/>
                {text['tweet-p52']}
                <br/>
                {text['tweet-p53']}
            </p>

            <h4><span id="analysis">{text['tweet-sub7']}</span></h4>
            <p>
                {text['tweet-p54']}
                <br/>
                {text['tweet-p55']}<a href="#carousel-1">{text['tweet-sp1']}</a>.
            </p>

            <a id="carousel-1" role="button" tabIndex="0">
                <mau-carousel>
                    <figure>
                        <img className="lazy-load" data-src="https://firebasestorage.googleapis.com/v0/b/personal-website-gmail.appspot.com/o/pages%2Fprojects%2Ftwitter%2Fconfusion_matrix-plutchick-DS0-en-5165.webp?alt=media&token=858032cd-a201-4476-b56d-a46ef1362cac" alt="Inglés - Modelo Plutchick (Precisión: 39.01 %)" />
                        <figcaption>{text['tweet-fca']}</figcaption>
                    </figure>
                    <figure>
                        <img className="lazy-load" data-src="https://firebasestorage.googleapis.com/v0/b/personal-website-gmail.appspot.com/o/pages%2Fprojects%2Ftwitter%2Fconfusion_matrix-plutchick-DS0-es-5196.webp?alt=media&token=f772117d-0f71-4928-8ce4-6be4c9146243" alt="Español - Modelo Plutchick (Precisión: 36.96 %)" />
                        <figcaption>{text['tweet-fcb']}</figcaption>
                    </figure>
                    <figure>
                        <img className="lazy-load" data-src="https://firebasestorage.googleapis.com/v0/b/personal-website-gmail.appspot.com/o/pages%2Fprojects%2Ftwitter%2Fconfusion_matrix-plutchick-DS0-pt-5215.webp?alt=media&token=9be57755-4d6f-4898-bc83-4fca8bc155d8" alt="Portugués - Modelo Plutchick (Precisión: 35.77 %)" />
                        <figcaption>{text['tweet-fcc']}</figcaption>
                    </figure>
                </mau-carousel>
            </a>

            <h2><a id="results">{text['tweet-sub8']}</a></h2>

            <p>
                {text['tweet-p56']}
                <br/>
                {text['tweet-p57']}
                <br/>
                {text['tweet-p58']}<a href="#carousel-2">2</a>{text['tweet-and']}<a href="#carousel-3">3</a>{text['tweet-sp2']}
            </p>
            <p>
                {text['tweet-p59']}<a href="https://www.infobae.com/politica/2021/10/01/la-imagen-de-alberto-fernandez-cayo-a-un-piso-historico-especialmente-entre-millennials-y-centennials/">{text['tweet-sp4']}</a>
            </p>
            
            <a id="carousel-2" role="button" tabIndex="0">
                <mau-carousel>
                    <figure>
                        <img className="lazy-load" data-src="https://firebasestorage.googleapis.com/v0/b/personal-website-gmail.appspot.com/o/pages%2Fprojects%2Ftwitter%2Fargentina%202020%20-%20Tema%201-%20graphic_emotions.webp?alt=media&token=9132bd12-68b9-48e2-a563-d559a10f7113" alt="Argentina 2018 - Temática: Medio ambiente y salud" />
                        <figcaption>{text['tweet-fc1']}</figcaption>
                    </figure>
                    <figure>
                        <img className="lazy-load" data-src="https://firebasestorage.googleapis.com/v0/b/personal-website-gmail.appspot.com/o/pages%2Fprojects%2Ftwitter%2Fargentina%202020%20-%20Tema%201-%20graphic_emotions.webp?alt=media&token=9132bd12-68b9-48e2-a563-d559a10f7113" alt="Argentina 2020 - Temática: Medio ambiente y salud" />
                        <figcaption>{text['tweet-fc2']}</figcaption>
                    </figure>
                    <figure>
                        <img className="lazy-load" data-src="https://firebasestorage.googleapis.com/v0/b/personal-website-gmail.appspot.com/o/pages%2Fprojects%2Ftwitter%2Fargentina%202021%20-%20Tema%201-%20graphic_emotions.webp?alt=media&token=a6292394-511b-4c31-968e-d9069369914b" alt="Argentina 2021 - Temática: Medio ambiente y salud" />
                        <figcaption>{text['tweet-fc3']}</figcaption>
                    </figure>
                </mau-carousel>
            </a>
            <a id="carousel-3" role="button" tabIndex="0">
                <mau-carousel>
                    <figure>
                        <img className="lazy-load" data-src="https://firebasestorage.googleapis.com/v0/b/personal-website-gmail.appspot.com/o/pages%2Fprojects%2Ftwitter%2Fusa%202018%20-%20Tema%201-%20graphic_emotions.webp?alt=media&token=a6f3cf6b-6382-48c7-8c9f-447f2420d7e4" alt="EEUU 2018 - Temática: Medio ambiente y salud" />
                        <figcaption>{text['tweet-fc4']}</figcaption>
                    </figure>
                    <figure>
                        <img className="lazy-load" data-src="https://firebasestorage.googleapis.com/v0/b/personal-website-gmail.appspot.com/o/pages%2Fprojects%2Ftwitter%2Fusa%202020%20-%20Tema%201-%20graphic_emotions.webp?alt=media&token=8e886b29-7e8d-4185-aac4-de1216721e96" alt="EEUU 2020 - Temática: Medio ambiente y salud" />
                        <figcaption>{text['tweet-fc5']}</figcaption>
                    </figure>
                    <figure>
                        <img className="lazy-load" data-src="https://firebasestorage.googleapis.com/v0/b/personal-website-gmail.appspot.com/o/pages%2Fprojects%2Ftwitter%2Fusa%202021%20-%20Tema%201-%20graphic_emotions.webp?alt=media&token=54628ec5-e084-472c-908e-f34958795fed" alt="EEUU 2021 - Temática: Medio ambiente y salud" />
                        <figcaption>{text['tweet-fc6']}</figcaption>
                    </figure>
                </mau-carousel>
            </a>

            <p>
                {text['tweet-sp6']}<a href="#carousel-4">{text['tweet-sp7']}</a>{text['tweet-sp8']}
            </p>

            <a id="carousel-4" role="button" tabIndex="0">
                <mau-carousel>
                    <figure>
                        <img className="lazy-load" data-src="https://firebasestorage.googleapis.com/v0/b/personal-website-gmail.appspot.com/o/pages%2Fprojects%2Ftwitter%2Fwordcloud%20-%202018%20argentina%20Tema%202.webp?alt=media&token=7dd1a21c-c369-4cc3-a7b0-d51831dfedc3" alt="Wordcloud, Argentina 2018 - Temática: Sociedad" />
                        <figcaption>{text['tweet-fc7']}</figcaption>
                    </figure>
                    <figure>
                        <img className="lazy-load" data-src="https://firebasestorage.googleapis.com/v0/b/personal-website-gmail.appspot.com/o/pages%2Fprojects%2Ftwitter%2Fwordcloud%20-%202018%20brasil%20Tema%200.webp?alt=media&token=896f63aa-3e0a-4361-b40d-05820fced2fd" alt="Wordcloud, Brasil 2018 - Temática: Economía" />
                        <figcaption>{text['tweet-fc8']}</figcaption>
                    </figure>
                    <figure>
                        <img className="lazy-load" data-src="https://firebasestorage.googleapis.com/v0/b/personal-website-gmail.appspot.com/o/pages%2Fprojects%2Ftwitter%2Fwordcloud%20-%202018%20mexico%20Tema%202.webp?alt=media&token=6efed575-7ad3-4aa0-a78e-85eee80e04db" alt="Wordcloud, Méjico 2018 - Temática: Sociedad" />
                        <figcaption>{text['tweet-fc9']}</figcaption>
                    </figure>
                    <figure>
                        <img className="lazy-load" data-src="https://firebasestorage.googleapis.com/v0/b/personal-website-gmail.appspot.com/o/pages%2Fprojects%2Ftwitter%2Fwordcloud%20-%202020%20alemania%20Tema%202.webp?alt=media&token=72325fdd-2a76-4d51-8226-06a6200eda77" alt="Wordcloud, Alemania 2020 - Temática: Sociedad" />
                        <figcaption>{text['tweet-fc10']}</figcaption>
                    </figure>
                    <figure>
                        <img className="lazy-load" data-src="https://firebasestorage.googleapis.com/v0/b/personal-website-gmail.appspot.com/o/pages%2Fprojects%2Ftwitter%2Fwordcloud%20-%202020%20canada%20Tema%200.webp?alt=media&token=a4eab186-a4ba-482f-be6b-057486836f44" alt="Wordcloud, Canadá 2018 - Temática: Economía" />
                        <figcaption>{text['tweet-fc11']}</figcaption>
                    </figure>
                    <figure>
                        <img className="lazy-load" data-src="https://firebasestorage.googleapis.com/v0/b/personal-website-gmail.appspot.com/o/pages%2Fprojects%2Ftwitter%2Fwordcloud%20-%202020%20turquia%20Tema%203.webp?alt=media&token=26d86634-1c10-41db-9ca0-2b422760b667" alt="Wordcloud, Turquía 2018 - Temática: Gobierno" />
                        <figcaption>{text['tweet-fc12']}</figcaption>
                    </figure>
                </mau-carousel>
            </a>            

            <br/>
            {text['tweet-p60']}<a rel="noopener noreferrer" target="_blank" href="https://github.com/maramau/Analisis-de-sentimientos-G20">{text['tweet-sp9']}</a>
        </div>
        </>
       
    );
}

export default Twitter;