import React, {useState, useContext} from "react";

import Context from '../../../context/language/Context';
import dictionary from './lang/index.js';

import {Link} from 'react-router-dom';

import SVGCreator from '../../imageCreator/SVGCreator';
import CanvasCreator from '../../imageCreator/CanvasCreator';

import Form from 'react-bootstrap/Form';

const Daw = function () {
    const {language} = useContext(Context);
    const text = dictionary[language];
    const [creatorMode, setCreatorMode] = useState('canvas');

    const handleSwitch = (e) => {
        const target = e.target;
        const mode = target.checked? 'canvas' : 'svg';

        setCreatorMode(mode);
    }
    //
    return (
        <>
        <div className="content-container">
            <h1 className="title">{text['daw-title']}</h1>
            <hr />
            <p>
                {text['daw-p1']}
                <br/>
                {text['daw-p2']}
                <br/>
                {text['daw-p3']}
                <br/>
                {text['daw-p4']}
                <br/>
                {text['daw-p5']}<Link to={`/${language}/projects/daw/upload`}>{text['daw-p6']}</Link>
            </p>

            <Form.Check
                type="switch"
                label={text[`daw-mode-${creatorMode}`]}
                onChange={handleSwitch}
                checked={creatorMode === 'canvas'}
            />
            {
                creatorMode === 'canvas'
                ? <CanvasCreator/>
                : <SVGCreator/>
            }            

        </div>
        </>
    );
}

export default Daw;