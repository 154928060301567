import axios from 'axios';

import { getImg } from '../conf/blog';

const BASE_IMG_URL = 'https://firebasestorage.googleapis.com/';

const setLazyLoadImages = (data) => {
  const cc = document.querySelectorAll('.content-container')[0];
  
  let lazyloadImages = document.querySelectorAll("img.lazy-load");
  let lazyloadThrottleTimeout;
  
  function lazyload() {
    if (lazyloadThrottleTimeout) {
      clearTimeout(lazyloadThrottleTimeout);
    }
    
    lazyloadThrottleTimeout = setTimeout(() => {
        lazyloadImages.forEach(async (img) => {
          const isVisible = img.offsetParent !== null;
          const imgTop = img.getBoundingClientRect().top;
          const scrollTop = window.pageYOffset;
          const ccTop = cc.getBoundingClientRect().top;
          const windowHeight = window.innerHeight;

          if (isVisible && img.dataset.src && imgTop < (scrollTop + ccTop) + windowHeight) {
            // When parsing the markdown, only the file's name is included, not the full address
            if (!img.dataset.src.startsWith(BASE_IMG_URL)) {
              const response = await axios.get(`/api/blog/getImgURL/${data.year}/${data.month}/${data.folder}/${img.dataset.src}`);
              
              img.src = response.data;
              img.classList.remove('lazy-load');
              lazyloadImages = document.querySelectorAll("img.lazy-load");
            } else {
              img.src = img.dataset.src;
            }
          }
        });
        if (lazyloadImages.length == 0) { 
          document.removeEventListener("scroll", lazyload);
          window.removeEventListener("resize", lazyload);
          window.removeEventListener("orientationChange", lazyload);
          window.removeEventListener("mau-browseCarousel", lazyload);
        }
    }, 20);
  }
  
  document.addEventListener("scroll", lazyload);
  window.addEventListener("resize", lazyload);
  window.addEventListener("orientationChange", lazyload);
  window.addEventListener("mau-browseCarousel", lazyload);
};

export {setLazyLoadImages};