import React, { Component } from 'react';
import axios from 'axios';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ToggleButton from 'react-bootstrap/ToggleButton'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import LanguageContext from '../../context/language/Context';
import dictionary from './lang/index.js';

import { ToastContainer, toast } from "react-toastify";
import {TOAST_CONF} from '../../assets/conf/conf.js'; 

import './imageCreator.css';

class UploadPhrase extends Component {
    static text = {};
    static contextType = LanguageContext;

    constructor(props) {
        super(props);
        this.state = {
            countries: [],
            flags: [],
            languages: [],
        };

        this.getBy = this.getBy.bind(this);
        this.getCountries = this.getCountries.bind(this);
        this.getFlags = this.getFlags.bind(this);
        this.getLanguages = this.getLanguages.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.select = this.select.bind(this);
        this.send = this.send.bind(this);
    }

    getBy(colection, key, value) {
        let toReturn = null;

        if (colection && key && value) {
            colection.forEach((elem) => {
                if (elem[key] && elem[key] === value) {
                    toReturn = elem;
                }
            });
        }

        return toReturn;
    }

    async getCountries() {
        try {
            let response = await axios.post('/api/getCountries', {});

            return response.data;
        } catch (e) {
            console.log(e.message);
        }
    }

    async getFlags() {
        try {
            const response = await axios.post('/api/getFlags', {});

            return response.data;
        } catch(e) {
            console.log(e.message);
        }
    };

    async getLanguages() {
        try {
            let response = await axios.post('/api/getLanguages', {});

            return response.data;
        } catch (e) {
            console.log(e.message);
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value.toLowerCase();
        const containerColection = document.querySelectorAll('#' + target.dataset.for + ' .btn-group label');

        containerColection.forEach((elem) => {
            elem.style.display = (!value || elem.dataset.keywords.includes(value))? '' : 'none';
        });
        
    }

    select(event) {
        const target = event.target;
        const newState = {
            checked: this.state.checked
        };

        let arrSelected = newState.checked[target.dataset.name];

        if (target.dataset.checked === "false") {
            arrSelected.push(target.dataset.id);
            target.dataset.checked = "true";
        } else {
            const toRemove = arrSelected.indexOf(target.dataset.id);

            if (toRemove !== -1) {
                arrSelected.splice(toRemove, 1);
            }
            newState.checked[target.dataset.name] = arrSelected;
            target.dataset.checked = "false";
        }
        this.setState(newState);
    }

    async send(event) {
        const inPhrase = document.getElementById('in-phrase');
        const inLiteral = document.getElementById('in-literal');
        const inMeaning = document.getElementById('in-meaning');
        const inExplanation = document.getElementById('in-explanation');
        const countriesUsed = document.querySelectorAll('#container-countries-used .btn-group [data-checked="true"]');
        const flags = document.querySelectorAll('#container-flags .btn-group [data-checked="true"]');
        const langFrom = this.getBy(this.state.languages, "name", 'ES');

        try {
            const form = {
                from: langFrom._id,
            };

            let shouldSend = true;

            // Check if mandatory data is missing
            if (inPhrase.value === '') {
                inPhrase.classList.add('danger');
                inPhrase.focus();
                shouldSend = false;
            } else {
                inPhrase.classList.remove('danger');
                form.phrase = inPhrase.value;
            }
            if (inMeaning.value === '') {
                inMeaning.classList.add('danger');
                inMeaning.focus();
                shouldSend = false;
            } else {
                inMeaning.classList.remove('danger');
                form.translations = {
                    meanings: {
                        ES : [{
                            text: inMeaning.value
                        }]
                    },
                };
            }

            if (shouldSend) {
                const flagIds = [];
                const usedIds = [];

                // optional inputs
                form.translations.literals = {
                    DE: [
                        {
                            text: inLiteral.value
                        }
                    ]
                };
                form.explanation = {
                    ES: inExplanation.value
                };

                // Checkboxes
                Array.from(flags).forEach((flag) => {
                    flagIds.push(flag.dataset.id);
                })
                form.flags = flagIds;

                Array.from(countriesUsed).forEach((country) => {
                    usedIds.push(country.dataset.id);
                });
                form.used = usedIds;
            
                await axios.post('/api/createTranslation', form);
                toast.success('¡Traducción guardada exitosamente!', TOAST_CONF);
                     
            }
        } catch(e) {
            toast.error(this.text['error-upload'], TOAST_CONF);
            console.log(e.message);
        } finally {
            const newStateChecked = this.state.checked;

            inPhrase.value = '';
            inLiteral.value = '';
            inMeaning.value = '';
            inExplanation.value = '';
            Array.from(countriesUsed).forEach((country) => {
                country.dataset.checked = "false";
            });
            if (newStateChecked) {
                Object.keys(newStateChecked).forEach((k) => {
                    newStateChecked[k] = [];
                });
                this.setState({checked: newStateChecked});
            }
        }
    }

    async componentDidMount() {
        const arrPromises = await Promise.all([this.getCountries(), this.getFlags(), this.getLanguages()]);

        this.setState({
            checked: {
                countries: [],
                flags: [],
            },
            countries: arrPromises[0],
            flags: arrPromises[1],
            languages: arrPromises[2],
        });
    }

    renderButtons(options) {
        const arrayButtons = [];
        const arrayCheckedIdxs = [];

        if (!options.colection) {
            return null;
        }

        options.colection.forEach((elem, idx) => {
            let checked = "false";
            
            if (this.state.checked[options.groupName].includes(elem._id)) {
                checked = "true";
                arrayCheckedIdxs.push(idx);
            }

            arrayButtons.push(
                <ToggleButton 
                    className="label-mau"
                    data-checked={checked}
                    data-keywords={elem.keywords.join('$')}
                    data-id={elem._id}
                    data-name={options.groupName}
                    id={"tb-" + options.groupName + "-" + idx} 
                    key={options.groupName + '-' + idx} 
                    name={options.groupName? options.groupName : ''} 
                    onClick={this.select} 
                    value={idx}
                    title={elem.description? elem.description : ''}>
                        {elem.emoji}
                </ToggleButton>
            );
        });
        
        return  (
                <ToggleButtonGroup 
                    aria-label={options.groupName}
                    name={options.groupName? options.groupName : ''} 
                    style={{display: 'inline'}}
                    type={options.groupType}
                    value={arrayCheckedIdxs}>
                    {arrayButtons}
                </ToggleButtonGroup>
                );
    }

    render () {
        this.text = dictionary[this.context.language];

        return  (
            <div className="content-container">
                <h1 className="title">{this.text['upload-title']}</h1>
                <hr />
                <Container>
                    <Row className="justify-content-md-center mt-2">
                        <Col xs={12} sm={12} md={4} lg={3}>
                            <InputGroup>
                                <Form.Label htmlFor="#in-phrase">{this.text['phrase']} <span className="danger small">{this.text['mandatory']}</span></Form.Label>
                            </InputGroup>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={9}>
                            <Form.Control
                                    aria-label="Frase"
                                    className="mandatory"
                                    id="in-phrase"
                                    maxLength={125}
                                    placeholder="No caza un fulbo"
                                    size={50}
                                    type="text"
                                    autoFocus
                                    required
                                />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center mt-2">
                        <Col xs={12} sm={12} md={4} lg={3}>
                            <InputGroup>
                                <Form.Label htmlFor="#in-meaning">{this.text['meaning']} <span className="danger small">{this.text['mandatory']}</span></Form.Label>
                            </InputGroup>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={9}>
                            <Form.Control
                                    aria-label="significado"
                                    className="mandatory"
                                    id="in-meaning"
                                    maxLength={125}
                                    placeholder="Él no entiende nada"
                                    size={50}
                                    type="text"
                                    autoFocus
                                    required
                                />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center mt-2">
                        <Col xs={12} sm={12} md={4} lg={3}>
                            <InputGroup>
                                <Form.Label htmlFor="#in-literal">{this.text['literal']}</Form.Label>
                            </InputGroup>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={9}>
                            <Form.Control
                                    aria-label="significado literal"
                                    className="mandatory"
                                    id="in-literal"
                                    maxLength={125}
                                    placeholder="Er jägt keinen Fussball"
                                    size={50}
                                    type="text"
                                    autoFocus
                                    required
                                />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center mt-2">
                        <Col xs={12} sm={12} md={4} lg={3}>
                            <InputGroup>
                                <Form.Label htmlFor="#in-explanation">{this.text['explanation']}</Form.Label>
                            </InputGroup>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={9}>
                            <Form.Control
                                    aria-label="explicación"
                                    as="textarea"
                                    className="mandatory"
                                    cols={50}
                                    id="in-explanation"
                                    maxLength={250}
                                    placeholder="Usado cuando la persona referenciada no entiende algo. La palabra “fulbo” es una deformación de “fútbol”, remite por metonimia a la pelota."
                                    rows={4}
                                    size={200}
                                    type="text"
                                    autoFocus
                                    required
                                />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center mt-2">
                        <Col xs={12} sm={12} md={4} lg={3}>
                            <InputGroup>
                                <Form.Label htmlFor="#container-countries-used">{this.text['country']}</Form.Label>
                            </InputGroup>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={9}>
                            <div className="container-options-group p-2">
                                <div>
                                    <input className="input-search-options m-1 p-1" data-for="container-countries-used" placeholder="ARG" onChange={this.handleInputChange}/>
                                </div>
                                <span className="container-options" id="container-countries-used">
                                    {this.renderButtons({colection: this.state.countries, groupName: 'countries', groupType: 'checkbox'})}
                                </span>
                            </div>
                            
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center mt-2">
                        <Col xs={12} sm={12} md={4} lg={3}>
                            <InputGroup>
                                <Form.Label htmlFor="#container-flags">{this.text['flags']}</Form.Label>
                            </InputGroup>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={9}>
                            <div className="container-options-group p-2">
                                <div>
                                    <input className="input-search-options m-1 p-1" data-for="container-flags" placeholder="MIL" onChange={this.handleInputChange}/>
                                </div>
                                <span className="container-options" id="container-flags">
                                    {this.renderButtons({colection: this.state.flags, groupName: 'flags', groupType: 'checkbox'})}
                                </span>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Button onClick={this.send}>{this.text['send']}</Button>
                <ToastContainer />
                <p></p>
            </div>
        );
    }
}

export default UploadPhrase;