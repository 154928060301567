import React,{ useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from 'axios';

import Button from 'react-bootstrap/Button';

const EmailHandler = () => {
    const COOKIE_OPTIONS = {path: '/', sameSite: 'Strict', secure: false};
    const TIMEOUT = 5000;
    const navigate = useNavigate();
    const [cookies, removeCookie] = useCookies(['token']);

    const init = async () => {
        try {
            await axios.post(
                '/api/email/init',
                {},
                {timeout: TIMEOUT}
            );

            alert('Done!');
        } catch (e) {
            console.log(e.message);
        }
    }
    
    useEffect(() => {
        // Cancel request in case of demounting or timeout
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        let status = false;

        const verifyCookie = async () => {
            try {
                if (!cookies.token || cookies.token === 'undefined') {
                    navigate(`/ES/admin/login`);
                } else {
                    const { data } = await axios.post(
                        "/api/admin/verifyUser",
                        {},
                        {   cancelToken: source.token,
                            withCredentials: true,
                            credentials: 'include',
                            timeout: TIMEOUT}
                    );
                    status = data.status;
                }
                if (!status) {
                    removeCookie("token", COOKIE_OPTIONS);
                    navigate(`/ES/admin/login`);
                }
            } catch (err) {
                if (axios.isCancel(err)) {
                    console.log('successfully aborted');
                } else {
                    console.log(err);
                }
            };
        };

        verifyCookie();

        return () => {
            // cancel the request before component unmounts
            source.cancel();
        };
    }, []);
    
    return (
        <div className="content-container">
            <Button onClick={init} style={{textAlign: 'center'}}>
                GENERATE GAPI TOKEN
            </Button>
        </div>
    );
}

export default EmailHandler;