import React, {useState, useEffect, useContext} from 'react';
import './contact.css';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { ToastContainer, toast } from "react-toastify";
import {TOAST_CONF} from '../../../assets/conf/conf.js';

import Context from '../../../context/language/Context';
import dictionary from './lang/index.js';

import { WidgetInstance } from "friendly-challenge";
    
const Contact = function () {
    const {language} = useContext(Context);
    const text = dictionary[language];

    const [widget, setWidget] = useState(null);
    const [canSend, setCanSend] = useState(false);
    const [form, setForm] = useState({reason: 'other'});
    const [notification, setNotification] = useState({text: '', show: false});

    function handleChange (event) {
        const target = event.target;
        const key = target.name;

        form[key] = target.value;
        setForm(form);
    }

    async function sendEmail(event) {
        const target = event.target;
        const inEmailAddress = document.getElementById('emailAddress');
        const inEmailBody = document.getElementById('emailBody');
        
        const isEmail = (email) =>
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(form.email);

        let send = canSend;

        target.disabled = true;

        // Check if mandatory data is missing
        if (!form.body) {
            inEmailBody.classList.add('danger');
            inEmailBody.focus();
            send = false;
        }
        if (!form.email || !isEmail(form.email)) {
            inEmailAddress.classList.add('danger');
            inEmailAddress.focus();
            send = false;
        }

        if (send) {
            const domForm = document.getElementById("form");
            
            try {
                await axios.post('/api/email/sendEmailFromMe', form).then(
                  (x) => {
                    if (x.data === 'ERROR') {
                        toast.error('¡Ha ocurrido un error!', TOAST_CONF);
                    } else {
                        toast.success('¡Email enviado!', TOAST_CONF);
                        setCanSend(false);
                        domForm.reset();
                        widget.reset();
                    }
                  }
                );
                inEmailAddress.classList.remove('danger');
                inEmailBody.classList.remove('danger');
            } catch (e) {
                console.log(e.message);
            }
        } else {
            toggleNotification(text['error-general']);
        }
        target.disabled = false;
    }
    
    function toggleNotification(text) {
        const newState = {
            show: notification.show
        }

        if (text && typeof(text) === 'string') {
            newState.text = text;
            newState.show = !newState.show;
        } else {
            newState.text = '';
            newState.show = false;
        }
        setNotification(newState);
    }

    useEffect( () => {
         // This element should contain the `frc-captcha` class for correct styling
         const element = document.querySelector("#my-widget");
         const options = {
             doneCallback: (solution) => {
                setCanSend(true);
            },
             errorCallback: (solution) => {
                setCanSend(false);
                toggleNotification(text['captcha-error']);
            },
             sitekey: "FCMS9EG3SF5809VL",
             startMode: "focus",
         }
         const widget = new WidgetInstance(element, options);
         
         setWidget(widget);
    }, []);
    return (
        <div id='container-contact' className='content-container'>
            <h1 className="title">{text['title']}</h1>
            <hr />
            <h4>{text['suggestion1']}</h4>
            <div className="social_container">
                <a href="https://www.linkedin.com/in/mauro-grizia-83a34aa2/" rel="noopener noreferrer" target="_blank" title="LinkedIn">
                    <i className="bi bi-linkedin"></i>
                </a>
                <a href="https://www.instagram.com/maurogrizia/" rel="noopener noreferrer" target="_blank" title="Instagram">
                    <i className="bi bi-instagram"></i>
                </a>
                <a href="https://www.threads.net/@maurogrizia" rel="noopener noreferrer" target="_blank" title="Threads">
                    <i className="bi bi-threads"></i>
                </a>
                <a href="https://twitter.com/GriziaMauro" rel="noopener noreferrer" target="_blank" title="Twitter">
                    <i className="bi bi-twitter"></i>
                </a>
                <a href="https://www.reddit.com/user/MauroGrizia" rel="noopener noreferrer" target="_blank" title="Reddit">
                    <i className="bi bi-reddit"></i>
                </a>
                <a href="tg://resolve?domain=supermaramau" title="Telegram">
                    <i className="bi bi-telegram"></i>
                </a>
                <a href="https://medium.com/@griziamauro" rel="noopener noreferrer" target="_blank" title="Medium">
                    <i className="bi bi-medium"></i>
                </a>
            </div>
            <hr />
            <h4>{text['suggestion2']}</h4>
            <Form id="form">
                <Form.Group className="mb-3" controlId="emailAddress">
                    <Form.Label>Email</Form.Label>
                    <Form.Control className="mandatory" type="email" name="email" onChange={handleChange} placeholder={text['form1-ph']} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label htmlFor="disabledSelect">{text['sel1-lbl']}</Form.Label>
                    <Form.Select defaultValue={'other'} id="disabledSelect" name="reason" onChange={handleChange} placeholder={text['sel1-ph']}>
                        <option value="bug">{text['sel1-opt1']}</option>
                        <option value="personal">{text['sel1-opt2']}</option>
                        <option value="suggestion">{text['sel1-opt3']}</option>
                        <option value="work">{text['sel1-opt4']}</option>
                        <option value="other">{text['sel1-opt5']}</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="emailBody">
                    <Form.Label>{text['sel2-lbl']}</Form.Label>
                    <Form.Control className="mandatory" as="textarea" name="body" onChange={handleChange} placeholder={text['sel2-ph']} rows="5" />
                </Form.Group>
                <div>
                    <Button className="mb-3" disabled={canSend? false : true} id="email-submit" onClick={sendEmail}>
                        {text['but']}
                    </Button>
                    <div id="my-widget" className="frc-captcha" data-lang="es" style={{float: 'right'}}></div>
                </div>
                
                <ToastContainer />
            </Form>
        </div>
    );
}

export default Contact;