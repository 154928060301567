import React from "react";
import ProjectCard from './ProjectCard';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const ProjectColection = function (props) {
    function renderCols(colection, options, idx) {
        const cols = [];

        while (idx < colection.length && idx % options.cols < options.cols && cols.length < options.cols) {
            cols.push(
                <Col key={options.rowKey + '-col-' + String(idx)} xs={12/(options.cols - 2)} sm={12/(options.cols - 1)} md={12/(options.cols - 1)} lg={12/options.cols}>
                    <ProjectCard card={colection[idx]}/>
                </Col>
            );
            idx++;
        }

        return cols;
    }

    function renderRows(colection, options) {
        const rows = [];

        let idx = 0;
        
        while (idx < colection.length) {
            const rowKey = 'row-' + String(idx);

            rows.push(
                <Row key={rowKey}>
                    {renderCols(colection, {cols: options.cols, rowKey: rowKey}, idx)}
                </Row>
            );
            idx += options.cols;
        }

        return rows;
    }

    return (
        <Container fluid='md'>
            {renderRows(props.colection, {cols: props.cols})}
        </Container>
    );
}

export default ProjectColection;