import React, {Component} from 'react';
import axios from 'axios';

import './imageCreator.css';

import Dropdown from 'react-bootstrap/Dropdown';
import {sizes} from '../util/canvas/sizes';
import styles from '../util/canvas/styles';

class ImageCreator extends Component {
    static conf = {
        hiddenColection: {
            languageFrom: ['EN', 'POR'],
            languageTo: ['POR']
        },
        //visibleSizes: ['small', 'medium', 'fb_story', 'fb_vertical', 'ig_vertical', 'ig_story', 'li_vertical', 'wa_story'],
    };
    static flagDAW = {
        name: "DAW",
        description: "Das argendeutsche Wörterbuch",
        explanation: "",
        codepoints: ["0x1F310"],
        emoji: "🌐",
    };
    static styleConfs = styles;
    static sizeConfs = sizes;

    constructor(props) {
        super(props);

        this.defaultValues = {
            languageFrom: 'DE',
            languageTo: 'ES',
        }
// idxLiteral, adminMode, idxLiteralArray?
        this.state = {
            addWatermark: true,
            adminMode: false,
            countries: null,
            flags: null,
            idxEquivalent: -1,
            newest: null,
            oldest: null,
            languageFrom: null,
            languageTo: null,
            languages: null,
            dice: '2',
            name: 'DasArgendeutscheWörterbuch',
            //size: this.chooseSize(),
            size: 'small',
            downloadSize: 'small',
            style: 'dark',
            translation: null,
        };

        this.getBy = this.getBy.bind(this);
        this.getCountries = this.getCountries.bind(this);
        this.getFlags = this.getFlags.bind(this);
        this.getLanguages = this.getLanguages.bind(this);
        this.getNewDice = this.getNewDice.bind(this);
        this.getTranslation = this.getTranslation.bind(this);
        this.getTranslationInit = this.getTranslationInit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.init = this.init.bind(this);
        
        ImageCreator.prevEquivalent = this.prevEquivalent.bind(this);
        ImageCreator.nextEquivalent = this.nextEquivalent.bind(this);
        ImageCreator.update = this.update.bind(this);
    }

    animateDice(event) {
        const spin = [
            {
                transform: "translate(0px, -25px) rotate(180deg)", 
                "transform-origin": "center",
                offset: 0.5
            },
            {
                transform: "translate(0px, 0px) rotate(180deg)",
                "transform-origin": "center",
                offset: 1
            },
        ];
        const time = {
            duration: 500,
            iterations: 1,
        };

        event.target.animate(spin, time);
    }

    chooseSize() {
        const userWidth = visualViewport.width;

        let size = 'small';
        let largestWidth = ImageCreator.sizeConfs[size].width;

        Object.keys(ImageCreator.sizeConfs).forEach((k, i) => {
            const width = ImageCreator.sizeConfs[k].width;

            if (width <= userWidth && width >= largestWidth) {
                size = k;
            }
        });

        return size;
    }

    /*
    // Better ordering, i think
    chooseSize() {
        const userWidth = visualViewport.width;
        const estimWidth = Math.floor(userWidth * 0.4);
        const orderedSizes = Object.entries(webSizes).sort((a, b) => {
            return a[1].width - b[1].width;
        });
        
        let largestWidth = orderedSizes[0][1].width;
        let size = orderedSizes[0][0];
        let done = false;

        for (let i = 1; !done && i < orderedSizes.length; i++) {
            const key = orderedSizes[i][0];
            const width = webSizes[key].width;

            if (width <= estimWidth) {
                if (width >= largestWidth) {
                    size = key;
                    largestWidth = width;
                }
            } else {
                done = true;
            }
        }
        console.log(webSizes)
        console.log(size)
        return size;
    }*/

    getBy(colection, key, value) {
        let toReturn = null;

        if (colection && key && value) {
            colection.forEach((elem) => {
                if (elem[key] && elem[key] === value) {
                    toReturn = elem;
                }
            });
        }

        return toReturn;
    }

    async getCountries() {
        try {
            let response = await axios.post('/api/getCountries', {});

            return response.data;
        } catch (e) {
            console.log(e.message);
        }
    }

    async getFlags() {
        try {
            let response = await axios.post('/api/getFlags', {});

            return response.data;
        } catch (e) {
            console.log(e.message);
        }
    }

    async getLanguages() {
        try {
            let response = await axios.post('/api/getLanguages', {});

            return response.data;
        } catch (e) {
            console.log(e.message);
        }
    }

    getNewDice() {
        const newDice = Math.floor(Math.random() * 5) + 1;

        return newDice;
    }

    async getTranslation(options) {
        const def = 'newest';
        const getFuncs = {
            newest: async (options) => {
                try {
                    let response = await axios.post('/api/getTranslation', options);

                    return response.data;
                } catch (e) {
                    console.log(e.message);
                }
            },
            next: async (options) => {
                try {
                    let response = await axios.post('/api/getTranslation', options);

                    return response.data;
                } catch (e) {
                    console.log(e.message);
                }
            },
            prev: async (options) => {
                try {
                    let response = await axios.post('/api/getTranslation', options);

                    return response.data;
                } catch (e) {
                    console.log(e.message);
                }
            },
            oldest: async (options) => {
                try {
                    let response = await axios.post('/api/getTranslation', options);

                    return response.data;
                } catch (e) {
                    console.log(e.message);
                }
            },
            random: async (options) => {
                try {
                    let response = await axios.post('/api/getTranslation', options);

                    return response.data;
                } catch (e) {
                    console.log(e.message);
                }
            },
        };

        let toReturn = null;

        if (options.select && getFuncs[options.select]) {
            toReturn = getFuncs[options.select](options);
        } else {
            toReturn = getFuncs[def](options);
        }

        return toReturn;
    }

    async getTranslationInit(options) {
        try {
            let response = await axios.post('/api/getTranslationInit', options);

            return response.data;
        } catch (e) {
            console.log(e.message);
        }
    }
    async handleChange(event) {
        const funcs = {
            languageFrom: async (newState, languagename) => {
                const options = {
                    lang: dataset.languagename
                };

                if (this.state.languageFrom !== languagename) {
                    const [oldest, newest] = await this.getTranslationInit(options);

                    newState['newest'] = {};
                    newState['newest'][languagename] = newest;
                    newState['oldest'] = {};
                    newState['oldest'][languagename] = oldest;
                    newState['translation'] = newest;
                } else {
                    const translation = await this.getTranslation(options);

                    newState['translation'] = translation;
                }
                
                return;
            }
        };
        const target = event.target;
        const dataset = target.dataset;
        const newState = {

        };

        let updateState = false;

        if (dataset.languagename) {
            const result = this.getBy(this.state.languages, "name", dataset.languagename);
            
            // Look for a new translation, if needed
            if (funcs[dataset.state]) {
                await funcs[dataset.state](newState, dataset.languagename);
            }

            newState[dataset.state] = result;
            updateState = true;
        }

        if (updateState) {
            this.setState(newState);
        }
    }

    async init() {
        let languageFrom = null, languageTo = null;

        try {
            let newState = {};
            let [countries, flags, languages, [oldest, newest]] = await Promise.all([this.getCountries(), this.getFlags(), this.getLanguages(), this.getTranslationInit({lang: (languageFrom)? languageFrom : this.defaultValues.languageFrom})]);
            
            languages.forEach((lang, idx) => {
                if (lang.name === this.defaultValues.languageFrom) {
                    languageFrom = lang;
                }
                if (lang.name === this.defaultValues.languageTo) {
                    languageTo = lang;
                }
            });
            
            newState.countries = countries;
            newState.flags = flags;
            newState.newest = {}; 
            newState.newest[languageFrom.name] = newest;
            newState.oldest = {};
            newState.oldest[languageFrom.name] = oldest;
            newState.languageFrom = languageFrom;
            newState.languageTo = languageTo;
            newState.languages = languages;
            newState.translation = newest;

            this.setState(newState);
        } catch (error) {
            console.log(`Error in promises ${error}`);
        }
    }

    async update(event, options) {
        const passedOptions = {
            date: (this.state.translation)? this.state.translation.date : null,
            lang: (this.state.languageFrom)? this.state.languageFrom.name : this.defaultValues.languageFrom,
            select: options.select,
        };
        const newState = {};

        let translation = null;

        if (this.state.oldest[this.state.languageFrom.name] && this.state.newest[this.state.languageFrom.name]) {
            translation = await this.getTranslation(passedOptions);
            if (translation) {
                newState.translation = translation;
            }
        } else {
            let [oldest, newest] = await this.getTranslationInit(passedOptions);

            if (oldest && newest) {
                newState.newest = newest;
                newState.oldest = newest;
                newState.translation = newest;
            }
        }
        
        newState.idxEquivalent = -1;
        newState.isOldest = options.select === 'oldest' || (options.select === 'prev' && !translation);
        newState.isNewest = options.select === 'newest' || (options.select === 'next' && !translation);
    
        if (options.animateDice) {
            const newDice = this.getNewDice();

            newState.dice = newDice;
            this.animateDice(event);
        }
        this.setState(newState);
    }

    prevEquivalent() {
        this.setState({
            idxEquivalent: this.state.idxEquivalent - 1
        });
    }

    nextEquivalent() {
        this.setState({
            idxEquivalent: this.state.idxEquivalent + 1
        });
    }

    renderFormSizes() {
        const options = Object.keys(ImageCreator.sizeConfs).map((x, i) => {
            return <option key={i} value={x}>{ImageCreator.sizeConfs[x].description}</option>; 
        });

        return options;
    }

    renderOptions(options) {
        const arrayOptions = [];

        if (this.state.languages) {
            this.state.languages.forEach((language, idx) => {
                if (!options.hiddenColection || (!options.hiddenColection.includes(language.name))) {
                    arrayOptions.push(<Dropdown.Item data-languagename={language.name} data-state={options.state} href="#" key={idx} onClick={this.handleChange} active={(language === options.select)}>{language.emoji + " " + language.description}</Dropdown.Item>);
                }
            });
        }

        return arrayOptions;
    }
}


export default ImageCreator;