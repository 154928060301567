import React, { useEffect, useState } from "react";

import axios from "axios";

import Context from "./Context";

const Provider = ({ children }) => {
    const [entries, setEntries] = useState(null);
    const getPreviews = async () => {
        try {
            const PAGE = 0;
            const CANT = 10;
            const response = await axios.get(`/api/blog/previews/${PAGE}/${CANT}`);
            const entries = response.data || {};

            // NodeJS timestamps are ISO, client dates are not. Adapt!
            Array(Object(entries.values)).forEach((entry) => {
                entry.date = new Date(entry.date);
            });
                    
            setEntries(entries);
        } catch (e) {
            console.log(e.message);
        }
    };
    const provider = {
        entries,
        changeEntries: es => {
            setEntries({...es});
        }
    };

    useEffect(() => {
        if (!entries) {
            getPreviews();
        }
    }, []);

    return (
        <Context.Provider value={provider}>
            {children}
        </Context.Provider>
    );
};

export default Provider;