import React from 'react';

import EntryPreview from './EntryPreview';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const EntryColection = function (props) {
    function renderCols(colection, options, idx) {
        const cols = [];
        while (idx < colection.length && idx % options.cols < options.cols && cols.length < options.cols) {
            cols.push(
                <Col key={options.rowKey + '-col-' + String(idx)} className={'col-' + String(12/options.cols)}>
                    <EntryPreview data={colection[idx]} />
                </Col>
            );
            idx++;
        }

        return cols;
    }

    function renderRows(colection, options) {
        const rows = [];

        let idx = 0;
        
        while (idx < colection.length) {
            const rowKey = 'row-' + String(idx);

            rows.push(
                <Row className="mb-2" key={rowKey} xs={"auto"} md={12/options.cols} lg={12/options.cols}>
                    {renderCols(colection, {cols: options.cols, rowKey: rowKey}, idx)}
                </Row>
            );
            idx += options.cols;
        }

        return rows;
    }
    
    return (
        <Container fluid='md'>
            {renderRows(props.colection, {cols: props.cols})}
        </Container>
    );
}

export default EntryColection;