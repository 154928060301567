const commonPalettes = {
    label: {
        blackWhite:     {ES: 'B y N',      EN: 'B and W',     DE: 'S und W'},
        blue:           {ES: 'Azul',       EN: 'Blue',        DE: 'Blau'},
        childish:       {ES: 'Infantil',   EN: 'Childish',    DE: 'Kindisch'},
        childish2:      {ES: 'Infantil 2', EN: 'Childish 2',  DE: 'Kindisch 2'},
        chocolate:      {ES: 'Chocolate',  EN: 'Chocolate',   DE: 'Schokolade'},
        chocolate2:     {ES: 'Chocolate 2',EN: 'Chocolate 2', DE: 'Schokolade 2'},
        cream:          {ES: 'Crema',      EN: 'Cream',       DE: 'Sahne'},
        cthulhu:        {ES: 'Cthulhu',    EN: 'Cthulhu',     DE: 'Cthulhu'},
        darkblue:       {ES: 'Azul oscuro',EN: 'Dark blue',   DE: 'Dunkelblau'},
        darkgreen:      {ES: 'Verde2',     EN: 'Green2',      DE: 'Grün2'},
        duality:        {ES: 'Dualidad',   EN: 'Duality',     DE: 'Dualität'},
        elegant:        {ES: 'Elegante',   EN: 'Elegant',     DE: 'Elegant'},
        fire:           {ES: 'Fuego',      EN: 'Fire',        DE: 'Feuer'},
        gray:           {ES: 'Gris',       EN: 'Gray',        DE: 'Grau'},
        green:          {ES: 'Verde',      EN: 'Green',       DE: 'Grün'},
        lady:           {ES: 'Dama',       EN: 'Lady',        DE: 'Dame'},
        midnight:       {ES: 'Medianoche', EN: 'Midnight',    DE: 'Mitternacht'},
        midnight2:      {ES: 'Medianoche2',EN: 'Midnight2',   DE: 'Mitternacht2'},
        military:       {ES: 'Militar',    EN: 'Military',    DE: 'Militär'},
        passion:        {ES: 'Pasión',     EN: 'Passion',     DE: 'Passion'},
        pastel:         {ES: 'Pastel',     EN: 'Pastel',      DE: 'Pastell'},
        pink:           {ES: 'Rosa',       EN: 'Pink',        DE: 'Rosa'},
        poker:          {ES: 'Poker',      EN: 'Poker',       DE: 'Poker'},
        purple:         {ES: 'Violeta',    EN: 'Purple',      DE: 'Violet'},
        rainbow:        {ES: 'Arcoiris',   EN: 'Rainbow',     DE: 'Regenbogen'},
        sky:            {ES: 'Cielo',      EN: 'Sky',         DE: 'Himmel'},
    },
    rgb: {
        blackWhite: [
            '33,37,41','248,249,250'
        ],
        blue: [
            '3,4,94','2,62,138','0,119,182','0,150,199','0,180,216','72,202,228','144,224,239','173,232,244','202,240,248'
        ],
        darkblue: [
            '136, 180, 211', '36, 44, 49', '101, 133, 155', '25, 32, 37', '72, 99, 118'
        ],
        chocolate: [
            '111,29,27','187,148,87','67,40,24','153,88,42','255,230,167'
        ],
        chocolate2: [
            '255,237,216','243,213,181','231,188,145','212,162,118','188,138,95','164,113,72','139,94,52','111,69,24','96,56,8','88,49,1'
        ],
        childish: [
            '205,180,219','255,200,221','255,175,204','189,224,254','162,210,255'
        ],
        childish2: [
            '255,214,255','231,198,255','200,182,255','184,192,255','187,208,255'
        ],
        cream: [
            '237,237,233','214,204,194','245,235,224','227,213,202','213,189,175'
        ],
        cthulhu: [
            '211,189,176','193,174,159','137,147,124','113,91,100','105,56,92'
        ],
        duality: [
            '255,109,0','255,121,0','255,133,0','255,145,0','255,158,0','36,0,70','60,9,108','90,24,154','123,44,191','157,78,221'
        ],
        elegant: [
            '13,27,42','27,38,59','65,90,119','119,141,169','224,225,221'
        ],
        fire: [
            '3,7,30','55,6,23','106,4,15','157,2,8','208,0,0','220,47,2','232,93,4','244,140,6','250,163,7','255,186,8'
        ],
        gray: [
            '89,89,89','127,127,127','165,165,165','204,204,204','242,242,242',
        ],
        green: [
            '218,215,205','163,177,138','88,129,87','58,90,64','52,78,65'
        ],
        darkgreen: [
            '216,243,220','183,228,199','149,213,178','116,198,157','82,183,136','64,145,108','45,106,79','27,67,50','8,28,21'
        ],
        lady: [
            '206,190,190','236,226,208','213,185,178','162,103,105','109,46,70'
        ],
        midnight: [
            '255,205,178','255,180,162','229,152,155','181,131,141','109,104,117'
        ],
        midnight2: [
            '237,203,150','247,196,165','158,118,130','96,87,112','77,72,97'
        ],
        military: [
            '88,47,14','127,79,36','147,102,57','166,138,100','182,173,144','194,197,170','164,172,134','101,109,74','65,72,51','51,61,41'
        ],
        passion: [
            '89,13,34','128,15,47','164,19,60','201,24,74','255,77,109','255,117,143','255,143,163','255,179,193','255,204,213','255,240,243'
        ],
        pastel: [
            '254,197,187','252,213,206','250,225,221','248,237,235','232,232,228','216,226,220','236,228,219','255,229,217','255,215,186','254,200,154'
        ],
        pink: [
            '255,229,236','255,194,209','255,179,198','255,143,171','251,111,146'
        ],
        poker: [
            '11,9,10','22,26,29','102,7,8','164,22,26','186,24,27','229,56,59','177,167,166','211,211,211','245,243,244','255,255,255'
        ],
        purple: [
            '35,25,66','94,84,142','159,134,192','190,149,196','224,177,203'
        ],
        purple2: [
            '16,0,43','36,0,70','60,9,108','90,24,154','123,44,191','157,78,221','199,125,255','224,170,255'
        ],
        rainbow: [
            '231,29,67','255,0,0','255,55,0','255,110,0','255,165,0','255,195,0','255,225,0','255,255,0',
            '170,213,0','85,170,0','0,128,0','0,85,85','0,43,170','0,0,255','25,0,213','50,0,172','75,0,130',
            '129,43,166','184,87,202','208,58,135'
        ],
        sky: [
            '0,8,20','0,29,61','0,53,102','38,66,139','255,195,0','255,214,10','251,236,93'
        ],
    },
};

module.exports = {commonPalettes};