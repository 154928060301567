import React, {useContext} from 'react';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Context from '../../context/language/Context';
import dictionary from './lang/index.js';

import { getFolder } from "../../assets/conf/blog";

const EntryPreview = (props) => {
    const {language} = useContext(Context);
    const lang = language.toLowerCase();
    const text = dictionary[language];
    const {author, kicker, subtitle, title, readingTime} = props.data;
    const date = new Date(props.data.date);
    const year = String(date.getFullYear());
    const month = (date.getMonth() + 1 < 10? '0' : '') + String(date.getMonth() + 1);;
    const id = props.data.id;
    const folder = props.data.folder;
    const href = getFolder({year, month, folder});
    
    return (
        <Card className='blog-entry-preview blog-card-card'>
            <Card.Header className="blog-card-header">
                <Row className="mb-2" key={'row-' + title} xs={"auto"} md={12} lg={12}>
                    <Col key={'col1-' + title} xs={"auto"} md={4} lg={4}>
                        <span className='blog-entry-preview-author'>{author}</span>
                    </Col>
                    <Col key={'col2-' + title} xs={"auto"} md={4} lg={4}>
                        <span className='blog-entry-reading-time'>{Math.round(Number(readingTime)) + ' min'}</span>
                    </Col>
                    <Col key={'col3-' + title} xs={"auto"} md={4} lg={4}>
                        <span className='blog-entry-preview-date'>{date.toLocaleDateString()}</span>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body className="blog-card-body">
                <span className='blog-entry-preview-kicker'>{kicker}</span>
                <a href={href}>
                    <Card.Title>{title[lang]}</Card.Title>
                </a>
                <Card.Subtitle className="mb-2 text-muted">
                    {subtitle[lang]}
                </Card.Subtitle>
            </Card.Body>
            <Card.Footer className="blog-card-header">
                <Card.Link key={id} href={href}>
                    <Button className="read-button" variant="primary">{text['read']}</Button>
                </Card.Link>
            </Card.Footer>
        </Card>
    );
}

export default EntryPreview;