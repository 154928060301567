import React from 'react';

import './card.css';

const ProjectCard = function (props) {
    const card = props.card;
//<img src={card.img} className="card-project-img-top" alt={card.alt} />
    if (card) {
        return (
            <div className="card-project">
                <a className={"card-project-anchor" + (card.disabled? " disabled" : "")} href={card.link} rel="noopener noreferrer" >
                    <div className="card-project-header" style={{backgroundImage: "url('" + card.img + "')"}}>
                        
                    </div>
                    <div className="card-project-body">
                        <h5 className="card-project-title">{card.title}</h5>
                        <p className="card-project-text">{card.description}</p>
                    </div>
                    <div className="card-project-footer">
                    </div>
                </a>
            </div>
        );
    } else {
        console.log('ProjectCard.js ERROR!');
        console.log(card);
    }
}

export default ProjectCard;