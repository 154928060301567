const BLOG_DEFAULTS = {
    author: 'Mauro Grizia',
    kicker: '',
};
const BLOG_BASE_FOLDER = 'blog';

const getKey = (data) => {
    return `${data.year}/${data.month}/${data.folder}/`;
};
const getFolder = (data) => {
    return `${BLOG_BASE_FOLDER}/${getKey(data)}`;
};
const getHtml = (data) => {
    const lang = (data.lang || data.language).toLowerCase();

    return `${BLOG_BASE_FOLDER}/${data.year}/${data.month}/${data.folder}/entry_${lang}.html`;
};
const getImg = (data) => {
    return `${BLOG_BASE_FOLDER}/${data.year}/${data.month}/${data.folder}/${data.file}`;
};

export {BLOG_BASE_FOLDER, BLOG_DEFAULTS, getFolder, getHtml, getImg, getKey};