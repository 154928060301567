import React, {useContext, useState} from 'react';
import { useParams } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import MultiRangeSlider from "multi-range-slider-react";
import Canvas from './Canvas';
import Request from './Request.jsx';

import colection from './resources/js/colection';
import {webSizes} from '../util/canvas/sizes';
import styles from '../util/canvas/styles';

import {v4 as uuid} from 'uuid';

import Context from '../../context/language/Context';
import dictionary from './lang/index.js';

import './gallery.css';

export function withRouter(Children) {
    return(props) => {
       return <Children {...props} params = {useParams()}/>
    }
}

const ArtProject = (props) => {
    const {language} = useContext(Context);
    const text = dictionary[language];
    const projectName = props.params.projectName;
    const vp = visualViewport;
    const REFRESH_TIME = 1000;

    let runningTO = null;

    const [project, setProject] = useState(colection[projectName]);
    const [layoutLand, setLayoutLand] = useState(project.draw.md.size.includes('landscape') || vp.width < 950);
    const [style, setStyle] = useState('dark');
    const [dropdowns, setDropdowns] = useState({});
    const [showReq, setShowReq] = useState(false);

    const searchQuery = project.searchQuery;

    const update = () => {
        const oldProject = {...project};
        const vp = visualViewport;
        const dropdowns = document.querySelectorAll('.selected');
        const sliders = document.querySelectorAll('.form-range');
        const multiSliders = document.querySelectorAll('.multi-range-slider');
        const texts = document.querySelectorAll('.form-control[type="text"]');
        const checkboxes = document.querySelectorAll('.form-check-input[type="checkbox"]');
        const dice = document.querySelectorAll('.dice')[0];

        sliders.forEach((slider) => {
            const key = slider.dataset.key;
            const value = slider.value;

            oldProject.draw.md[key] = Number.parseFloat(value);
        });
        multiSliders.forEach((multiSlider) => {
            const inputMin = multiSlider.querySelector('.input-type-range-min');
            const inputMax = multiSlider.querySelector('.input-type-range-max');
            const valueMin = inputMin.value;
            const valueMax = inputMax.value;
            const id = multiSlider.id;
            
            let keyMin = id.replace('_', 'min');
            let keyMax = id.replace('_', 'max');
            
            keyMin = oldProject.draw.md[keyMin]? keyMin : id.replace('_', 'Min');
            keyMax = oldProject.draw.md[keyMax]? keyMax : id.replace('_', 'Max');
            oldProject.draw.md[keyMin] = Number.parseInt(valueMin);
            oldProject.draw.md[keyMax] = Number.parseInt(valueMax);
        });
        dropdowns.forEach((dropdown) => {
            const key = dropdown.dataset.key;
            const value = dropdown.dataset.value;

            oldProject.draw.md[key] = value;
        });
        checkboxes.forEach((checkbox) => {
            const key = checkbox.dataset.key;
            const value = checkbox.checked;

            oldProject.draw.md[key] = value;
        });
        texts.forEach((text) => {
            const key = text.dataset.key;
            const value = text.value;

            oldProject.draw.md[key] = value;
        });

        // Set dice
        const key = dice.dataset.key;
        const value = dice.dataset.value;

        oldProject.draw.md[key] = value;

        const layoutLand = 
                oldProject.draw.md.size.includes('landscape')
            ||  vp.width < 950; // por ahi 900
        setLayoutLand(layoutLand);
        setProject(oldProject);
    };
    const refreshTO = () => {
        clearTimeout(runningTO);
        runningTO = setTimeout(update, REFRESH_TIME);
    };
    const handleChange = (x, event) => {
        const target = event.target;
        const siblings = target.parentElement.childNodes;

        siblings.forEach((sibling) => {
            sibling.classList.remove('selected');
        })
        target.classList.add('selected');

        refreshTO();
    };
    const handleDropdown = (x, event) => {
        const target = event.target;
        const name = target.dataset.name;
        const button = target.parentElement.parentElement.querySelector('button');
        const siblings = target.parentElement.childNodes;
        const newDropdowns = {...dropdowns};

        newDropdowns[name] = target.textContent;
        siblings.forEach((sibling) => {
            sibling.classList.remove('disabled', 'selected');
        })
        target.classList.add('disabled', 'selected');
        button.textContent = target.textContent;

        refreshTO();
        setDropdowns(newDropdowns)
    };
    const handleDice = (event) => {
        const dice = event.currentTarget.querySelector('.dice');
        const value = uuid();
        
        animateDice(dice);
        dice.dataset.value = value;

        refreshTO();
    };
    const toggleShowReq = (event) => {
        setShowReq(!showReq);
    };
    const animateDice = (dice) => {
        /*const spin = [
            {
                "transform-origin": "center",
                "-webkit-transform": "traslate(0px, -25px) rotate(180deg)",
                "-moz-transform": "traslate(0px, -25px) rotate(180deg)",
                "-ms-transform": "traslate(0px, -25px) rotate(180deg)",
                "-o-transform": "traslate(0px, -25px) rotate(180deg)", 
                transform: "traslate(0px, -25px) rotate(180deg)", 
                offset: 0.5
            },
            {
                "transform-origin": "center",
                "-webkit-transform": "traslate(0px, 0px) rotate(180deg)",
                "-moz-transform": "traslate(0px, 0px) rotate(180deg)",
                "-ms-transform": "traslate(0px, 0px) rotate(180deg)",
                "-o-transform": "traslate(0px, 0px) rotate(180deg)",
                transform: "traslate(0px, 0px) rotate(180deg)",
                offset: 1
            },
        ];
        const time = {
            duration: 500,
            iterations: 1,
        };
        
        dice.animate(spin, time);*/
        const classStr = dice.classList.toString();
        const oldValue = Number(classStr[classStr.indexOf('-fill') - 1]);
        
        let newValue = Math.ceil(Math.random() * 6);
        
        newValue = oldValue === newValue? (newValue % 6) + 1 : newValue;
        const newDice = String(newValue);
        
        dice.classList.replace(`bi-dice-${dice.dataset.dice}-fill`, `bi-dice-${newDice}-fill`)
        dice.dataset.dice=newDice;
    };
    
    return (
    <div className="content-container">
        <h1 className="title">{project.title[language]}</h1>
        <hr />
        <div style={{display: showReq? 'block' : 'none'}}>
            <Request 
                projectName={projectName} 
                request={project} 
                toggleShowReq={toggleShowReq}
                searchQuery={searchQuery}
            />
        </div>
        <Container fluid='md' style={{display: showReq? 'none' : 'block'}}>
            {layoutLand?
                <>
                <Row key="row-1" className="text-center">
                    <Canvas md={project.draw.md} drawFunc={project.draw.func} size={webSizes[project.draw.md.size]} style={styles[style]}/>
                </Row>
                <Row key="row-2">
                    {project.controls.dropdowns
                        ? project.controls.dropdowns.map((dropdown) => {
                            const ddWithvalue = project.controls.dropdowns.filter((dd) => {return dd.name === dropdown.name && dd.value === dropdown.value;})[0];
                            
                            return (
                                <Col key={`row-${dropdown.name}-1`} className="align-self-center text-start mt-2" xs={12} sm={12} md={6} lg={6}>
                                    <Dropdown key={`dropdown-${dropdown.name}`} onSelect={handleDropdown} defaultValue={dropdowns[dropdown.name]? dropdowns[dropdown.name] : null}>
                                        <label className="lbl-button" style={{width: "30%"}}>
                                            {text[dropdown.label]}
                                        </label>
                                        <Dropdown.Toggle variant="success" style={{width: "60%"}}>
                                            {dropdowns[dropdown.name]? dropdowns[dropdown.name] : text[ddWithvalue.label]}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="dropdown-r0 dropdown-primary" style={{width: "60%"}}>
                                            {dropdown.options.map((option) => {
                                                return (
                                                    <Dropdown.Item key={`drop-item-${dropdown.name}-${option.value}`} className="text-end" data-key={dropdown.name} data-value={option.value} data-name={dropdown.name} disabled={String(project.draw.md[dropdown.name]) === String(option.value)}>
                                                        {option.label[language]}
                                                    </Dropdown.Item>
                                                );
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                            );
                        })
                        : null
                    }
                    <hr style={{marginTop: "15px"}}/>
                    {project.controls.sliders && project.controls.sliders.length > 0
                        ? project.controls.sliders.map((slider) => {
                            return (
                            <Col key={`row-${slider.name}-1`} className="align-self-center text-start" xs={12} sm={12} md={6} lg={6}>
                                <Form key={`form-${slider.name}`}>
                                    <Form.Label style={{width: "30%"}}>{text[slider.label]}</Form.Label>
                                    <Form.Range defaultValue={slider.value} onChange={refreshTO} min={slider.min} max={slider.max} step={slider.step} data-key={slider.name} style={{width: "60%", verticalAlign: "middle"}}/>
                                </Form>
                            </Col>
                            );
                        })
                        : null
                    }
                    {project.controls.multiSliders && project.controls.multiSliders.length > 0
                        ? <>
                            <hr/>
                            {project.controls.multiSliders.map((multiSlider) => {
                                return (
                                <Col key={`row-${multiSlider.name}-1`} className="align-self-center text-start" xs={12} sm={12} md={6} lg={6}>
                                    <Form key={`form-${multiSlider.name}`}>
                                        <Form.Label style={{width: "30%"}}>{text[multiSlider.label]}</Form.Label>
                                        <MultiRangeSlider
                                            id={multiSlider.name}
                                            onChange={refreshTO}
                                            preventWheel={true}
                                            canMinMaxValueSame={true}
                                            ruler={false}
                                            label={false}
                                            min={multiSlider.min.limit}
                                            max={multiSlider.max.limit}
                                            step={multiSlider.step}
                                            minValue={multiSlider.min.value}
                                            maxValue={multiSlider.max.value}
                                            style={{display: "inline-block", width: "60%", verticalAlign: "middle"}}
                                        />
                                    </Form>
                                </Col>
                                );
                            })}
                        </>
                        : null
                    }
                    {project.controls.texts && project.controls.texts.length > 0
                        ? <>
                            <hr/>
                            {project.controls.texts.map((text) => {
                                return (
                                    <Col key={`row-${text.name}-1`} className="align-self-center text-start" xs={12} sm={12} md={6} lg={6}>
                                        <Form key={`form-${text.name}`}>
                                            <Form.Label>{text.label[language]}</Form.Label>
                                            <Form.Control defaultValue={text.value} data-key={text.name} aria-label={text['al-meaning']} id="in-kicker" maxLength={25} size={25} type="text" onChange={refreshTO} autoFocus/>
                                        </Form>
                                    </Col>
                                );
                            })}
                        </>
                        : null
                    }
                    {project.controls.checkboxes && project.controls.checkboxes.length > 0
                        ? <>
                            <hr/>
                            {project.controls.checkboxes.map((checkbox) => {
                                return (
                                    <Col key={`row-${checkbox.name}-1`} className="align-self-center text-start" xs={12} sm={12} md={6} lg={6}>
                                        <Form key={`form-${checkbox.name}`}>
                                            <Form.Check type="checkbox" defaultChecked={checkbox.value} data-key={checkbox.name} label={text[checkbox.label]} onChange={refreshTO}/>
                                        </Form>
                                    </Col>
                                );
                            })}
                        </>
                        : null
                    }
                    <Row xs={6} sm={6} md={6} lg={6}>
                        <Col className="p-3" xs={8} sm={8} md={6} lg={6} onClick={handleDice}>
                            <Form.Label className="p-3">{text['ap-change-var']}</Form.Label>
                            <span className="p-2 container-dice-gallery">
                                <i className={"dice bi bi-dice-6-fill"} data-dice="6" data-key="seed" data-value={project.draw.md.seed}></i>
                            </span>
                        </Col>
                        <Col className="p-3" xs={0} sm={0} md={2} lg={2}></Col>
                        <Col className="p-3 text-center" xs={4} sm={4} md={4} lg={4}>
                            <Button className="but-req" onClick={toggleShowReq}>
                                {text['req-des']}
                            </Button>
                        </Col>
                    </Row>
                    <Row className="p-0 m-0 text-left" xs={12} sm={12} md={12} lg={12}>
                        <Col className="p-0" xs={4} sm={4} md={5} lg={8}></Col>
                        <Col className="p-0" xs={8} sm={8} md={7} lg={4}>
                            <h6>
                                {text['req-des-h6']}
                            </h6>
                            <ul>
                                <li>{text['req-des-li1']}</li>
                                <li>{text['req-des-li2']}</li>
                                <li>{text['req-des-li3']}</li>
                                <li>{text['req-des-li4']}</li>
                                <li>{text['req-des-li5']}</li>
                            </ul>
                        </Col>
                    </Row>
                </Row>
                </>
            :   <>
                <Row key="row-1">
                    <Col key="row-1-col-1" className="align-self-center text-center" xs={6} sm={6} md={8} lg={8}>
                        <Canvas md={project.draw.md} drawFunc={project.draw.func} size={webSizes[project.draw.md.size]} style={styles[style]}/>
                    </Col>
                    <Col key="row-1-col-2" xs={6} sm={6} md={4} lg={4}>
                        {project.controls.dropdowns
                            ? project.controls.dropdowns.map((dropdown) => {
                                const ddWithvalue = project.controls.dropdowns.filter((dd) => {return dd.name === dropdown.name && dd.value === dropdown.value;})[0];
                                
                                return (
                                    <Dropdown key={`dropdown-${dropdown.name}`} onSelect={handleDropdown} defaultValue={dropdowns[dropdown.name]? dropdowns[dropdown.name] : null}>
                                        <Row key={`row-${dropdown.name}`} className="mt-1">
                                            <Col key={`row-${dropdown.name}-1`} className="align-self-center text-start" xs={6} sm={6} md={6} lg={6}>
                                                <label className="lbl-button">
                                                    {text[dropdown.label]}
                                                </label>
                                            </Col>
                                            <Col key={`row-${dropdown.name}-2`} className="align-self-center text-end" xs={6} sm={6} md={6} lg={6}>
                                                <Dropdown.Toggle variant="success" style={{width: "100%"}}>
                                                    {dropdowns[dropdown.name]? dropdowns[dropdown.name] : text[ddWithvalue.label]}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-r0 dropdown-primary">
                                                    {dropdown.options.map((option) => {
                                                        return (
                                                            <Dropdown.Item key={`drop-item-${dropdown.name}-${option.value}`} className="text-end" data-key={dropdown.name} data-value={option.value} data-name={dropdown.name} disabled={String(project.draw.md[dropdown.name]) === String(option.value)}>
                                                                {option.label[language]}
                                                            </Dropdown.Item>
                                                        );
                                                    })}
                                                </Dropdown.Menu>
                                            </Col>
                                        </Row>
                                    </Dropdown>
                                );
                            })
                            : null
                        }
                        <hr/>
                        {project.controls.sliders
                            ? project.controls.sliders.map((slider) => {
                                return (
                                <Form key={`form-${slider.name}`}>
                                    <Form.Label>{text[slider.label]}</Form.Label>
                                    <Form.Range defaultValue={slider.value} onChange={refreshTO} min={slider.min} max={slider.max} step={slider.step} data-key={slider.name}/>
                                </Form>
                                );
                            })
                            : null
                        }
                        {project.controls.multiSliders
                        ? project.controls.multiSliders.map((multiSlider) => {
                            return (
                            <Form key={`form-${multiSlider.name}`}>
                                <Form.Label>{text[multiSlider.label]}</Form.Label>
                                <MultiRangeSlider
                                    id={multiSlider.name}
                                    onChange={refreshTO}
                                    preventWheel={true}
                                    canMinMaxValueSame={true}
                                    ruler={false}
                                    label={false}
                                    min={multiSlider.min.limit}
                                    max={multiSlider.max.limit}
                                    step={multiSlider.step}
                                    minValue={multiSlider.min.value}
                                    maxValue={multiSlider.max.value}
                                />
                            </Form>
                            );
                        })
                        : null
                    }
                    <hr/>
                        {project.controls.texts
                            ? project.controls.texts.map((text) => {
                                return (
                                <Form key={`form-${text.name}`}>
                                    <Form.Label>{text.label[language]}</Form.Label>
                                    <Form.Control defaultValue={text.value} data-key={text.name} aria-label={text["al-meaning"]} id="in-kicker" maxLength={25} size={25} type="text" autoFocus/>
                                </Form>
                                );
                            })
                            : null
                        }
                        {project.controls.checkboxes && project.controls.checkboxes.length > 0
                            ? <>
                                <hr/>
                                {project.controls.checkboxes.map((checkbox) => {
                                    return (
                                        <Col key={`row-${checkbox.name}-1`} className="align-self-center text-start" xs={12} sm={12} md={6} lg={6}>
                                            <Form key={`form-${checkbox.name}`}>
                                                <Form.Check type="checkbox" defaultChecked={checkbox.value} data-key={checkbox.name} label={text[checkbox.label]} onChange={refreshTO}/>
                                            </Form>
                                        </Col>
                                    );
                                })}
                            </>
                            : null
                        }
                        <Row onClick={handleDice}>
                            <Col className="p-3" xs={6} sm={6} md={6} lg={6}>
                                <Form.Label>{text['ap-change-var']}</Form.Label>
                            </Col>
                            <Col className="text-center p-2" xs={6} sm={6} md={6} lg={6}>
                                <span className="container-dice-gallery">
                                    <i className={"dice bi bi-dice-6-fill"} data-dice="6" data-key="seed" data-value={project.draw.md.seed}></i>
                                </span>
                            </Col>
                        </Row>
                        <Row className="text-center" key="row-1-2">
                            <Button className="but-req" onClick={toggleShowReq}>
                                {text['req-des']}
                            </Button>
                        </Row>
                        <Row className="mt-1">
                            <h6>
                                {text['req-des-h6']}
                            </h6>
                            <ul>
                                <li>{text['req-des-li1']}</li>
                                <li>{text['req-des-li2']}</li>
                                <li>{text['req-des-li3']}</li>
                                <li>{text['req-des-li4']}</li>
                                <li>{text['req-des-li5']}</li>
                            </ul>
                        </Row>
                    </Col>
                </Row>
                </>
            }
            
        </Container>
    </div>
    )
}

export default withRouter(ArtProject);