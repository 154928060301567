import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { ToastContainer, toast } from "react-toastify";
import axios from 'axios';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import './styles.css';
import {Col, Row} from 'react-bootstrap/esm';

const BlogHandler = () => {
    const navigate = useNavigate();
    const [cookies, removeCookie] = useCookies(['token']);

    const normalizeText = (text) => {
        const regexp = /[áéíóúÁÉÍÓÚäöüÄÖÜ]/g;
        const mapChars = {
            'á': 'a', 'Á': 'A',
            'é': 'e', 'É': 'E',
            'í': 'i', 'Í': 'I',
            'ó': 'o', 'Ó': 'O',
            'ú': 'u', 'Ú': 'U',
            'ä': 'a', 'Ä': 'A',
            'ö': 'o', 'Ö': 'O',
            'ü': 'u', 'Ü': 'U'
        };
    
        const normalized = text.replace(regexp, function(match) { 
            return mapChars[match]; 
          });
    
        return normalized;
    };
    const fBrowseImg = (event) => {
        const button = event.target;
        const carousel = button.closest("mau-carousel");
        const figures = carousel.querySelectorAll('figure');
        const figureActive = carousel.querySelector("[data-active]");
        const indexActive = [...figures].indexOf(figureActive);
        const indexActiveNew = (figures.length + indexActive + (button.dataset.move === "next"? 1 : -1)) % figures.length;
    
        figures[indexActiveNew].dataset.active = "true";
        delete figureActive.dataset.active;
    };
    const parseMarkdown = (markdown) => {
        // Add _blank to anchors
        markdown = markdown.replaceAll(
            RegExp(/<a.*?>/g),
            (m, key) => {
                const template = document.createElement('template');
                template.innerHTML = m;
                const a = template.content.firstChild;
    
                a.target = "_blank";
                a.rel = "noopener noreferrer";
                    
                return a.outerHTML;
            }
        );
        // Add lazy loading to images
        markdown = markdown.replaceAll(
            RegExp(/<img.*?>/g),
            (m, key) => {
                const template = document.createElement('template');
                template.innerHTML = m;
                const img = template.content.firstChild;
    
                img.dataset.src = img.src;
                img.src = '';
                img.classList.add('lazy-load');
    
                return img.outerHTML;
            }
        );
    
        // Make mau-carousel
        markdown = markdown.replaceAll(
            RegExp(/<mau-carousel.*?>/g),
            (m, key) => {
                const template = document.createElement('template');
                template.innerHTML = m;
                const carousel = template.content.firstChild;
    
                // Select active figure (first one found)
                let found = false;
    
                for (let i = 0; i < carousel.children.length && !found; i++) {
                    if (carousel.children[i].tagName === 'FIGURE') {
                        carousel.children[i].dataset.active = "true";
                        found = true;
                    }
                };
    
                // Add navigation buttons
                const butNext = document.createElement('i');
                const butPrev = document.createElement('i');
    
                butNext.className = 'carousel-button next bi bi-arrow-right-square-fill';
                butPrev.className = 'carousel-button prev bi bi-arrow-left-square-fill';
    
                butNext.dataset.move = "next";
                butPrev.dataset.move = "prev";
    
                butNext.addEventListener("click", fBrowseImg);
                butPrev.addEventListener("click", fBrowseImg);
    
                carousel.appendChild(butNext);
                carousel.appendChild(butPrev);
    
                return carousel.outerHTML;
            }
        );
    
        // Change local with web addresses
        markdown = markdown.replaceAll('./', '/blog/');
    
        return markdown;
    };
    const uploadFile = async (event) => {
        event.preventDefault();

        const MAX = 10000000; // 10 MB, I think
        const inputES = document.getElementById("input-file-es");
        const inputEN = document.getElementById("input-file-en");
        const inputDE = document.getElementById("input-file-de");
        const inputIMG = document.getElementById("input-img");
        const entry = {};
        const fileES = Array.from(inputES.files)[0];
        const fileEN = Array.from(inputEN.files)[0];
        const fileDE = Array.from(inputDE.files)[0];
        const fileIMGs = Array.from(inputIMG.files);
        const inAuthor = document.getElementById('in-author');
        const inKicker = document.getElementById('in-kicker');
        const inTitleES = document.getElementById('in-title-es');
        const inTitleEN = document.getElementById('in-title-en');
        const inTitleDE = document.getElementById('in-title-de');

        const inSubtitleES = document.getElementById('in-subtitle-es');
        const inSubtitleEN = document.getElementById('in-subtitle-en');
        const inSubtitleDE = document.getElementById('in-subtitle-de');

        let folder = '';
        let htmlFiles = {};
        let shouldSend = true;
        let msg = 'ERROR!';
        
        try {
            // Check if mandatory data is missing
            // Title
            entry.title = {es: null, en: null, de: null};
            if (inTitleES.value === '') {
                inTitleES.classList.add('danger');
                inTitleES.focus();
                shouldSend = false;
            } else {
                inTitleES.classList.remove('danger');
                entry.title.es = inTitleES.value;
            }
            if (inTitleEN.value === '') {
                inTitleEN.classList.add('danger');
                inTitleEN.focus();
                shouldSend = false;
            } else {
                inTitleEN.classList.remove('danger');
                entry.title.en = inTitleEN.value;
            }
            if (inTitleDE.value === '') {
                inTitleDE.classList.add('danger');
                inTitleDE.focus();
                shouldSend = false;
            } else {
                inTitleDE.classList.remove('danger');
                entry.title.de = inTitleDE.value;
            }

            // Subtitle
            entry.subtitle = {es: null, en: null, de: null};
            if (inSubtitleES.value === '') {
                inSubtitleES.classList.add('danger');
                inSubtitleES.focus();
                shouldSend = false;
            } else {
                inSubtitleES.classList.remove('danger');
                entry.subtitle.es = inSubtitleES.value;
            }
            if (inSubtitleEN.value === '') {
                inSubtitleEN.classList.add('danger');
                inSubtitleEN.focus();
                shouldSend = false;
            } else {
                inSubtitleEN.classList.remove('danger');
                entry.subtitle.en = inSubtitleEN.value;
            }
            if (inSubtitleDE.value === '') {
                inSubtitleDE.classList.add('danger');
                inSubtitleDE.focus();
                shouldSend = false;
            } else {
                inSubtitleDE.classList.remove('danger');
                entry.subtitle.de = inSubtitleDE.value;
            }

            // File
            if (fileES.size < MAX && fileES.type === "text/markdown") {
                const originalname = normalizeText(fileES.name);
                
                let text = await fileES.text();
                
                folder = originalname.split('.')[0];
                folder = folder.toLowerCase().replaceAll(/,|:|-|\s|¿|\?|¡|!/g, '_').replaceAll(/_+/g, '_');
                entry.folder = folder;

                // Parse markdown
                htmlFiles.es = parseMarkdown(text);

                // Estimated time of reading
                const words = text.split(' ');
                const readingTime = String((words.length / 200).toFixed(1));

                entry.readingTime = readingTime;
            } else {
                shouldSend = false;
                msg = 'ES file must be a Markdown file';
            }
            if (fileEN.size < MAX && fileEN.type === "text/markdown") {
                let text = await fileEN.text();

                // Parse markdown
                htmlFiles.en = parseMarkdown(text);
            } else {
                shouldSend = false;
                msg = 'EN file must be a Markdown file';
            }
            if (fileDE.size < MAX && fileDE.type === "text/markdown") {
                let text = await fileDE.text();
                
                // Parse markdown
                htmlFiles.de = parseMarkdown(text);
            } else {
                shouldSend = false;
                msg = 'DE file must be a Markdown file';
            }

            // Check not mandatory data
            entry.author = inAuthor.value;
            entry.kicker = inKicker.value;

            if (shouldSend) {
                const date = new Date();
                const year = String(date.getFullYear());
                const month = (date.getMonth() + 1 < 10? '0' : '') + String(date.getMonth() + 1);
                const data = {year, month, lang: 'es', folder};
                
                entry.date = date;
                
                const x = new FormData();
                
                x.append('entry', JSON.stringify(entry));
                x.append('data', JSON.stringify(data));
                x.append('fileES', htmlFiles.es);
                x.append('fileEN', htmlFiles.en);
                x.append('fileDE', htmlFiles.de);
                fileIMGs.forEach(async (fileIMG) => {
                    x.append('fileIMGs', fileIMG);
                });
                
                await axios.post('/api/blog/create', x, {headers: {'Content-Type': 'multipart/form-data'}});
                toast.success('Uploaded 3 files!', {
                    position: "top-right",
                });
            } else {
                toast.error(msg, {
                    position: "top-right",
                });
                console.log(msg);
            }

        } catch (e) {
            console.log(e.message);
        }
    };

    const uploadEntries = async () => {
        try {
            await axios.post(
                '/api/blog/uploadEntries', 
                {}
            );

            alert('Done!');
        } catch (e) {
            console.log(e.message);
        }
    };

    useEffect(() => {
        const COOKIE_OPTIONS = {path: '/', sameSite: 'Strict', secure: false};
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        let status = false;

        const verifyCookie = async () => {
            try {
                if (!cookies.token || cookies.token === 'undefined') {
                    navigate(`/ES/admin/login`);
                } else {
                    const { data } = await axios.post(
                        "/api/admin/verifyUser",
                        {},
                        {   cancelToken: source.token,
                            withCredentials: true,
                            credentials: 'include'}
                            );
                    status = data.status;
                }
                if (!status) {
                    removeCookie("token", COOKIE_OPTIONS);
                    navigate(`/ES/admin/login`);
                }
            } catch (err) {
                if (axios.isCancel(err)) {
                    console.log('successfully aborted');
                } else {
                    console.log(err);
                }
            };
        };

        verifyCookie();

        return () => {
            // cancel the request before component unmounts
            source.cancel();
        };
    }, [navigate, removeCookie, cookies.token]);

    return (
        <div className="content-container">
            <div>
                <Button onClick={uploadEntries} style={{textAlign: 'center'}}>
                    UPLOAD TEST ENTRIES
                </Button>
            </div>
            <Form onSubmit={uploadFile} encType="multipart/form-data">
                <Form.Group className="mb-3">
                    <Form.Label htmlFor="#in-author">Author</Form.Label>
                    <Form.Control
                        aria-label="significado"
                        id="in-author"
                        maxLength={50}
                        placeholder="Mauro Grizia"
                        size={50}
                        type="text"
                        autoFocus
                    />

                    <Form.Label htmlFor="#in-kicker">Kicker</Form.Label>
                    <Form.Control
                        aria-label="significado"
                        id="in-kicker"
                        maxLength={50}
                        placeholder="Computer Art Series"
                        size={50}
                        type="text"
                        autoFocus
                    />

                    <Form.Label htmlFor="#in-title">Title(ES/EN/DE) <span className="danger small">(obligatorio)</span></Form.Label>
                    <Row id="in-title">
                        <Col>
                            <Form.Control
                                aria-label="significado"
                                className="mandatory"
                                id="in-title-es"
                                maxLength={100}
                                placeholder="Creatividad computacional"
                                size={50}
                                type="text"
                                autoFocus
                                required
                            />
                        </Col>
                        <Col>
                            <Form.Control
                                aria-label="significado"
                                className="mandatory"
                                id="in-title-en"
                                maxLength={100}
                                placeholder="Computacional creativity"
                                size={50}
                                type="text"
                                autoFocus
                                required
                            />
                        </Col>
                        <Col>
                            <Form.Control
                                aria-label="significado"
                                className="mandatory"
                                id="in-title-de"
                                maxLength={100}
                                placeholder="Computerkreativität"
                                size={50}
                                type="text"
                                autoFocus
                                required
                            />
                        </Col>
                    </Row>
                    

                    <Form.Label htmlFor="#in-subtitle">Subtitle(ES/EN/DE) <span className="danger small">(obligatorio)</span></Form.Label>
                    <Row id="in-title">
                        <Col>
                            <Form.Control
                                aria-label="significado"
                                className="mandatory"
                                id="in-subtitle-es"
                                maxLength={50}
                                placeholder='El caso de "The Painting Fool"'
                                size={50}
                                type="text"
                                autoFocus
                                required
                            />
                        </Col>
                        <Col>
                            <Form.Control
                                aria-label="significado"
                                className="mandatory"
                                id="in-subtitle-en"
                                maxLength={50}
                                placeholder='The case of "The Painting Fool"'
                                size={50}
                                type="text"
                                autoFocus
                                required
                            />
                        </Col>
                        <Col>
                            <Form.Control
                                aria-label="significado"
                                className="mandatory"
                                id="in-subtitle-de"
                                maxLength={50}
                                placeholder='Der Fall "The Painting Fool"'
                                size={50}
                                type="text"
                                autoFocus
                                required
                            />
                        </Col>
                    </Row>

                    <Form.Label>Upload markdown file(ES/EN/DE) <span className="danger small">(obligatorio)</span></Form.Label>
                    <Row id="in-title">
                        <Col>
                            <Form.Control name="files" id="input-file-es" type="file" required/>
                        </Col>
                        <Col>
                            <Form.Control name="files" id="input-file-en" type="file" required/>
                        </Col>
                        <Col>
                            <Form.Control name="files" id="input-file-de" type="file" required/>
                        </Col>
                    </Row>
                </Form.Group>

                <Form.Label>Upload images</Form.Label>
                <Form.Control name="imgs" id="input-img" type="file" multiple/>

                <Button  variant="primary" type="submit">Upload</Button>
            </Form>
            <ToastContainer />
        </div>
    );
}

export default BlogHandler;