import React, {useEffect, useState, useContext} from 'react';
import axios from 'axios';
import { useParams, Navigate } from 'react-router-dom';
import { CircularProgressbar } from 'react-circular-progressbar';

import EntriesContext from '../../context/entries/Context';
import LanguageContext from '../../context/language/Context';

import { setLazyLoadImages } from '../../assets/js/imgUtils';

import 'react-circular-progressbar/dist/styles.css';
import { getKey } from '../../assets/conf/blog';

const Entry = (props) => {
    const { entries } = useContext(EntriesContext);
    const { language } = useContext(LanguageContext);
    const lang = language.toLowerCase();
    const params = useParams();
    const year = props.year || params.year;
    const month = props.month || params.month;
    const folder = props.folder || params.folder;
    const [redirect, setRedirect] = useState(false);
    const [entry, setEntry] = useState({});
    const [progress, setProgress] = useState(0);

    function handleTag(event) {

    }

    async function getEntry() {
        try {
            const data = {year, month, folder, language};
            const key = getKey(data);

            if (entries[key]) {
                if (!entries[key].html) {
                    const responseUrl = await axios.get(`/api/blog/getEntry/${year}/${month}/${folder}/${language}`);
                    const url = responseUrl.data;
                    const response = await axios.get(url);

                    entries[key].html = response.data;
                    entries[key].date = new Date(entries[key].date);
                }

                const newEntry = {...entries[key]};
                
                setEntry(newEntry);
                updateProgress();
            }
        } catch (e) {
            console.log('Entry - getEntry(): ERROR!');
            console.log(e.message);
            setRedirect(true);
        }
    }
    function scrollUp(event) {
        window.scrollTo(0,0);
    }
    function updateProgress(event) {
        const body = document.getElementsByTagName('BODY')[0];
        const bodyHeight = body.scrollHeight;
        const navHeight = window.screen.height;
        const scrollY = window.scrollY;
        const progress = Math.ceil((scrollY + navHeight) / bodyHeight * 100);

        setProgress(scrollY === 0? 0 : progress > 100? 100 : progress);
    }
    function parseEntry() {
        const data = {year, month, folder, language};
        const anchors = document.querySelectorAll('mau-carousel a');
        const carousels = document.querySelectorAll('mau-carousel');
        const fBrowseImg = (event) => {
            const button = event.target;
            const carousel = button.closest("mau-carousel");
            const figures = carousel.querySelectorAll('figure');
            const figureActive = carousel.querySelector("[data-active]");
            const indexActive = [...figures].indexOf(figureActive);
            const indexActiveNew = (figures.length + indexActive + (button.dataset.move === "next"? 1 : -1)) % figures.length;

            figures[indexActiveNew].dataset.active = "true";
            delete figureActive.dataset.active;
        };

        // Add "lazy load" to all img classes
        setLazyLoadImages(data);

        // Add rel="noopener noreferrer" target="_blank" to all anchors
        anchors.forEach((anchor) => {
            anchor.rel = "noopener noreferrer";
            anchor.target = "_blank";
        });

        // Make carousels
        carousels.forEach((carousel) => {
            // Select active figure (first one found)
            let found = false;

            for (let i = 0; i < carousel.children.length && !found; i++) {
                if (carousel.children[i].tagName === 'FIGURE') {
                    carousel.children[i].dataset.active = "true";
                    found = true;
                }
            };

            // Add navigation buttons
            const butNext = document.createElement('i');
            const butPrev = document.createElement('i');

            butNext.className = 'carousel-button next bi bi-arrow-right-square-fill';
            butPrev.className = 'carousel-button prev bi bi-arrow-left-square-fill';

            butNext.dataset.move = "next";
            butPrev.dataset.move = "prev";

            butNext.addEventListener("click", fBrowseImg);
            butPrev.addEventListener("click", fBrowseImg);

            carousel.appendChild(butNext);
            carousel.appendChild(butPrev);
        });
    }

    useEffect(() => {
        if (entries) {
            getEntry();
        }
        
        return () => {
            setEntry({});
            setProgress(0);
            document.removeEventListener('scroll', updateProgress);
        };
    }, [entries]);

    useEffect(() => {
        parseEntry();
        document.addEventListener('scroll', updateProgress);

        return () => {
            const carouselButs = document.querySelectorAll('.carousel-button');

            carouselButs.forEach((but) => {
                but.remove();
            });
        }
    }, [entry]);

    /*if (redirect) {
        return <Navigate to='/error_404'/>;
    }*/
//<ReactMarkdown children={entry.markdown} rehypePlugins={[rehypeRaw]}/>
    return (
        entry.title &&
            <>
            <div className='content-container'>
                <h3 className="kicker">{entry.kicker}</h3>
                <h1 className="title">{entry.title[lang]}</h1>
                <h4 className="subtitle">{entry.subtitle[lang]}</h4>
                <div className="metadata-container mt-3 mb-3">
                    <span className="metadata metadata-author">
                        <i className="bi bi-person-circle"></i> {entry.author? entry.author : '-'}
                    </span>
                    <span className="metadata metadata-reading-time">
                        <i className="bi bi-book"></i> {entry.readingTime? 'TEA: ' + entry.readingTime + ' min' : '-'}
                    </span>
                    <span className="metadata metadata-date">
                        <i className="bi bi-calendar-event"></i> {entry.date? entry.date.toLocaleDateString() : '-'}
                    </span>
                </div>
                <hr/>
                <div className='blog-entry' dangerouslySetInnerHTML={{__html: entry.html}}>
                    
                </div>
                <div className='tags'>
                    TAGS: 
                    {entry.tags && entry.tags.map((x, i) => {
                        return (<button key={x} className="tag m-1" onClick={handleTag}>{x}</button>)
                    })}
                </div>
            </div>
            <div className='up-container' onClick={scrollUp}>
                <CircularProgressbar 
                    value={progress}
                    minValue={0}
                    maxValue={100}
                    strokeWidth={8}
                    background={false}
                    styles={{
                        rotation: 0.1,
                        pathTransitionDuration: 0.5,
                        path: {stroke: 'var(--color-primary)'},
                        text: {fill: 'var(--color-secondary)'}
                    }} 
                    text={`${progress}%`}
                />
            </div>
            </>
    );
}

export default Entry;