import React, { useState, useContext } from "react";
import Context from '../../context/language/Context';
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useCookies } from "react-cookie";
import {COOKIE_CONF} from '../../assets/conf/conf';

const Login = () => {
  const {language} = useContext(Context);
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const [inputValue, setInputValue] = useState({
    email: "",
    password: "",
  });
  const { email, password } = inputValue;
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });
  const handleSuccess = (msg) =>
    toast.success(msg, {
      position: "bottom-left",
    });

  const handleSubmit = async (e) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    e.preventDefault();

    try {
      const { data } = await axios.post(
        "/api/admin/login",
        {
          ...inputValue,
        },
        { cancelToken: source.token,
          credentials: 'include',
          withCredentials: true}
      );
      const { success, message, token } = data;
      
      if (success) {
        setCookie('token', token, COOKIE_CONF);
        handleSuccess(message);
        setTimeout(() => {
          navigate("/ES/admin");
        }, 1000);
      } else {
        handleError(message);
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        console.log('successfully aborted');
      } else {
        source.cancel();
        console.log(err);
      }
    };
    setInputValue({
      ...inputValue,
      email: "",
      password: "",
    });
  };

  return (
    <div className="content-container">
      <div className="form_container">
        <h2>Log in</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="email">Email</label>
            <input
              className="mw-100"
              type="email"
              name="email"
              value={email}
              placeholder="Enter your email"
              onChange={handleOnChange}
            />
          </div>
          <div>
            <label htmlFor="password">Contraseña</label>
            <input
            className="mw-100"
              type="password"
              name="password"
              value={password}
              placeholder="Enter your password"
              onChange={handleOnChange}
            />
          </div>
          <button type="submit">Enviar</button>
          <span>
            Don't have an account? <Link to={`/${language}/admin/signup`}>Signup</Link>
          </span>
        </form>
        <ToastContainer />
      </div>
    </div>
    
  );
};

export default Login;

