class Point {
    constructor(options) {
      this.x = options.x;
      this.y = options.y;
      this.r = options.r;
      this.fillStyle = options.fillStyle;
      this.strokeStyle = options.strokeStyle;
      this.lineWidth = options.lineWidth? options.lineWidth : 3;
  
      this.dx = options.dx? options.dx : (Math.random() - 0.5) * 2;
      this.dy = options.dy? options.dy : (Math.random() - 0.5) * 2;
      this.idxUpdate = options.idxUpdate;
    }
  
    draw(context) {
      context.beginPath();
      context.fillStyle = this.fillStyle;
      context.lineWidth = this.lineWidth;
      context.arc(this.x, this.y, this.r, 0, 2 * Math.PI);
      if (this.fillStyle) {
        context.fill();
      }
      if (this.strokeStyle) {
        context.stroke();
      }
    }
  
    update(data) {
      const funcUpdates = [
        //../sketches/sketch-03-1
        (obj) => {
            this.dx *= (this.x < 0 || this.x > obj.width)? -1 : 1;
            this.dy *= (this.y < 0 || this.y > obj.height)? -1 : 1;
            this.x += this.dx;
            this.y += this.dy;
        },
        //../sketches-mau/triangulo_con_laser
        (shape) => {
            const fitsFormula = shape.fitsFormula(this.x, this.y);
        
            this.dx *= fitsFormula? -(1) : 1;
            this.dy *= fitsFormula && Math.random() < 0.5? -(1) : 1;
            this.x += this.dx;
            this.y += this.dy;
        }
      ];

      funcUpdates[this.idxUpdate](data);
    }
}

class Shape {
    // I could create ANOTHER invisible triangle inside the visible one to avoid visible colisiona
    THRESHOLD = 3500;
  
    constructor(props) {
      this.walls = props.walls;
    }
  
    fitsFormula(x, y) {
      let fits = false;
  
      this.walls.forEach((wall) => {
        const value = wall.formula(x, y);
  
        fits = fits || (value && value < this.THRESHOLD);
      });
  
      return fits;
    }
}

/*
 * With two points, make a wall which makes objects to bounce if they intersect with the line formula
 */
class Wall {
    constructor(props) {
      this.width = props.width;
      this.p2 = props.p2;
      this.p1 = props.p1;
      this.slopeX = props.p2.y - props.p1.y;
      this.slopeY = props.p2.x - props.p1.x;
      this.constant = (props.p2.x - props.p1.x) * props.p1.y - (props.p2.y - props.p1.y) * props.p1.x;
      this.error = props.p1.x === props.p2.x || props.p1.y === props.p2.y? 200 : 0;
      this.minX = (props.p2.x < props.p1.x? props.p2.x : props.p1.x) - this.error - this.width;
      this.maxX = (this.minX === props.p1.x? props.p2.x : props.p1.x) + this.error + this.width;
      this.minY = (props.p2.y < props.p1.y? props.p2.y : props.p1.y) - this.error - this.width;
      this.maxY = (this.minY === props.p1.y? props.p2.y : props.p1.y) + this.error + this.width;
    }
  
    formula(x, y) {
      let toReturn = null;
  
      if ((x > this.minX && x < this.maxX) && (y > this.minY && y < this.maxY)) {
        toReturn = this.slopeX * x - this.slopeY * y + this.constant;
        /*console.group('formula')
        console.log(`p1: (${this.p1.x}, ${this.p1.y})`)
        console.log(`p2: (${this.p2.x}, ${this.p2.y})`)
        console.log(x, y)
          console.log(`${this.slopeX} * ${x} + ${this.slopeY} * ${y} + ${this.constant} = ${toReturn}`)
        console.groupEnd('formula')*/
      }
      
      return toReturn? Math.abs(toReturn) : null;
    }
}

export {Point, Shape, Wall};