import React, {useState, useContext} from 'react';

import axios from 'axios';

import EntryPreviewColection from './EntryPreviewColection';

import { getKey } from "../../assets/conf/blog";

import Button from 'react-bootstrap/Button';

import EntriesContext from '../../context/entries/Context';
import LanguageContext from '../../context/language/Context';
import dictionary from './lang/index.js';

import './blog.css';

const Blog = function () {
    const { entries, changeEntries } = useContext(EntriesContext);
    const { language } = useContext(LanguageContext);
    const text = dictionary[language];
    const conf = {
        cantPerPage: 10,
        colsPerRow: 1,
    };
    const [page, setPage] = useState(0);
    const [previews, setPreviews] = useState(entries);
    
    let idxStart = conf.cantPerPage * page;
    let idxEnd = idxStart + conf.cantPerPage;

    useState(() => {
        const getPreviews = async () => {
            const PAGE = 0;
            const CANT = 10;
            try {
                const response = await axios.get(`/api/blog/previews/${PAGE}/${CANT}`);
                const entries = response.data || {};
                
                // NodeJS timestamps are ISO, client dates are not. Adapt!
                Array(Object(entries.values)).forEach((entry) => {
                    entry.date = new Date(entry.date);
                });

                changeEntries(entries);
                setPreviews(entries);
            } catch (e) {
                console.log(e.message);
            }
        };
        
        if (!previews) {
            getPreviews();
        }
    }, []);

    function prev() {
        const butPrev = document.getElementById('blog-prev');
        const butNext = document.getElementById('blog-next');

        if (page <= 0) {
            butPrev.disabled = true;
        } else {
            setPage(page - 1);
            idxStart = conf.cantPerPage * (page - 1);
            idxEnd = idxStart + conf.cantPerPage - 1;
        }
        if (idxEnd < previews.length) {
            butNext.disabled = false;
        }
    }

    function next() {
        const butPrev = document.getElementById('blog-prev');
        const butNext = document.getElementById('blog-next');

        if (conf.cantPerPage * page >= previews.length) {
            butNext.disabled = true;
        } else {
            setPage(page + 1);
            idxStart = conf.cantPerPage * (page + 1);
            idxEnd = idxStart + conf.cantPerPage + 1;
        }
        if (page > 0) {
            butPrev.disabled = false;
        }
    }
    
    return (
        <>
        <div className="content-container blog-entry">
            <h1 className="title">{text['title']}</h1>
            <hr />
            {previews
                ? <>
                    <EntryPreviewColection colection={Object.values(previews).slice(idxStart, idxEnd)} cols={conf.colsPerRow}/>
                    <div className='blog-button-container'>
                        <Button id="blog-prev" disabled={page <= 0} onClick={prev}>{'<'}</Button>
                        <Button style={{pointerEvents: 'none'}}>{page}</Button>
                        <Button id="blog-next" disabled={idxEnd >= previews.length} onClick={next}>{'>'}</Button>
                    </div>
                </>
                : null
            }
        </div>
        </>
    );
}

export default Blog;