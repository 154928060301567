import React, {useContext} from 'react';
import GalleryCard from './GalleryCard';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Context from '../../context/language/Context';
import dictionary from './lang/index.js';

import colectionPreview from './resources/js/colectionPreview';

const Gallery = () => {
    const {language} = useContext(Context);
    const text = dictionary[language];

    const renderCols = (colectionPreview, options, idx) => {
        const cols = [];

        while (idx < colectionPreview.length) {
            cols.push(
                <Col key={options.rowKey + '-col-' + String(idx)} xs={12/(options.cols[0])} sm={12/(options.cols[1])} md={12/(options.cols[2])} lg={12/options.cols[3]}>
                    <GalleryCard card={colectionPreview[idx]}/>
                </Col>
            );
            idx++;
        }
        
        return cols;
    }

    const renderRows = (colectionPreview, options) => {
        const rows = [];

        let idx = 0;
        
        while (idx < colectionPreview.length) {
            const rowKey = 'row-' + String(idx);

            rows.push(
                <Row key={rowKey}>
                    {renderCols(colectionPreview, {cols: options.cols, rowKey: rowKey}, idx)}
                </Row>
            );
            idx += options.cols;
        }

        return rows;
    }

    return (
        <div className="content-container">
            <h1 className="title">{text['gal-title']}</h1>
            <hr />
            <p>{text['gal-p1']}</p>
            <p>{text['gal-p2']}</p>
            <p>{text['gal-p3']}</p>
            <Container fluid='md'>
                {renderRows(
                    Object.values(colectionPreview).sort((a, b) => {const aDate = new Date(a.date); const bDate = new Date(b.date); return bDate.valueOf() - aDate.valueOf();}),
                    {cols: [1, 2, 2, 3]}
                )}
            </Container>
        </div>
    );
}

export default Gallery;