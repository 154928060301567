import React from 'react';

import ImageCreator from './ImageCreator.jsx';

import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle';
import Form from 'react-bootstrap/Form';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Context from '../../context/language/Context';
import dictionary from './lang/index.js';

class CanvasCreator extends ImageCreator {
    static text = {};
    static contextType = Context;

    constructor(props) {
        super(props);
        
        //this.renderLines = this.renderLines.bind(this);
        this.draw = this.draw.bind(this);
        this.drawLines = this.drawLines.bind(this);
        this.save = this.save.bind(this);

        const language = localStorage.getItem('language') || 'ES';
        
        this.text = dictionary[language];
    }

    // Separate text into lines and draw them in canvas context ctx
    drawLines(ctx, {prefix = '', text}, dimensions, charsPerLine, fontSize) {
        const words = text.split(' ');

        let line = [];
        let strLine = '';
        let x = 0;
        let cantLines = 0;
        let chars = 0;

        for (let i = 0; i < words.length; i++) {
            if (chars + words[i].length > charsPerLine) {
                chars = chars % charsPerLine;
                strLine = (cantLines === 0 && prefix? prefix + ' ': '') + line.join(' ');
                
                x = dimensions.x + (cantLines === 0 || prefix === ''? 0 : fontSize * 2);
                ctx.fillText(strLine, x, dimensions.y + cantLines * fontSize);
                line = [];
                chars = 0;
                cantLines++;
            }
            line.push(words[i]);
            chars += words[i].length + 1;
        };

        if (line.length > 0) {
            strLine = (cantLines === 0 && prefix? prefix + ' ': '') + line.join(' ');
            x = dimensions.x + (cantLines === 0 || prefix === ''? 0 : fontSize * 2);
            ctx.fillText(strLine, x, dimensions.y + cantLines * fontSize);
            cantLines++;
        }

        return cantLines;
    }

    draw(id, sizeKey) {
        const sizeConf = ImageCreator.sizeConfs[sizeKey];
        const styleConf = ImageCreator.styleConfs[this.state.style];
        const canvas = document.getElementById(id);
        const ctx = canvas.getContext("2d");
        const translation = this.state.translation;
        const languageFrom = this.state.languageFrom;
        const languageTo = this.state.languageTo;
        const idxEquivalent = this.state.idxEquivalent;
        const isEquivalent = (idxEquivalent > -1 && translation.translations.equivalents[languageFrom.name] && idxEquivalent < translation.translations.equivalents[languageFrom.name].length);

        let phrase = '', literal = '', meaning = '', explanation = '';
        let countryUsed = null;
        let flagLiteral = null;

        if (translation) {
            phrase = isEquivalent? translation.translations.equivalents[languageFrom.name][idxEquivalent].text : translation.phrase;
            literal = (translation.translations.literals && translation.translations.literals[languageTo.name][0])? translation.translations.literals[languageTo.name][0].text : '';
            meaning = (translation.translations.meanings && translation.translations.meanings[languageTo.name][0])? translation.translations.meanings[languageTo.name][0].text : '';
            explanation = (translation.explanation)? translation.explanation[languageTo.name] : '';

            countryUsed = this.getBy(this.state.countries, "_id", isEquivalent? translation.translations.equivalents[languageFrom.name][idxEquivalent].used[0] : translation.used[0]);
            flagLiteral = this.getBy(this.state.languages, "name", languageTo.name);
        }

        // Canvas style
        canvas.height = sizeConf.height;
        canvas.width = sizeConf.width;

        // Initial rectangle
        ctx.fillStyle = styleConf.rectFill;
        ctx.fillRect(0, 0, sizeConf.width, sizeConf.height);
        
        if (!translation) {
            console.log('Translation is null');
            return;
        }

        // Phrase
        const phraseEmoji = phrase && countryUsed? countryUsed.emoji: '';
        ctx.font = sizeConf.phrase.fontSize + " " + styleConf.phrase.fontFamily;
        ctx.fillStyle = styleConf.textFill;
        const linesPhrase = this.drawLines(ctx, {prefix: phraseEmoji, text: phrase}, {height: sizeConf.height, x: sizeConf.phrase.x, y: sizeConf.height * 0.1}, sizeConf.phrase.charsPerLine, parseInt(sizeConf.phrase.fontSize));
        
        // Literal translation
        const literalEmoji = literal && flagLiteral? flagLiteral.emoji : '';
        const yLiteral = sizeConf.height * 0.25 + (linesPhrase - 1) * parseInt(sizeConf.phrase.fontSize);
        ctx.font = sizeConf.literal.fontSize + " " + styleConf.literal.fontFamily;
        ctx.fillStyle = styleConf.textFill;
        const linesTranslation = this.drawLines(ctx, {prefix: literalEmoji, text: literal}, {height: sizeConf.height, x: sizeConf.literal.x, y: yLiteral}, sizeConf.literal.charsPerLine, parseInt(sizeConf.literal.fontSize));

        // Separator
        const yMeaning = (yLiteral + parseInt(sizeConf.literal.fontSize) * linesTranslation + 30 > sizeConf.meaning.y)? yLiteral + parseInt(sizeConf.literal.fontSize) * linesTranslation + 30 : sizeConf.meaning.y;
        const yLine = yLiteral + (yMeaning - yLiteral) / 2;
        ctx.beginPath();
        ctx.moveTo(sizeConf.width * 0.1, yLine);
        ctx.lineTo(sizeConf.width * 0.9, yLine);
        ctx.save()
        ctx.globalAlpha = styleConf.lineSeparator.opacity;
        ctx.strokeStyle = styleConf.lineSeparator.stroke;
        ctx.lineWidth = styleConf.lineSeparator.strokeWidth;
        ctx.stroke();
        ctx.restore();

        // Meaning translation
        const meaningY = yMeaning;
        ctx.font = sizeConf.meaning.fontSize + " " + styleConf.meaning.fontFamily;
        ctx.fillStyle = styleConf.textFill;
        this.drawLines(ctx, {text: meaning}, {height: sizeConf.height, x: sizeConf.meaning.x, y: meaningY}, sizeConf.meaning.charsPerLine, parseInt(sizeConf.meaning.fontSize));

        // Explanation
        const pExplanation = document.getElementById("explanation");

        pExplanation.textContent = explanation;

        // Footer
        const footerEmoji = ImageCreator.flagDAW.emoji;
        const footer = this.state.name;
        ctx.font = sizeConf.footer.fontSize + " " + styleConf.footer.fontFamily;
        ctx.fillStyle = styleConf.textFill;
        ctx.textAlign = 'right';
        ctx.fillText(footerEmoji + " " + footer, sizeConf.width * 0.98, sizeConf.height * 0.98);

        // Watermark
        if (this.state.addWatermark) {
            ctx.font = sizeConf.watermark.fontSize + " " + styleConf.watermark.fontFamily;
            ctx.fillStyle = styleConf.textFill;
            ctx.textAlign = 'center';
            ctx.save();
            ctx.globalAlpha = styleConf.watermark.opacity;
            ctx.translate(sizeConf.watermark.translateX, sizeConf.watermark.translateY);
            ctx.rotate( (Math.PI / 180) * parseInt(sizeConf.watermark.rotation));
            ctx.fillText(footerEmoji + " " + footer, sizeConf.width * 0.05, sizeConf.height * 1.05);
            ctx.restore();
        }
    }

    save() {
        const id = 'canvas-save';
        const size = this.state.downloadSize;
        this.draw(id, size);
        const canvas = document.getElementById(id);
        const dataURL = canvas.toDataURL("image/png");
        const downloadLink = document.createElement("a");

        downloadLink.href = dataURL;
        downloadLink.download = "image.png";
        downloadLink.click();
    }

    componentDidUpdate() {
        // Force canvas-visible size based on screen size?
        //const size = 'medium';

        this.draw("canvas-visible", this.state.size);
    }

    componentDidMount() {
        this.init();
    }

    render() {
        this.text = dictionary[this.context.language];
        
        return (
            <div className="image-creator mt-3">
                <Container fluid="md">
                        <Row className={"justify-content-md-center mt-2"} xs={"auto"} md={12} lg={12}>
                            <Col className={'col-6'}>
                                <Dropdown className="text-center">
                                    <label className="lbl-button" htmlFor="dropdown-language-from">
                                        {this.text['from']}
                                    </label>
                                    <DropdownToggle id="dropdown-language-from" variant="primary" caret={"true"}>
                                        {(this.state.languageFrom)? this.state.languageFrom.description : 'Select language'}
                                    </DropdownToggle>
                                    <Dropdown.Menu>
                                        {this.renderOptions({
                                            hiddenColection: ImageCreator.conf.hiddenColection.languageFrom,
                                            select: this.state.languageFrom,
                                            state: "languageFrom",
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            <Col className={'col-6'}>
                                <Dropdown className="text-center">
                                    <label className="lbl-button" htmlFor="dropdown-language-to">
                                        {this.text['to']}
                                    </label>
                                    <Dropdown.Toggle id="dropdown-language-to" variant="primary" caret={"true"}>
                                        {(this.state.languageTo)? this.state.languageTo.description : 'Select language'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {this.renderOptions({
                                            hiddenColection: ImageCreator.conf.hiddenColection.languageTo,
                                            select: this.state.languageTo,
                                            state: "languageTo",
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        </Row>
                        <Row className={"justify-content-md-center mt-2"} xs={"auto"} md={12} lg={12}>
                            <Col className="col-1 m-0 p-0">
                                <Button className="ghostly-button" disabled={!this.state.translation || this.state.idxEquivalent < 0} onClick={ImageCreator.prevEquivalent} variant="light">{"<"}</Button>
                            </Col>
                            <Col className="col-10 m-0 p-0 text-center">
                                <div className="container-image-creator">
                                    <canvas id="canvas-save" style={{display:'none'}}>
                                        {this.text['error-canvas']}
                                    </canvas>
                                    <canvas id="canvas-visible">
                                        {this.text['error-canvas']}
                                    </canvas>
                                </div>
                            </Col>
                            <Col className="col-1 m-0 p-0">
                                <Button className="ghostly-button" disabled={!this.state.translation || this.state.idxEquivalent >= this.state.translation.translations.equivalents[this.state.languageFrom.name].length - 1} onClick={ImageCreator.nextEquivalent} variant="light">{">"}</Button>
                            </Col>
                        </Row>
                        <Row className={"text-center mt-3"} xs={"auto"} md={12} lg={12}>
                            <Col className="col-3">
                                <Button disabled={!this.state.languageFrom || !this.state.oldest[this.state.languageFrom.name] || (this.state.oldest[this.state.languageFrom.name] && this.state.oldest[this.state.languageFrom.name]._id === this.state.translation._id)} onClick={(event) => {ImageCreator.update(event, {select: 'prev'});}} variant="primary">{'<'}</Button>
                                <span className="container-dice">
                                    <i className={"dice bi bi-dice-" + this.state.dice + "-fill"} onClick={(event) => {ImageCreator.update(event, {animateDice: true, select: 'random'});}}></i>
                                </span>
                                <Button disabled={!this.state.languageFrom || !this.state.newest[this.state.languageFrom.name] || (this.state.newest[this.state.languageFrom.name] && this.state.newest[this.state.languageFrom.name]._id === this.state.translation._id)} onClick={(event) => {ImageCreator.update(event, {select: 'next'});}} variant="primary">{'>'}</Button>
                            </Col>
                            <Col className="col-2">
                                <Form.Label>{this.text['size']}</Form.Label>
                            </Col>
                            <Col className="col-4">
                                <Form.Select defaultValue={this.state.size} id="svg-size" name="size" onChange={(e) => this.setState({downloadSize: e.target.value})} size="sm">
                                    {this.renderFormSizes()}
                                </Form.Select>
                            </Col>
                            <Col className="col-3">
                                <Button id="svg-save" onClick={this.save} variant="primary">{this.text['download']}</Button>
                            </Col>
                        </Row>
                        <Row className={"justify-content-md-center mt-3"} xs={"auto"} md={12} lg={12}>
                            <div className="container-explanation">
                                <p id="explanation"></p>
                            </div>
                            <p></p>
                        </Row>
                    </Container>
            </div>
        );
    }
}

export default CanvasCreator;