const styles = {
    dark: {
        phrase: {
            fontFamily: 'Roboto',
        },
        literal: {
            fontFamily: 'Roboto',
        },
        meaning: {
            fontFamily: 'Roboto',
        },
        footer: {
            box: {
                fontFamily: 'Roboto',
                color: 'white',
                background: 'rgb(25, 25, 25)',
                opacity: 0.35,
            }
        },
        watermark: {
            fontFamily: 'Roboto',
            color: 'black',
            fontSize: 50,
            opacity: 0.33,
        },
        lineSeparator: {opacity: 0.5, stroke: '#FFFFFF', strokeWidth: 2},
        rectFill: '#000000',
        textFill: '#FFFFFF'
    },
    light: {
        phrase: {
            fontFamily: 'Roboto',
        },
        literal: {
            fontFamily: 'Roboto',
        },
        meaning: {
            fontFamily: 'Roboto',
        },
        footer: {
            box: {
                fontFamily: 'Roboto',
                color: 'white',
                background: 'rgb(25, 25, 25)',
                opacity: 0.35,
            }
        },
        watermark: {
            fontFamily: 'Roboto',
            opacity: 1/2,
        },
        lineSeparator: {opacity: 0.5, stroke: '#000000', strokeWidth: 2},
        rectFill: '#FFFFFF',
        textFill: '#000000'
    }
};

export default styles;