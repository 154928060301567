import React, {useContext} from 'react';

import Context from '../../context/language/Context';

import './gallery.css';

const GalleryCard = function (props) {
    const {language} = useContext(Context);
    const card = props.card;
    const href = `/${language}/gallery/${card.link}`;
    
    if (card) {
        return (
            <div className="card-project">
                <a className={"card-project-anchor" + (card.disabled? " disabled" : "")} href={href} rel="noopener noreferrer" target="_blank" >
                    <div className="card-project-header" style={{backgroundImage: "url('" + card.img + "')"}}>
                        <img src={`${card.img}`} alt=""/>
                    </div>
                    <div className="card-project-body">
                        <h5 className="card-project-title">{card.title[language]}</h5>
                        <p className="card-project-text">{card.description[language]}</p>
                    </div>
                    <div className="card-project-footer">
                    </div>
                </a>
            </div>
        );
    } else {
        console.log('ProjectCard.js ERROR!');
        console.log(card);
    }
}

export default GalleryCard;